import React from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const styles = {
  tabs: {
    margin: '-30px -25px 30px',
    padding: '0px 8px',
    backgroundColor: '#009be5',
  },
  tabsIndicator: {
    backgroundColor: '#ffffff',
    height: 3,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  tab: {
    backgroundColor: '#009be5',
    color: '#ffffff',
    fontSize: '14px',
  },
};

export default class ListTabsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tabs: props.tabs,
      selected: props.tabs && props.tabs[0] && props.tabs[0].key,
    };
  }

  componentWillReceiveProps = (props) => {
    if (props.tabs && props.tabs !== this.state.tabs) {
      let selected = this.state.selected;
      if (props.tabs.findIndex(tab => tab.key === selected) < 0) {
        selected = props.tabs[0] && props.tabs[0].key;
      }
      this.setState({tabs: props.tabs, selected});
    }
  }

  handleTabChange = (key) => {
    this.setState({selected: key});
    this.props.onTabChange(key);
  }

  render = () => {
    return (
      <Tabs
        value={this.state.selected}
        onChange={(e, key) => this.handleTabChange(key)}
        style={styles.tabs}
        TabIndicatorProps={{style: styles.tabsIndicator}}
      >
        {this.state.tabs.map(tab =>
          <Tab
            key={tab.key}
            value={tab.key}
            label={tab.title}
            style={styles.tab}
          />
        )}
      </Tabs>
    )
  }

}
