import React from 'react';
import $ from "jquery";
import { connect } from 'react-redux';
import update from 'immutability-helper';
import {Modal, Button as BTButton} from 'react-bootstrap';
import SaveIcon from  '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { sendRequest } from '../helpers/global.js';
import Storage from '../helpers/Storage.js';

import FlexibleInput from './FlexibleInput.js';

import '../sass/wizard.scss';

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class OrderView extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getDefaultState(props);
  }
  
  getDefaultState = (props) => {
    var properties = Object.assign({}, Storage.getConfig(props.configKey).properties);
    var config = Storage.getConfig(props.configKey).config;
    var keys = Object.keys(properties);
    var object = {};
    for (let i = 0; i < keys.length; i++) {
      var key = keys[i];
      if (props.object && props.object[key]) {
        object[key] = props.object[key];
      } else {
        if (Array.isArray(properties[key].default)) {
          object[key] = properties[key].default.slice();
        } else if (typeof properties[key].default === "object") {
          object[key] = Object.assign({}, properties[key].default);
        } else {
          object[key] = properties[key].default;
        }
      }
    }
    var state = {
      isMobile: !!Storage.getSetup('is_mobile'),
      object: object, 
      properties: properties, 
      config: config,
      responseContent: false,
      current_report: null,
      errors: {},
      assetClasses: [],
      checklists: [],
    }
    return state;
  }

  componentDidMount = () => {
    sendRequest({
      method: "orders/classes",
      type: "GET",
      success: (data) => {
        this.setState({assetClasses: data});
      },
      error: (xhr, status, err) => {
      }
    });
    sendRequest({
      method: "orders/checklists",
      type: "GET",
      success: (data) => {
        this.setState({checklists: data});
      },
      error: (xhr, status, err) => {
      }
    });
    this.requestInitialData();
  }

  componentWillReceiveProps = (props) => {
    if (props.configKey) {
      if (props.configKey !== this.props.configKey || props.object.id !== this.props.object.id) {
        var state = this.getDefaultState(props);
        this.setState(state);
        this.requestInitialData(state);
        return;
      }
    }
    let stateChange = {};
    let mapping = mapStoreToProps({data: {}, setup: {}});
    Object.keys(mapping).forEach((key) => {
      if (typeof props[key] !== 'undefined' && props[key] !== null) {
        stateChange[key] = props[key];
      }
    });
    this.setState(stateChange);
  }

  updateStateWithData = (data) => {
    var keys = Object.keys(this.state.properties);
    var object = this.state.object;
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (typeof data[key] !== "undefined" && data[key] !== null) {
        object[key] = data[key];
      }
    }
    this.setState({object});
  }

  requestInitialData = (state) => {
    state = state || this.state;

    if (state.object.id) {
      sendRequest({
        method: state.config.method + state.object.id,
        type: "GET",
        success: (data) => {
          if (data.id) {
            this.updateStateWithData(data);
          } else if (data.redirect) {
            $(document).trigger("changePage", [data.redirect, {id: state.object.id}]);
          } else if (data.error) {
            this.handleFinishEdit();
          }
        },
        error: (xhr, status, err) => {
        }
      });
    }
  }

  requestLogs = (customer_id) => {
    if (customer_id) {
      sendRequest({
        method: `customers/${customer_id}/logs`,
        type: "GET",
        success: (data) => {
          this.setState(update(this.state, {
            object: {
              logs: {$set: data}
            }
          }));
        },
      });
    } else {
      this.setState(update(this.state, {
        object: {
          logs: {$set: ''}
        }
      }));
    }
  }

  submitData = (e) => {
    let partsComplete = this.state.object["parts"].reduce((res, part) => 
      res && part.asset_class_id && part.assets_count && (part.checklist_ids || []).length > 0
    , true);
    if (!partsComplete) {
      this.setState({errors: {parts: 'Please complete all work scopes'}});
      return;
    }

    e.preventDefault();
    sendRequest({
      method: this.state.config.method + (this.state.object.id ? this.state.object.id : ""),
      type: this.state.object.id ? "PUT" : "POST",
      data: this.state.object,
      success: (data) => {
        if (data.id) {
          if (this.state.object.id) {
            this.updateStateWithData(data);
            $(document).trigger("showAlert", [`${this.state.config.objectName} saved`]);
          } else {
            $(document).trigger("showAlert", [`${this.state.config.objectName} created`]);
            this.handleFinishEdit();
          }
        } else if (data.errors) {
          this.setState({errors: data.errors});
        } else {
          this.setState({responseContent: data});
        }
      },
      error: (xhr, status, err) => {
        if (xhr.responseJSON) {
          if (xhr.responseJSON.errors) {
            this.setState({errors: xhr.responseJSON.errors});
          } else {
            this.setState({responseContent: xhr.responseJSON});
          }
        }
      }
    });
  }

  handleFinishEdit = () => {
    $(document).trigger("changePage", [this.props.configKey]);
  }

  onModalClose = () => {
    if (this.state.responseContent.redirectBack) {
      this.handleFinishEdit();
    } else {
      this.setState({responseContent: false});
    }
  }

  onReportCancel = (e) => {
    this.setState({current_report: null});
  }

  onReportConfirm = () => {
    var report = this.state.config.singleReport[this.state.current_report.key];
    var data = {id: this.state.object.id};
    if (report.report_type === "create") {
      data[report.nameParameter] = $('input[data-id="report_create"]').val();
    }
    sendRequest({
      method: report.request,
      type: report.type,
      data: data,
      success: (data) => {
        this.setState({responseContent: data});
      }
    });
  }

  renderResponse = () => {
    var responseBody = [];
    if (this.state.responseContent) {
      let content = this.state.responseContent;
      if ($.isArray(content.error)) {
        responseBody.push( content.error.map((err, i) => {
          return <span key={"error" + i} className="errorMessage">{err}</span>;
        }) );
      } else if (typeof content.error === "string") {
        responseBody.push( <span key="error" className="errorMessage">{content.error}</span> );
      }
      if (content.message) {
        if (responseBody.length > 0) {
          responseBody.push( <br key={"br" + responseBody.length}/> );
        }
        responseBody.push( content.message );
      }
      if (content.link) {
        if (responseBody.length > 0) {
          responseBody.push( <br key={"br" + responseBody.length}/> );
        }
        if (typeof content.link === "string") {
          responseBody.push( <a key="link" href={content.link} target="_blank" rel="noopener noreferrer">Download link</a> );
        } else {
          responseBody.push( <a key="link" href={content.link.href} target="_blank" rel="noopener noreferrer">{content.link.label}</a> );
        }
      }
      if (content.copyable) {
        if (responseBody.length > 0) {
          responseBody.push( <br key={"br" + responseBody.length}/> );
        }
        let copyToClipboard = (e) => {
          let input = $(e.currentTarget).parent().find("input");
          input.focus();
          input[0].setSelectionRange(0, input.val().length);
          try {
              console.log(document.execCommand("copy"));
          } catch(e) {}
        }
        responseBody.push( <div key="copyable" className="copyable">
          <input value={content.copyable} readOnly={true} className="form-control"/>
          <button className="btn btn-default" onClick={copyToClipboard}>
            <i className="fa fa-clipboard"></i>
          </button> 
        </div> );
      }
    }

    return (
      <Modal show={!!this.state.responseContent} onHide={this.onModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Server response</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {responseBody}
        </Modal.Body>
        <Modal.Footer>
          <BTButton onClick={this.onModalClose}>Close</BTButton>
        </Modal.Footer>
      </Modal>
    )
  }

  renderReport = () => {
    var reportTitle = null;
    var reportButton = null;
    var reportForm = null;
    if (this.state.current_report !== null) {
      var report = this.state.config.singleReport[this.state.current_report.key];
      reportTitle = report.button;
      reportButton = report.confirm;
      if (report.report_type === "create") {
        reportForm = <input
          key="report_create"
          data-id="report_create"
          placeholder={report.placeholder}
          className="form-control"
        />
      }
    }

    return (
      <Modal show={this.state.current_report !== null} onHide={this.onReportCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{reportTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {reportForm}
        </Modal.Body>
        <Modal.Footer>
          <BTButton onClick={this.onReportCancel}>Cancel</BTButton>
          <BTButton 
            onClick={this.onReportConfirm} 
            bsStyle="primary"
          >{reportButton}</BTButton>
        </Modal.Footer>
      </Modal>
    )
  }

  renderInput = ({key, properties, value, onChange, disabled}) => {
    if (properties.type === "none") {
      return null;
    }

    let inputDisabled = properties.preset || disabled;
    if (properties.createOnly || this.state.object.disable_edit) {
      inputDisabled = true;
    }
    if (properties.modifyCondition && !properties.modifyCondition(this.state.object)) {
      inputDisabled = true;
    }

    let parentProperties = this.state.properties;
    if (!parentProperties[key]) {
      parentProperties = {[key]: properties};
    }

    let hostProp = properties.hostProperty;
    return (
      <div className="questionInput" key={key}>
        <FlexibleInput
          object={value}
          parentId={this.state.object.id}
          disabled={inputDisabled}
          objectKey={key}
          objectProperties={parentProperties}
          hostPropertyValue={hostProp ? this.state.object[hostProp] : null}
          placeholder={properties.placeholder || properties.title}
          onChange={onChange}
        />
        {this.state.errors[key] ? 
          <div className='inputError'>{this.state.errors[key]}</div>
        : null}
      </div>
    )
  }

  renderBasicInput = (key) => {
    return this.renderInput({
      key: key,
      properties: this.state.properties[key],
      value: this.state.object[key],
      onChange: (key, val) => {
        this.setState(update(this.state, {
          object: {
            [key]: {$set: val}
          },
          errors: {
            [key]: {$set: null}
          },
        }), () => {
          if (key === 'customer_id') {
            this.requestLogs(val);
          }
        });
      }
    })
  }

  renderPart = (part, index, disabled) => {
    let onChange = (key, val, index, handler) => {
      this.setState(update(this.state, {
        object: {
          parts: { [index]: {
            [key]: {$set: val}
          }}
        },
        errors: {
          parts: {$set: null},
          [key]: {$set: null},
        },
      }), handler);
    }
    let assetClass = this.state.assetClasses.find(item => item.id === part.asset_class_id);
    let checklists = assetClass ? 
      this.state.checklists.filter(item => assetClass.checklist_ids.indexOf(item.id) > -1)
    : [];
    let titles = ["Asset class", "Checklists", "Quantity"];
    let inputs = [
      this.renderInput({
        key: "asset_class_id",
        properties: {type: "select", placeholder: "Asset class", options: this.state.assetClasses},
        value: part.asset_class_id || null,
        onChange: (key, val) => onChange(key, val, index, () => onChange("checklist_ids", [], index)),
        disabled,
      }),
      this.renderInput({
        key: "checklist_ids",
        properties: {type: "multi", placeholder: "Checklists", options: checklists},
        value: part.checklist_ids || [],
        onChange: (key, val) => onChange(key, val, index),
        disabled,
      }),
      this.renderInput({
        key: "assets_count",
        properties: {type: "text", placeholder: "Quantity"},
        value: part.assets_count || "",
        onChange: (key, val) => onChange(key, val, index),
        disabled,
      }),
    ];
    let onDelete = () => {
      this.setState(update(this.state, {
        object: {
          parts: {$splice: [[index, 1]]}
        },
        errors: {
          parts: {$set: null}
        },
      }));
    }

    if (this.state.isMobile) {
      return (
        <div key={part.id} className='partsList'>
          {inputs.map((view, index) =>
            <div key={view.key}>
              <label>{titles[index]}</label>
              {view}
            </div>
          )}
          {disabled ? null : 
            <Button onClick={onDelete}>Delete</Button>
          }
        </div>
      )
    } else {
      return (
        <tr key={part.id}>
          {inputs.map(view => <td key={view.key}>{view}</td>)}
          {disabled ? null : <td>
            <div className="deletePart" onClick={onDelete}>
              <i className="material-icons">close</i>
            </div>
          </td>}
        </tr>
      )
    }
  }

  renderInfoSection = () => {
    return (
      <ExpansionPanel key='info'>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          Customer Information
          {this.state.errors['customer_id'] ? 
            <div className='panelError'>{this.state.errors['customer_id']}</div>
          : null}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display: 'block'}}>
          {this.renderBasicInput("customer_id")}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  renderDetailsSection = () => {
    return (
      <ExpansionPanel key='details'>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          Work Order Details
          {this.state.errors['scheduled_at'] ? 
            <div className='panelError'>{this.state.errors['scheduled_at']}</div>
          : null}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display: 'block'}}>
          {this.renderBasicInput("name")}
          {this.renderBasicInput("scheduled_at")}
          {this.renderBasicInput("remarks")}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  renderAssignSection = () => {
    let logs = this.state.object["logs"];
    return (
      <ExpansionPanel key='assign'>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          Assign to
          {this.state.errors['technician_id'] ? 
            <div className='panelError'>{this.state.errors['technician_id']}</div>
          : null}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display: 'block'}}>
          {this.renderBasicInput("technician_id")}
          {logs && logs.length > 0 && Storage.getData('user').role === 'admin' ? 
            this.renderBasicInput("logs")
          : null}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  renderFrequencySection = () => {
    return (
      <ExpansionPanel key='frequency'>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          Frequency
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display: 'block'}}>
          {this.renderBasicInput("repeat_interval")}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  renderWorkScopeSection = () => {
    const properties = this.state.properties['parts'];
    let disabled = !!this.state.object.disable_edit;
    if (properties && properties.modifyCondition) {
      disabled = disabled || !(properties.modifyCondition(this.state.object));
    }
    let addPartButton = disabled ? null : <div className='addPartButton'>
      <Button
        variant='outlined'
        color='primary'
        onClick={() => {
          let id = `$${Date.now()}`;
          this.setState(update(this.state, {
            object: {
              parts: {$push: [{id}]}
            },
            errors: {
              parts: {$set: null}
            },
          }));
        }}
      >Add Scope</Button>
    </div>
    return (
      <ExpansionPanel key='workScope'>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          Work Scope
          {this.state.errors['parts'] ? 
            <div className='panelError'>{this.state.errors['parts']}</div>
          : null}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display: 'block'}}>
          {this.state.isMobile ?
            this.state.object["parts"].map((part, index) => 
              this.renderPart(part, index, disabled)
            )
            :  
            <table className="partsTable">
              <thead>
                <tr>
                  <th width='30%'>Asset class</th>
                  <th width='55%'>Checklists</th>
                  <th width='15%'>Quantity</th>
                  {disabled ? null : <th width='50px'></th>}
                </tr>
              </thead>
              <tbody>
                {this.state.object["parts"].map((part, index) => 
                  this.renderPart(part, index, disabled)
                )}
              </tbody>
            </table>
          }
          {addPartButton}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  renderReportsSection = () => {
    if (!this.state.object.id) {
      return null;
    }
    let reportKeys = Object.keys(this.state.config.singleReport).filter(key => {
      let report = this.state.config.singleReport[key];
      return !report.showCondition || report.showCondition(this.state.object);
    });
    if (reportKeys.length === 0) {
      return null;
    }
    return (
      <ExpansionPanel key='resources'>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          Reports
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display: 'block'}}>
          {reportKeys.map(key => {
            let report = this.state.config.singleReport[key];
            return (
              <Button
                key={key}
                variant='contained'
                style={{margin: '0px 16px 16px 0px'}}
                onClick={(e) => {
                  if (report.type === 'link') {
                    window.open(this.state.object[report.linkField], '_blank');
                  } else {
                    let method = (report.request || "").replace(":id", this.state.object.id);
                    sendRequest({
                      method,
                      type: report.type,
                      data: {id: this.state.object.id},
                      success: (data) => {
                        this.setState({responseContent: data});
                      }
                    });
                  }
                }}
              >{report.button}</Button>
            )
          })}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  render = () => {
    var views = [
      this.renderInfoSection(),
      this.renderDetailsSection(),
      this.renderAssignSection(),
      this.renderWorkScopeSection(),
      this.renderFrequencySection(),
      this.renderReportsSection(),
    ];
    if (this.state.object.id) {
      if (this.state.object.identifier) {
        views.unshift(
          <div key='id' className='outerLabel'>
            ID : <b>{this.state.object.identifier}</b>
          </div>
        );
      }
      if (this.state.object.started_at) {
        views.unshift(
          <div key='start' className='outerLabel'>
            Start time : <b>{this.state.object.started_at}</b>
          </div>
        );
      }
    }
    
    let saveButton = <Button
      variant='contained'
      color='primary'
      onClick={this.submitData}
    >
      <SaveIcon style={{marginRight: 8}}/>
      Save Changes
    </Button>

    if (this.state.config.preset || this.state.object.deleted || this.state.object.disable_edit) {
      saveButton = null;
    }
    if (this.state.config.modifyCondition && !this.state.config.modifyCondition(this.state.object)) {
      saveButton = null;
    }

    let viewType = !this.state.object.id ? "Create" : this.state.edit ? "Edit" : "View";
    return (
      <div className="adminContent wizardContent customScrollBar">

        <h1 className="page-header">
          <span className='backLink' onClick={this.handleFinishEdit}>
            <i className="fa fa-angle-left" aria-hidden="true"></i>
          </span>
          {this.state.config.objectName} {viewType}
        </h1>

        <form className="form-horizontal" onSubmit={(e) => e.preventDefault()}>
          {views}
          <div style={{
            textAlign: "right",
            marginTop: 35,
            paddingRight: this.state.isMobile ? 16 : 0,
          }}>
            {saveButton}
          </div>
        </form>

        {this.renderReport()}
        {this.renderResponse()}

      </div>
    );
  }
}

export default connect(mapStoreToProps)(OrderView)
