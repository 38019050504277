import React from 'react';
import $ from "jquery";
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from  '@material-ui/icons/Menu';
import NotificationIcon from  '@material-ui/icons/Notifications';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Drawer from '@material-ui/core/Drawer';

import SidebarView from './SidebarView.js';
import UserAvatarView from './UserAvatarView';

import { sendRequest } from '../helpers/global.js';
import Storage from '../helpers/Storage.js';

const styles = {
  container: isMobile => ({
    backgroundColor: '#009be5',
    position: 'fixed',
    height: 54,
    width: isMobile ? '100%' : 'calc(100% - 260px)',
    marginLeft: isMobile ? 0 : 260,
    zIndex: 2,
    textAlign: 'right',
    fontSize: '24px',
    padding: '0px 15px',
  }),
  unreadIndicator: {
    position: 'absolute',
    top: 1,
    left: 25,
    borderRadius: '50%',
    border: '1px solid #ffffff',
    backgroundColor: '#57d599',
    color: '#ffffff',
    fontSize: '14px',
    lineHeight: '20px',
    padding: '0px 6px',
  },
  notification: (checked, separate) => ({
    fontSize: '14px',
    lineHeight: '18px',
    padding: `${separate ? 13 : 11}px 16px 11px`,
    backgroundColor: checked ? '#ffffff' : '#ddffef',
    transition: 'background-color 900ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    width: 260,
    wordBreak: 'break-word',
    textAlign: 'left',
    borderTop: separate ? '1px solid #dddddd' : 'none',
  }),
};

const mapStoreToProps = (store) => {
  return {
    user: store.data.user,
    notifications: store.data.notifications,
    isMobile: store.setup.is_mobile,
  }
};

class HeaderView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: Storage.getData('user'),
      notifications: Storage.getData('notifications') || {},
      isMobile: !!Storage.getSetup('is_mobile'),
      menuOpen: false,
      optionsOpen: false,
      notificationsOpen: false,
    };
  }

  componentDidMount = () => {
    sendRequest({
      method: 'notifications',
      type: 'GET',
      success: (data) => {
        this.setState({notifications: data});
        Storage.setData('notifications', data);
      },
      error: (xhr, status, err) => {
      }
    });
  }

  componentWillReceiveProps = (props) => {
    let stateChange = {};
    let mapping = mapStoreToProps({data: {}, setup: {}});
    Object.keys(mapping).forEach((key) => {
      if (typeof props[key] !== 'undefined' && props[key] !== null) {
        stateChange[key] = props[key];
      }
    });
    this.setState(stateChange);
  }

  checkNotifications = () => {
    let notifications = this.state.notifications;
    if (notifications.unread_count > 0) {
      sendRequest({
        method: 'notifications',
        data: {check: true},
        type: 'GET',
        noLoad: true,
      });
      setTimeout(() => {
        let readNotifications = {
          unread_count: 0,
          notifications: notifications.notifications.map(n => ({...n, checked: true})),
        }
        Storage.setData('notifications', readNotifications);
      }, 500);
    }
  }

  renderNotification = (notification, separate = false) => {
    return (
      <div
        key={notification.id}
        style={styles.notification(notification.checked, separate)}
      >{notification.title}</div>
    )
  }

  renderNotificationsButton = () => {
    let notifications = this.state.notifications;
    let notificationItems = notifications.notifications || [];
    return (
      <div style={{display: 'inline-block'}}>
        <IconButton
          style={{width: 48, height: 48, padding: 8, position: 'relative'}}
          //onClick={() => $(document).trigger('changePage', ['notifications'])}
          onClick={() => {
            this.setState({notificationsOpen: !this.state.notificationsOpen});
            this.checkNotifications();
          }}
        >
          <NotificationIcon style={{width: 32, height: 32, color: '#ffffff'}}/>
          {notifications.unread_count > 0 ?
            <div style={styles.unreadIndicator}>{notifications.unread_count}</div>
          : null}
        </IconButton>
        <Popper open={this.state.notificationsOpen} anchorEl={this.anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper elevation={2}>
                <ClickAwayListener onClickAway={() => this.setState({notificationsOpen: false})}>
                  <MenuList>
                    {notificationItems.length === 0 ?
                      this.renderNotification({id: 0, title: 'No notifications yet', checked: true})
                      :
                      notificationItems.map((notification, index) => 
                        this.renderNotification(notification, index > 0)
                      ) 
                    }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    )
  }

  renderProfileButton = () => {
    return (
      <React.Fragment>
        <IconButton
          style={{width: 48, height: 48, padding: 9}}
          buttonRef={node => this.anchorEl = node}
          onClick={() => this.setState({optionsOpen: !this.state.optionsOpen})}
        >
          <UserAvatarView user={this.state.user} size={30}/>
        </IconButton>
        <Popper open={this.state.optionsOpen} anchorEl={this.anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper elevation={2}>
                <ClickAwayListener onClickAway={() => this.setState({optionsOpen: false})}>
                  <MenuList>
                    <MenuItem
                      style={{fontSize: '14px'}}
                      onClick={() => {
                        this.setState({optionsOpen: false});
                        $(document).trigger('changePage', ['settings'])
                      }}
                    >Settings</MenuItem>
                    <MenuItem
                      style={{fontSize: '14px'}}
                      onClick={() => {
                        this.setState({optionsOpen: false});
                        this.props.onLogout();
                      }}
                    >Sign Out</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
    )
  }

  render = () => {
    if (!this.props.logged) {
      return (
        <div style={styles.container}>
        </div>  
      )
    }
    return (
      <div style={styles.container(this.state.isMobile)}>
        {this.state.isMobile ?
          <IconButton
            style={{width: 48, height: 48, padding: 8, float: 'left'}}
            onClick={() => this.setState({menuOpen: !this.state.menuOpen})}
          >
            <MenuIcon style={{width: 32, height: 32, color: '#ffffff'}}/>
          </IconButton>
        : null}
        {this.renderNotificationsButton()}
        {this.renderProfileButton()}
        <Drawer
          anchor='left'
          variant={this.state.isMobile ? 'temporary' : 'permanent'}
          open={this.state.menuOpen || !this.state.isMobile}
          onClose={() => this.setState({menuOpen: false})}
          PaperProps={{
            style: {border: 'none'}
          }}
        >
          <SidebarView
            page={this.props.page}
            items={this.props.items}
            onItemClick={() => this.setState({menuOpen: false})}
          />
        </Drawer>
      </div>
    )
  }

}

export default connect(mapStoreToProps)(HeaderView)
