import Storage from '../helpers/Storage.js';

Storage.setConfig("organizations", {
  roles: ['admin', 'agent'],
  rootSection: true,
  config: {
    method: "organizations/",
    title: "Organizations",
    objectName: "Organization",
  },
  properties: {
    avatar_url: {
      type: "avatar",
      width: "50px",
    },
    id: {
      default: false,
      type: "none",
      title: "#",
      preset: true,
    },
    created_at: {
      default: "",
      type: "text",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "text",
      title: "Modified Time",
      preset: true,
    },
    avatar_image: {
      default: "",
      type: "image",
      title: "Avatar",
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
      width: "auto"
    },
    address: {
      default: "",
      type: "text",
      title: "Address",
    },
    phone: {
      default: "",
      type: "text",
      title: "Phone",
    },
    fax: {
      default: "",
      type: "text",
      title: "Fax",
    },
    active: {
      default: false,
      type: "select",
      title: "Active",
      options: [{id: true, name: 'Yes'}, {id: false, name: 'No'}],
      width: "60px",
    },
    demo: {
      default: false,
      type: "select",
      title: "Demo",
      options: [{id: true, name: 'Yes'}, {id: false, name: 'No'}],
      width: "60px",
    },
    demo_hours: {
      default: "",
      type: "text",
      title: "Demo hours",
      showCondition: object => object.demo,
    },
    demo_ends_at: {
      default: "",
      type: "text",
      title: "Demo ends",
      preset: true,
      showCondition: object => object.demo,
    },

  }
});
