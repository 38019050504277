import React from 'react';
import $ from "jquery";
import update from 'immutability-helper';
import {Modal, Button as BTButton} from 'react-bootstrap';
import Button from '@material-ui/core/Button';

import { sendRequest } from '../helpers/global.js';
import Storage from '../helpers/Storage.js';

import FlexibleInput from './FlexibleInput.js';

export default class ObjectEditView extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getDefaultState(props);
  }
  
  getDefaultState = (props) => {
    var pageConfig = Storage.getConfig(props.configKey);
    var hasListView = pageConfig.section || pageConfig.rootSection;
    var properties = Object.assign({}, pageConfig.properties);
    var config = Object.assign({hasListView}, pageConfig.config);
    var keys = Object.keys(properties);
    var object = {};
    for (let i = 0; i < keys.length; i++) {
      var key = keys[i];
      if (props.object && props.object[key]) {
        object[key] = props.object[key];
      } else {
        if (Array.isArray(properties[key].default)) {
          object[key] = properties[key].default.slice();
        } else if (typeof properties[key].default === "object") {
          object[key] = Object.assign({}, properties[key].default);
        } else {
          object[key] = properties[key].default;
        }
      }
    }
    var state = {
      object: object, 
      edit: props.edit,
      properties: properties, 
      config: config,
      responseContent: false,
      current_report: null,
      errors: {},
    }
    return state;
  }

  componentWillReceiveProps = (props) => {
    if (props.configKey) {
      if (props.configKey !== this.props.configKey || (this.props.object && props.object.id !== this.props.object.id)) {
        var state = this.getDefaultState(props);
        this.setState(state);
        this.requestInitialData(state);
      } else if (props.edit !== this.props.edit) {
        this.setState({edit: props.edit});
      }
    }
  }

  updateStateWithData = (data) => {
    var keys = Object.keys(this.state.properties);
    var object = this.state.object;
    var properties = this.state.properties;
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (typeof data[key] !== "undefined" && data[key] !== null) {
        object[key] = data[key];
      }
    }
    this.setState({object: object, properties: properties});
  }

  componentDidMount = () => {
    this.requestInitialData();
  }

  requestInitialData = (state) => {
    state = state || this.state;
    if (!state.object.id && state.config.type !== "object") {
      return;
    }
    const config = state.config;
    const method = config.objectMethod || config.method;
    sendRequest({
      method: method + (config.type !== "object" ? state.object.id : ""),
      type: "GET",
      success: (data) => {
        this.updateStateWithData(data);
      },
      error: (xhr, status, err) => {
      }
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const config = this.state.config;
    const method = config.objectMethod || config.method;
    sendRequest({
      method: method + (this.state.object.id ? this.state.object.id : ""),
      type: this.state.object.id ? "PUT" : "POST",
      data: this.state.object,
      success: (data) => {
        if (data.id) {
          this.updateStateWithData(data);
          this.handleFinishEdit();
          $(document).trigger("showAlert", [`${config.objectName} saved`]);
        } else if (data.errors) {
          this.setState({errors: data.errors});
        } else {
          this.setState({responseContent: data});
        }
      },
      error: (xhr, status, err) => {
        if (xhr.responseJSON) {
          if (xhr.responseJSON.errors) {
            this.setState({errors: xhr.responseJSON.errors});
          } else {
            this.setState({responseContent: xhr.responseJSON});
          }
        }
      }
    });
  }

  handleFinishEdit = () => {
    if (this.state.config.type !== "object") {
      $(document).trigger("changePage", [this.props.configKey]);
    }
  }

  handleEdit = (e) => {
    e.preventDefault();
    $(document).trigger("changePage", [this.props.configKey, this.state.object, "edit"]);
  }

  handlePropertyChange = (key, value) => {
    this.setState(update(this.state, {
      object: {
        [key]: {$set: value},
      },
      errors: {
        [key]: {$set: null},
      },
    }));
  }

  onReportClick = (e, key) => {
    var report = this.state.config.singleReport[key];
    if (report.report_type === "link") {
      let link = this.state.object[report.link_field];
      window.open(link, "_blank");
      return;
    }
    if (report.report_type === "share") {
      let link = this.state.object[report.link_field];
      this.setState({responseContent: {copyable: link}});
      return;
    }
    if (report.report_type === "create") {
      this.setState({current_report: {key: key}});
      return;
    }
    let method = (report.request || "").replace(":id", this.state.object.id);
    sendRequest({
      method,
      type: report.type,
      data: {id: this.state.object.id},
      success: (data) => {
        this.setState({responseContent: data});
      }
    });
  }

  onModalClose = () => {
    if (this.state.responseContent.redirectBack) {
      this.handleFinishEdit();
    } else {
      this.setState({responseContent: false});
    }
  }

  onReportCancel = (e) => {
    this.setState({current_report: null});
  }

  onReportConfirm = () => {
    var report = this.state.config.singleReport[this.state.current_report.key];
    var data = {id: this.state.object.id};
    if (report.report_type === "create") {
      data[report.nameParameter] = $('input[data-id="report_create"]').val();
    }
    sendRequest({
      method: report.request,
      type: report.type,
      data: data,
      success: (data) => {
        this.setState({responseContent: data});
      }
    });
  }

  render = () => {
    var inputs = Object.keys(this.state.properties).map((key) => {
      if (!this.state.properties[key].type || ["none", "avatar"].indexOf(this.state.properties[key].type) > -1) {
        return null;
      }
      let groupHidden = this.state.properties[key].preset && !this.state.object.id;
      if (this.state.properties[key].showCondition && !this.state.properties[key].showCondition(this.state.object)) {
        groupHidden = true;
      }
      let inputDisabled = this.state.properties[key].preset;
      if (!this.state.edit && this.state.object.id > 0) {
        inputDisabled = true;
      }
      if (this.state.edit && this.state.properties[key].createOnly) {
        inputDisabled = true;
      }
      if (this.state.properties[key].modifyCondition && !this.state.properties[key].modifyCondition(this.state.object)) {
        inputDisabled = true;
      }
      let hostProp = this.state.properties[key].hostProperty;
      return (
        <div className="form-group" hidden={groupHidden} key={key}>
          <label htmlFor="id" className="col-sm-2 control-label">{this.state.properties[key].title}</label>
          <div className="col-sm-10">
            <FlexibleInput
              object={this.state.object[key]}
              parentId={this.state.object.id}
              onChange={this.handlePropertyChange}
              disabled={inputDisabled}
              objectKey={key}
              objectProperties={this.state.properties}
              hostPropertyValue={hostProp ? this.state.object[hostProp] : null}
            />
            {this.state.errors[key] ? 
              <div className='inputError'>{this.state.errors[key]}</div>
            : null}
          </div>
        </div>
      );
    });
    
    var actionButton = null;
    if (this.state.object.id && !this.state.edit) {
      actionButton = <Button
        variant='outlined'
        color='primary'
        onClick={this.handleEdit}
      >Edit</Button>
    } else {
      actionButton = <Button
        variant='contained'
        color='primary'
        onClick={this.handleSubmit}
      >Save</Button>
    }
    if (this.state.config.preset || this.state.object.deleted) {
      actionButton = null;
    }
    if (this.state.config.modifyCondition && !this.state.config.modifyCondition(this.state.object)) {
      actionButton = null;
    }

    var singleReportButtons = null;
    if (this.state.config.singleReport) {
      singleReportButtons = Object.keys(this.state.config.singleReport).map((key) => {
        let report = this.state.config.singleReport[key];
        if (report.showCondition(this.state.object)) {
          return(
            <button 
              key={key}
              className="btn button-report"
              onClick={(e) => {this.onReportClick(e, key)}}
            >{report.button}</button>
          );
        } else {
          return null;
        }
      });
    }

    // report
    var reportTitle = null;
    var reportButton = null;
    var reportForm = null;
    if (this.state.current_report !== null) {
      var report = this.state.config.singleReport[this.state.current_report.key];
      reportTitle = report.button;
      reportButton = report.confirm;
      if (report.report_type === "create") {
        reportForm = <input
          key="report_create"
          data-id="report_create"
          placeholder={report.placeholder}
          className="form-control"
        />
      }
    }

    var responseBody = [];
    if (this.state.responseContent) {
      let content = this.state.responseContent;
      if ($.isArray(content.error)) {
        responseBody.push( content.error.map((err, i) => {
          return <span key={"error" + i} className="errorMessage">{err}</span>;
        }) );
      } else if (typeof content.error === "string") {
        responseBody.push( <span key="error" className="errorMessage">{content.error}</span> );
      }
      if (content.message) {
        if (responseBody.length > 0) {
          responseBody.push( <br key={"br" + responseBody.length}/> );
        }
        responseBody.push( content.message );
      }
      if (content.link) {
        if (responseBody.length > 0) {
          responseBody.push( <br key={"br" + responseBody.length}/> );
        }
        responseBody.push( <a key="link" href={content.link} target="_blank" rel="noopener noreferrer">Download link</a> );
      }
      if (content.copyable) {
        if (responseBody.length > 0) {
          responseBody.push( <br key={"br" + responseBody.length}/> );
        }
        let copyToClipboard = (e) => {
          let input = $(e.currentTarget).parent().find("input");
          input.focus();
          input[0].setSelectionRange(0, input.val().length);
          try {
              console.log(document.execCommand("copy"));
          } catch(e) {}
        }
        responseBody.push( <div key="copyable" className="copyable">
          <input value={content.copyable} readOnly={true} className="form-control"/>
          <button className="btn btn-default" onClick={copyToClipboard}>
            <i className="fa fa-clipboard"></i>
          </button> 
        </div> );
      }
    }

    let backButton = <span className='backLink' onClick={this.handleFinishEdit}>
      <i className="fa fa-angle-left" aria-hidden="true"></i>
    </span>
    let viewType = !this.state.object.id ? "Create" : this.state.edit ? "Edit" : "View";
    if (this.state.config.type === "object") {
      backButton = null;
      viewType = "";
    }
    if (!this.state.config.hasListView) {
      backButton = null;
    }

    return (
      <div className="adminContent customScrollBar">

        <div className="reportButtons">
          {singleReportButtons}
        </div>
        <h1 className="page-header">
          {backButton}
          {`${this.state.config.objectName} - ${viewType}`}
        </h1>

        <div className="card">
          <form className="form-horizontal" onSubmit={(e) => {e.preventDefault();}}>
            {inputs}
            <div className="form-group">
              <div className="col-sm-offset-2 col-sm-10">
                {actionButton}
              </div>
            </div>
          </form>
        </div>

        <Modal show={!!this.state.responseContent} onHide={this.onModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Server response</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {responseBody}
          </Modal.Body>
          <Modal.Footer>
            <BTButton onClick={this.onModalClose}>Close</BTButton>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.current_report !== null} onHide={this.onReportCancel}>
          <Modal.Header closeButton>
            <Modal.Title>{reportTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {reportForm}
          </Modal.Body>
          <Modal.Footer>
            <BTButton onClick={this.onReportCancel}>Cancel</BTButton>
            <BTButton 
              onClick={this.onReportConfirm} 
              bsStyle="primary"
            >{reportButton}</BTButton>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}
