import React from 'react';

import SelectInput from './input/SelectInput.js';
import CheckboxInput from './input/CheckboxInput.js';
import DateInput from './input/DateInput.js';
import RichTextInput from './input/RichTextInput.js';
import ImageInput from './input/ImageInput.js';
import IntervalInput from './input/IntervalInput.js';
import TextInput from './input/TextInput.js';
import JsonInput from './input/JsonInput.js';
import FileInput from './input/FileInput';
import LinkInput from './input/LinkInput';
import PageLinkInput from './input/PageLinkInput';
import DefectsInput from './input/DefectsInput';
import NotesInput from './input/NotesInput';
import ChangesInput from './input/ChangesInput';

export default class FlexibleInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    var properties = this.props.objectProperties[this.props.objectKey];
    switch (properties.type) {
      case "text":
      case "password":
      case "textarea":
        return <TextInput  properties={properties} {...this.props} />
      case "wysiwyg":
        return <RichTextInput properties={properties} {...this.props} />
      case "select":
      case "multi":
        return <SelectInput properties={properties} {...this.props} />
      case "checkbox":
        return <CheckboxInput properties={properties} {...this.props} />
      case "date":
      case "datetime":
        return <DateInput properties={properties} {...this.props} />
      case "date-interval":
      case "interval":
        return <IntervalInput properties={properties} {...this.props} />
      case "image":
      case "image-crop":
        return <ImageInput properties={properties} {...this.props} />
      case "file":
        return <FileInput properties={properties} {...this.props} />
      case "link":
        return <LinkInput properties={properties} {...this.props} />
      case "page-link":
        return <PageLinkInput properties={properties} {...this.props} />
      case "json":
        return <JsonInput properties={properties} {...this.props} />
      case "defects":
        return <DefectsInput properties={properties} {...this.props} />
      case "notes":
        return <NotesInput properties={properties} {...this.props} />
      case "changes":
        return <ChangesInput properties={properties} {...this.props} />
      default:
        return null;
    }
  }
}
