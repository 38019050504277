import React from 'react';
import $ from "jquery";
import { connect } from 'react-redux';
import {Modal, Button as BTButton, Breadcrumb} from 'react-bootstrap';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import update from 'immutability-helper';
import Fab from '@material-ui/core/Fab';
import AddIcon from  '@material-ui/icons/Add';
import SortIcon from  '@material-ui/icons/Sort';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import { sendRequest, apiUrl } from '../helpers/global.js';
import Storage from '../helpers/Storage.js';

import SearchView from './SearchView.js';
import ListTabsView from './ListTabsView.js';
import ObjectListElem from './ObjectListElem.js';

$(document).ready(() => {
  localStorage.removeItem("ObjectListView-key");
  localStorage.removeItem("ObjectListView-page");
  localStorage.removeItem("ObjectListView-search");
  localStorage.removeItem("ObjectListView-from");
});

const ITEMS_PER_PAGE = 20;

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class ObjectListView extends React.Component {

  constructor(props) {
    super(props);
    let properties = Storage.getConfig(props.configKey).properties;
    let config = Storage.getConfig(props.configKey).config;
    let search = {
      paginate: true, 
      offset: 0,
    };
    // Default search interval
    if (config.search) {
      Object.keys(config.search).forEach((key) => {
        search[key] = config.search[key].default || null;
      });
    }
    this.state = {
      isMobile: !!Storage.getSetup('is_mobile'),
      objects: [],
      properties,
      config, 
      options: {},
      showConfirm: false, 
      pendingDelete: {},
      responseContent: false,
      search,
      tab: config.tabs && config.tabs[0] && config.tabs[0].key,
      sort: {
        key: null,
        order: 'desc',
      },
      export: {
        selected: [],
        current: null,
        disabled: true
      },
      page: 0,
      from: props.from || null,
    };
  }

  componentWillReceiveProps = (props) => {
    let stateChange = {};
    let mapping = mapStoreToProps({data: {}, setup: {}});
    Object.keys(mapping).forEach((key) => {
      if (typeof props[key] !== 'undefined' && props[key] !== null) {
        stateChange[key] = props[key];
      }
    });
    this.setState(stateChange);
  }

  componentDidMount = () => {
    if (this.state.config.parentPage) {
      let from = this.props.from || JSON.parse(localStorage.getItem("ObjectListView-from"));
      if (!from || from.length === 0 || from[from.length - 1].page !== this.state.config.parentPage) {
        $(document).trigger("changePage", [this.state.config.parentPage]);
      }
    }
    this.loadPage();
    this.requestData();
    this.requestOptions();
  }

  requestOptions = () => {
    let options = Object.assign({}, this.state.options);
    Object.keys(this.state.properties).forEach(key => {
      if (this.state.properties[key].width && this.state.properties[key].type === "select") {
        if (this.state.properties[key].request) {
          sendRequest({
            type: "GET",
            method: this.state.properties[key].request,
            success: (data) => {
              this.setState({options:
                update(this.state.options, {
                  [key]: {$set: data}
                })
              });
            },
            error: (xhr, status, err) => {}
          });
        } else if (this.state.properties[key].options) {
          options[key] = this.state.properties[key].options;
        }
      }
    });
    this.setState({options});
  }

  updateStateWithData = (data) => {
    let stateChange = {
      page: this.state.page,
    };
    // add or remove objects to fit new count
    stateChange.objects = this.state.objects.splice(0, data.count);
    for (let i = stateChange.objects.length; i < data.count; i++) {
      stateChange.objects.push({});
    }
    // drop page number to fit new count
    let pageNum = Math.ceil(data.count / ITEMS_PER_PAGE);
    if (stateChange.page >= pageNum) {
      stateChange.page = 0;
    }
    // save new objects
    for (let i = 0; i < data.objects.length; i++) {
      let index = stateChange.page * ITEMS_PER_PAGE + i;
      stateChange.objects[index] = data.objects[i];
    }
    this.setState(stateChange);
  }

  requestData = (state) => {
    if (!state) {
      state = this.state;
    }
    let data = {};
    if (state.search) {
      data = state.search;
    }
    if (state.tab) {
      data.tab = state.tab;
    }
    if (state.sort && state.sort.key) {
      data.sort = state.sort.key;
      data.sort_order = state.sort.order;
    }
    data.paginate = true;
    data.offset = state.page * ITEMS_PER_PAGE;
    if (this.state.from && this.state.from.constructor.name === "Array" && this.state.from.length > 0) {
      var last = this.state.from[this.state.from.length - 1];
      data[last.param] = last.object.id;
    }
    sendRequest({
      method: state.config.method,
      data: data,
      type: "GET",
      success: (data) => {
        this.updateStateWithData(data);
      },
      error: (xhr, status, err) => {
      }
    });
  }

  onObjectActiveSwitch = (object) => {
    sendRequest({
      method: `${this.state.config.method}${object.id}/${object.active ? 'deactivate' : 'activate'}`,
      type: "POST",
      success: (data) => {
        this.requestData(this.state);
      },
      error: (xhr, status, err) => {
      }
    });
  }

  onObjectDelete = (object) => {
    this.setState({pendingDelete: object, showConfirm: true});
  }

  onDeleteCancel = (e) => {
    this.setState({pendingDelete: {}, showConfirm: false});
  }

  onDeleteConfirm = (e) => {
    var object = this.state.pendingDelete;
    sendRequest({
      method: this.state.config.method + object.id,
      type: "DELETE",
      success: (data) => {
        this.setState({pendingDelete: {}, showConfirm: false});
        this.requestData(this.state);
      },
      error: (xhr, status, err) => {
      }
    });
  }

  onSearch = (search) => {
    var data = this.state.search;
    var keys = Object.keys(search);
    for (let i = 0; i < keys.length; i++) {
      var key = keys[i];
      if (search[key] === undefined || search[key] === null) {
        data[key] = null;
        continue;
      }
      data[key] = search[key];
    }
    
    var exp = this.state.export;
    exp.selected = [];
    exp.current = null;
    exp.disabled = true;
    this.setState({export: exp, page: 0});

    this.requestData();
  }

  onSelect = (objectId, select) => {
    var exp = Object.assign({}, this.state.export);
    if (select) {
      exp.selected.push(objectId);
    } else {
      exp.selected.splice($.inArray(objectId, exp.selected), 1);
    }
    this.setState({export: exp});
  }

  onSelectAll = (e) => {
    var exp = Object.assign({}, this.state.export);
    exp.selected = [];
    var select = this.state.export.selected.length !== this.state.objects.length;
    if (select) {
      for (let i = 0; i < this.state.objects.length; i++) {
        if (!this.state.objects[i].deleted) {
          exp.selected.push(this.state.objects[i].id);
        }
      }
    }
    this.setState({export: exp});
  }

  onExport = (key) => {
    var singleExport = this.state.config.export[key];
    if (singleExport.type === "get_report") {
      let params = $.param({
        [singleExport.listParameter]: this.state.export.selected,
        access_token: localStorage.getItem("zaiko-admin:token"),
      });
      let url = apiUrl(singleExport.method) + '?' + params;
      window.open(url, "_blank");
      return;
    }
    if (singleExport.type === "get") {
      this.onExportConfirm(singleExport);
      return;
    }
    var exp = this.state.export;
    exp.current = singleExport;
    exp.disabled = !singleExport.value;
    this.setState({export: exp});
  }

  onExportCancel = (e) => {
    var exp = this.state.export;
    exp.current = null;
    exp.disabled = true;
    this.setState({export: exp});
  }
  
  onExportConfirm = (exportParam) => {
    var singleExport = this.state.export.current || exportParam;
    var data = {};
    var requestType = "GET";
    if (singleExport.type === "create") {
      requestType = "POST";
      data[singleExport.nameParameter] = $('input[data-id="export_create"]').val();
    }
    if (singleExport.type === "choose") {
      requestType = "GET";
      data[singleExport.idParameter] = singleExport.value.value;
    }
    if (singleExport.type === "upload") {
      requestType = "POST";
      data[singleExport.fileParameter] = singleExport.value;
    }
    data[singleExport.listParameter] = this.state.export.selected;
    sendRequest({
      method: singleExport.method,
      type: requestType,
      data: data,
      success: (data) => {
        let exp = Object.assign({}, this.state.export);
        exp.current = null;
        let stateChange = {export: exp};
        if (this.state.export.current) {
          if (!data || data.status !== "ok") {
            stateChange.responseContent = data;
          }
          this.setState(stateChange);
          this.requestData();
        } else {
          stateChange.responseContent = data;
          this.setState(stateChange);
        }
      },
      error: (xhr, status, err) => {
        this.setState({responseContent: xhr.responseJSON});
      }
    });
  }

  onModalClose = () => {
    this.setState({responseContent: false});
  }

  handlePageClick = (e) => {
    var state = Object.assign({}, this.state);
    state.page = e.selected;
    this.requestData(state);
    this.setState({page: e.selected});
  }

  storePage = () => {
    localStorage.setItem("ObjectListView-key", this.props.configKey);
    localStorage.setItem("ObjectListView-page", this.state.page);
    localStorage.setItem("ObjectListView-search", JSON.stringify(this.state.search));
    if (this.state.from && this.state.from.constructor.name === "Array" && this.state.from.length > 0) {
      localStorage.setItem("ObjectListView-from", JSON.stringify(this.state.from));
    }
  }

  loadPage = (state) => {
    if (localStorage.getItem("ObjectListView-key") === this.props.configKey) {
      var page = parseInt(localStorage.getItem("ObjectListView-page"), 10) || 0;
      var search = JSON.parse(localStorage.getItem("ObjectListView-search")) || {};
      var from = JSON.parse(localStorage.getItem("ObjectListView-from"));

      this.setState({page, search, from});
      //this.state = update(this.state, {$merge: {page, search, from}});
    } else {
      localStorage.removeItem("ObjectListView-key");
      localStorage.removeItem("ObjectListView-page");
      localStorage.removeItem("ObjectListView-search");
      localStorage.removeItem("ObjectListView-from");
    }
  }

  onLinkBack = (index, e) => {
    if (index > this.state.from.length - 1) {
      return;
    }
    var page = this.state.from[index].page;
    var current = Object.assign([], this.state.from);
    current.splice(index);

    if(e.button === 0) {
      this.storePage();
      $(document).trigger("changePage", [page, null, current]);
    } else if (e.button === 1) {
      e.preventDefault();
      $(document).trigger("openPage", [page, null, current]);
    }
  }

  onObjectView = (object, e) => {
    if(getSelection().toString()){
        return;
    }
    var eventData = [this.props.configKey, object];
    if (this.state.config.linkTo) {
      var current = (this.state.from && this.state.from.constructor.name === "Array") ? this.state.from : [];
      current.push({
        page: this.props.configKey,
        title: this.state.config.title,
        param: this.state.config.linkParam,
        object
      });
      eventData = [this.state.config.linkTo, null, current];
    }

    this.storePage();
    $(document).trigger("changePage", eventData);
  }

  onObjectEdit = (object, e) => {
    this.storePage();
    $(document).trigger("changePage", [this.props.configKey, object, "edit"]);
  }

  onObjectCopy = (object, e) => {
    const copyAction = this.state.config.copyAction;
    if (copyAction && copyAction.request) {
      sendRequest({
        type: copyAction.type,
        method: copyAction.request.replace(":id", object.id),
        success: (data) => {
          this.requestData();
        },
        error: (xhr, status, err) => {}
      });
    }
  }

  onObjectCreate = (e) => {
    if(e.button === 0) {
      this.storePage();
      $(document).trigger("changePage", [this.props.configKey, {id: false}, "create"]);
    } else if (e.button === 1) {
      e.preventDefault();
      $(document).trigger("openPage", [this.props.configKey, {id: false}, "create"]);
    }
  }

  renderListColumn = (key) => {
    let columnProps = this.state.properties[key];
    let order = this.state.sort.key === key ? this.state.sort.order : null;
    return <th
      key={key}
      width={columnProps.width}
      className={columnProps.sort ? 'sortable' : ''}
      onClick={columnProps.sort ? () => {
        this.setState({
          sort: {key, order: order === 'desc' ? 'asc' : 'desc'},
        }, () => this.requestData())
      } : null}
    >
      {this.state.properties[key].title}
      {order ? 
        <SortIcon style={{
          verticalAlign: 'bottom',
          marginLeft: 9,
          transform: order === 'asc' ? 'scaleY(-1)' : null,
        }}/>
      : null}
    </th>
  }

  render = () => {
    // Breadcrumbs links
    var breadCrumbs = null;
    if (this.state.from && this.state.from.constructor.name === "Array" && this.state.from.length > 0) {
      let breadCrumbItems = [<Breadcrumb.Item 
        key="root" 
        onClick={(e) => {this.onLinkBack(0, e)}}
      >
        {this.state.from[0].title || this.state.from[0].page}
      </Breadcrumb.Item>]
      this.state.from.forEach((from, index) => {
        breadCrumbItems.push(<Breadcrumb.Item 
          key={index}
          active={index === this.state.from.length -1}
          onClick={(e) => {this.onLinkBack(index + 1, e)}}
        >
          {from.object.name}
        </Breadcrumb.Item>);
      })
      breadCrumbs = <Breadcrumb>
        {breadCrumbItems}
      </Breadcrumb>
    }

    // Selects and Export
    var selectColumn = null;
    var exportButtons = null;
    var expConf = this.state.config.export;
    if (expConf) {
      var needSelect = false;
      var disableExport = this.state.export.selected.length === 0;
      exportButtons = Object.keys(expConf).map((key) => {
        needSelect = needSelect || !expConf[key].noSelect;
        return (
          <Button
            key={key}
            variant='outlined'
            color='primary'
            disabled={!expConf[key].noSelect && disableExport}
            onClick={() => this.onExport(key)}
            style={{marginRight: 16, marginBottom: this.state.isMobile ? 16 : 0}}
          >{expConf[key].button}</Button>
        )
      });
      if (needSelect) {
        selectColumn = <th width="40px">
          <Checkbox
            color='primary'
            checked={this.state.objects.length > 0 && this.state.objects.length === this.state.export.selected.length}
            disabled={!this.state.objects.length}
            onChange={this.onSelectAll}
            style={{padding: 0}}
          />
        </th>;
      }
    }

    // Objects
    let columns = Object.keys(this.state.properties)
      .filter(key => !!this.state.properties[key].width)
      .filter(key => {
        let condition = this.state.properties[key].listCondition;
        return !condition || condition(this.state.object)
      })
      .map(this.renderListColumn);
    var objects = [];
    for (let i = 0; i < ITEMS_PER_PAGE; i++) {
      let index = this.state.page * ITEMS_PER_PAGE + i;
      if (index >= this.state.objects.length) {
        break;
      }
      let object = this.state.objects[index];
      objects.push(
        <ObjectListElem 
          key={index}
          configKey={this.props.configKey}
          onDelete={this.onObjectDelete}
          onView={this.onObjectView}
          onEdit={this.onObjectEdit}
          onCopy={this.onObjectCopy}
          onActiveSwtich={this.onObjectActiveSwitch}
          inSelection={$.inArray(object.id, this.state.export.selected) > -1}
          onSelect={this.onSelect}
          preset={this.state.config.preset}
          needSelect={selectColumn !== null}
          options={this.state.options}
          {...object}
        />
      );
    }

    // Search
    let searchView = null;
    if (this.state.config.search) {
      searchView = <SearchView
        config={this.state.config.search}
        onSearch={this.onSearch}
      />;
    }

    let tabsView = null;
    if (this.state.config.tabs) {
      tabsView = <ListTabsView
        tabs={this.state.config.tabs}
        onTabChange={tab => {
          if (tab !== this.state.tab) {
            this.setState({
              tab,
              export: {...this.state.export, selected: []}
            }, () => this.requestData())
          }
        }}
      />
    }

    // Pagination
    var pagination = null;
    var pageNum = Math.ceil(this.state.objects.length / ITEMS_PER_PAGE);
    if (pageNum > 1) {
      pagination =
      <ReactPaginate 
        previousLabel="previous"
        nextLabel="next"
        breakLabel={<span href="">...</span>}
        breakClassName={"break-me"}
        pageCount={pageNum}
        forcePage={this.state.page}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"} 
      />
    }

    // Current export
    var exportForm = null;
    var exportTitle = "...";
    var exportButton = "...";
    var exportDisabled = this.state.export.disabled;
    var singleExport = this.state.export.current;
    if (singleExport !== null) {
      exportTitle = singleExport.button;
      exportButton = singleExport.confirm;
      if (singleExport.type === "create") {
        let onChange = (e) => {
          var exp = this.state.export;
          if (exp.disabled ^ e.target.value.length === 0) {
            exp.disabled = e.target.value.length === 0;
          }
          this.setState({export: exp});
        }
        exportForm = 
        <input
          type="text"
          data-id="export_create"
          placeholder={singleExport.placeholder}
          className="form-control"
          onChange={onChange}
        />
      }
      if (singleExport.type === "upload") {
        let onChange = (e) => {
          var exp = this.state.export;
          exp.disabled = e.target.files.length === 0;
          if (e.target.files.length > 0) {
            var reader = new FileReader();
            reader.onload = (e) => {
              exp.current.value = e.target.result;
              this.setState({export: exp});
            }
            reader.readAsDataURL(e.target.files[0]);
          } else {
            exp.current.value = null;
            this.setState({export: exp});
          }
        }
        exportForm = 
        <input
          type="file"
          data-id="export_upload"
          accept={singleExport.accept}
          className="form-control"
          onChange={onChange}
        />
      }
      if (singleExport.type === "choose") {
        let onChange = (e) => {
          var exp = this.state.export;
          exp.disabled = false;
          exp.current.value = e;
          this.setState({export: exp});
        }
        exportForm = <Select
          placeholder={singleExport.placeholder}
          clearable={false}
          onChange={onChange}
          options={singleExport.options || []}
          value={singleExport.value}
        />
      }
    }

    // response error
    var responseError = null;
    if (this.state.responseContent && this.state.responseContent.error) {
      if ($.isArray(this.state.responseContent.error)) {
        responseError = this.state.responseContent.error.map((err) => {
          return <span className="errorMessage">{err}</span>;
        });
      } else {
        responseError = <span className="errorMessage">{this.state.responseContent.error}</span>
      }
    }

    var editColumn = <th width="44px"></th>;
    var deleteColumn = <th width="44px"></th>;
    var addButton = <Fab
      color='primary'
      onClick={this.onObjectCreate}
      style={{position: 'fixed', right: 35, bottom: 40}}
    ><AddIcon/></Fab>
    if (this.state.config.preset) {
      addButton = null;
      editColumn = null;
      deleteColumn = null;
    }
    if (this.state.config.disableAdd) {
      addButton = null;
    }
    if (
      this.state.config.createRoles &&
      this.state.config.createRoles.indexOf(Storage.getData('user').role) < 0
    ) {
      addButton = null;
    }
    if (this.state.config.disableDelete) {
      deleteColumn = null;
    }

    var copyColumn = null;
    if (this.state.config.copyAction) {
      copyColumn = <th width="44px"></th>;
    }

    let pendingDelete = this.state.pendingDelete;
    let deleteId = pendingDelete.identifier || pendingDelete.serial_number || '';
    return (
      <div className={"adminContent customScrollBar " + (this.state.config.customClass || "")}>
        <h1 className="page-header">
          {this.state.config.title}
        </h1>
        {tabsView}
        {searchView}
        {breadCrumbs}
        <div className="card">
          {this.state.isMobile ? objects :
            <table className="table table-hover">
              <thead>
                <tr className="table-active">
                  {selectColumn}
                  {columns}
                  {copyColumn}
                  {editColumn}
                  {deleteColumn}
                </tr>
              </thead>
              <tbody>
                {objects}
              </tbody>
            </table>
          }
          {pagination}
          {addButton}
          <div className="exportBox">
            {exportButtons}
          </div>
        </div>

        <Modal show={this.state.showConfirm} onHide={this.onDeleteCancel}>
          <Modal.Header closeButton>
            <Modal.Title>DELETE</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>{`Are you sure want to delete 
              ${pendingDelete.name || this.state.config.objectName} ${deleteId}
            ?`}<br/>Type DELETE to confirm</h4>
            <input
              type='text'
              value={pendingDelete.confirmation || ''}
              onChange={e => this.setState({pendingDelete: {...pendingDelete, confirmation: e.target.value}})}
            />
          </Modal.Body>
          <Modal.Footer>
            <BTButton onClick={this.onDeleteCancel}>Cancel</BTButton>
            <BTButton
              onClick={() => {
                if ((pendingDelete.confirmation || '').toLowerCase() === 'delete') {
                  this.onDeleteConfirm();
                }
              }}
              bsStyle={(pendingDelete.confirmation || '').toLowerCase() === 'delete' ? 'danger' : null}
            >Delete</BTButton>
          </Modal.Footer>
        </Modal> 

        <Modal show={this.state.export.current !== null} onHide={this.onExportCancel}>
          <Modal.Header closeButton>
            <Modal.Title>{exportTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {exportForm}
          </Modal.Body>
          <Modal.Footer>
            <BTButton onClick={this.onExportCancel}>Cancel</BTButton>
            <BTButton 
              onClick={this.onExportConfirm} 
              bsStyle="primary"
              disabled={exportDisabled}
            >{exportButton}</BTButton>
          </Modal.Footer>
        </Modal> 

        <Modal show={!!this.state.responseContent} onHide={this.onModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Server response</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {responseError}
            {this.state.responseContent.message}
            <br/>
            <a href={this.state.responseContent.link} target="_blank" rel="noopener noreferrer">{this.state.responseContent.link ? "Download link" : ""}</a>
          </Modal.Body>
          <Modal.Footer>
            <BTButton onClick={this.onModalClose}>Close</BTButton>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}

export default connect(mapStoreToProps)(ObjectListView)
