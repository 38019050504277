import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import { Line, Bar, Pie } from 'react-chartjs-2';

import { sendRequest } from '../helpers/global.js';
import Storage from '../helpers/Storage.js';

import SearchView from './SearchView.js';
import GraphListView from './GraphListView.js';
import GraphTableView from './GraphTableView.js';

export default class GraphsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getDefaultState(props);
  }
  
  getDefaultState = (props) => {
    var state = {
      configKey: props.configKey,
      graphs: Storage.getConfig(props.configKey).graphs,
      config: Storage.getConfig(props.configKey).config,
      search: {},
      data: {},
      responseContent: false,
    };
    // Default search interval
    if (state.config.search) {
      Object.keys(state.config.search).forEach((key) => {
        if (state.config.search[key].default) {
          state.search[key] = state.config.search[key].default;
        }
      });
    }
    return state;
  }

  componentWillReceiveProps = (props) => {
    if (props.configKey && this.state.configKey !== props.configKey) {
      var state = this.getDefaultState(props);
      this.setState(state);
      this.requestInitialData(state);
    }
  }

  componentDidMount = () => {
    this.requestInitialData();
  }

  requestInitialData = (state) => {
    if (typeof state === "undefined") {
      state = this.state;
    }
    sendRequest({
      method: state.config.method,
      type: "GET",
      data: state.search,
      success: (data) => {
        this.setState({data: data});
      },
      error: (xhr, status, err) => {
      }
    });
  }

  onReportClick = (e, key) => {
    var report = this.state.config.singleReport[key];
    sendRequest({
      method: report.request,
      type: report.type,
      success: (data) => {
        this.setState({responseContent: data});
      }
    });
  }

  onModalClose = () => {
    this.setState({responseContent: false});
  }

  onSearch = (search) => {
    var data = this.state.search;
    var keys = Object.keys(search);
    for (let i = 0; i < keys.length; i++) {
      var key = keys[i];
      var type = this.state.config.search[key].type;
      if (search[key] === undefined || search[key] === null) {
        data[key] = null;
        continue;
      }
      if (type === "select") {
        data[key] = search[key].id || search[key].value || search[key];
      } else if (type === "multi") {
        data[key] = [];
        for (let j = 0; j < search[key].length; j++) {
          data[key].push(search[key][j].id); 
        }
      } else {
        data[key] = search[key];
      }
    }
    this.requestInitialData();
  }

  render = () => {

    // report buttons
    var singleReportButtons = null;
    if (this.state.config.singleReport) {
      singleReportButtons = Object.keys(this.state.config.singleReport).map((key) => { // eslint-disable-line
        let report = this.state.config.singleReport[key];
        if (report.showCondition(this.state.object)) {
          return(
            <button 
              key={key}
              className="btn button-report"
              onClick={(e) => {this.onReportClick(e, key)}}
            >{report.button}</button>
          );
        }
      });
    }

    // Search
    var searchView = null;
    if (typeof this.state.config.search !== "undefined") {
      searchView = 
      <SearchView
        config={this.state.config.search}
        onSearch={this.onSearch}
        defaultValue={this.state.search}
      />;
    }

    // Graphs
    var graphs = null;
    if (this.state.data) {
      graphs = Object.keys(this.state.graphs).map((key) => {
        var graph = this.state.graphs[key];
        var graphData = this.state.data[key];
        if (!graphData) {
          return null;
        }

        var data = {
          labels: graphData ? (graphData.keys || []) : [],
          datasets: [],
          ids: graphData ? (graphData.ids || []) : [],
        };

        Object.keys(graph.options).forEach((optionKey) => {
          var graphOption = graph.options[optionKey];
          if (!graphData[optionKey]) {
            return;
          }
          data.datasets.push({
            label: graphOption.title,
            data: graphData[optionKey],
            backgroundColor: graphOption.backgroundColor,
            borderColor: graphOption.borderColor,
            // line
            pointRadius: 1,
            pointBorderWidth: 1,
            pointHoverRadius: 7,
            pointHitRadius: 7,
            // custom
            columns: graphOption.columns,
            showIds: graphOption.showIds,
          });
        });

        var options = {
          title: {
            display: true,
            text: graph.title,
          },
        }
        if (graph.type !== "pie") {
          options.scales = { yAxes: [ {
            ticks: {
              beginAtZero: true,
            },
            afterFit: function(scaleInstance) {
              scaleInstance.width += 10;
            }
          } ] };
        }

        var width = 1000;
        var height = graph.size === "half" ? 1000 : 475;

        var chart = null;
        if (graph.type === "bar") {
          chart = <Bar data={data} options={options} width={width} height={height} />
        }
        if (graph.type === "pie") {
          chart = <Pie data={data} options={options} width={width} height={height} />
        }
        if (graph.type === "line") {
          chart = <Line data={data} options={options} width={width} height={height} />
        }
        if (graph.type === "list") {
          chart = <GraphListView data={data} options={options} objectPageKey={graph.objectPageKey}/>
        }
        if (graph.type === "table") {
          chart = <GraphTableView data={data} options={options} objectPageKey={graph.objectPageKey}/>
        }

        return (
          <div key={key} className={"graph " + graph.size + " type-" + graph.type}>{chart}</div>
        )

      });
    }
    return (
      <div className="adminContent dashboard customScrollBar">
        <div className="reportButtons">
          {singleReportButtons}
        </div>
        <h1 className="page-header">
          {this.state.config.title}
        </h1>
        {searchView}
        {graphs}

        <Modal show={!!this.state.responseContent} onHide={this.onModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Server response</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span className="errorMessage">{this.state.responseContent.error}</span>
            {this.state.responseContent.message}
            <br/>
            <a href={this.state.responseContent.link} target="_blank" rel="noopener noreferrer">{this.state.responseContent.link ? "Download link" : ""}</a>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onModalClose}>Close</Button>
          </Modal.Footer>
        </Modal>

      </div>
    )
  }
}
