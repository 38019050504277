import Storage from '../helpers/Storage.js';

Storage.setConfig("orders_table", {
  rootSection: true,
  config: {
    type: "orders-scheduler",
    method: "orders/calendar/",
    objectMethod: "orders/",
    title: "Orders Scheduler",
    objectName: "Work Order",
  },
  properties: {
    id: {
      default: false,
      type: "none",
      title: "#",
      preset: true,
    },
    disable_edit: {
      default: false,
      type: "none",
      preset: true,
    },
    identifier: {
      default: false,
      type: "text",
      title: "Identifier",
      preset: true,
      width: "180px"
    },
    created_at: {
      default: "",
      type: "text",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "text",
      title: "Modified Time",
      preset: true,
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
      placeholder: "Overwrite autogenerated Name (e.g. Pref1234Suff)",
      width: "auto"
    },
    scheduled_at: {
      default: "",
      type: "date",
      title: "Date",
      width: "100px",
      sort: true,
    },
    remarks: {
      default: "",
      type: "textarea",
      title: "Special instructions",
    },
    customer_id: {
      default: null,
      type: "select",
      title: "Customer",
      placeholder: "Customer",
      objectPageKey: "customers",
      request: "customers/all",
      filterCondition: item => !!item.active,
    },
    status: {
      default: "",
      type: "text",
      title: "Status",
      preset: true,
      width: "100px"
    },
    technician_id: {
      default: null,
      type: "select",
      title: "Technician",
      placeholder: "Technician",
      objectPageKey: "users",
      request: "users/technicians",
      displayField: "username",
      width: "120px",
    },
    logs: {
      default: "",
      type: "textarea",
      title: "Logs",
      preset: true,
    },
    parts: {
      default: [],
    },
    repeat_interval: {
      default: null,
      type: "select",
      title: "Repeat interval",
      placeholder: "Repeat interval",
      request: "orders/repeat_intervals",
    },
    summary_report_url: {
      default: false,
      type: "none",
      preset: true,
    },
    
  }
});
