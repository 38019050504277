import React from 'react';

export default class PageLinkInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      object: this.props.object,
      properties: this.props.properties,
    };
  }

  handleChange = (e) => {
    let object = e.target.value;
    this.setState({object: object});
    this.props.onChange(this.props.objectKey, object);
  }

  componentWillReceiveProps = (props) => {
    if (typeof props.object !== "undefined" && props.object !== null) {
      this.setState({object: props.object});
    }
  }

  render = () => {
    const object = this.state.object || {};
    return (
      <span
        className={`view-link ${object.page ? 'clickable' : ''}`}
        onClick={() => {
          if (object.page) {
            window.open(object.page, '_blank');
          }
        }}
      >{object.title}</span>
    )
  }

}
