import React from 'react';
import Datetime from 'react-datetime';

export default class DateInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      object: this.props.object,
      properties: this.props.properties,
    };
  }

  handleChange = (e) => {
    let object = null;
    var properties = this.state.properties;
    if (properties.type === "date") {
      object = e.format ? e.format("YYYY-MM-DD") : e;
    } else if (properties.type === "datetime") {
      object = e.format ? e.format("YYYY-MM-DD HH:mm") : e;
    }
    this.setState({object: object});
    this.props.onChange(this.props.objectKey, object);
  }

  componentWillReceiveProps = (props) => {
    if (typeof props.object !== "undefined" && props.object !== null) {
      this.setState({object: props.object});
    }
  }

  render = () => {
    var properties = this.state.properties;
    return (
      <Datetime 
        value={this.state.object}
        onChange={this.handleChange}
        inputProps={{disabled: this.props.disabled, placeholder: this.props.placeholder}}
        dateFormat="YYYY-MM-DD"
        timeFormat={properties.type === "datetime" ? "HH:mm" : false}
        closeOnSelect={true}
      />
    )
  }
}
