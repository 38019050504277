import React from 'react';
import $ from "jquery";
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from  '@material-ui/icons/Visibility';
import VisibilityOffIcon from  '@material-ui/icons/VisibilityOff';

import { sendRequest } from '../../helpers/global.js';

export default class TextInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      object: this.props.object,
      properties: this.props.properties,
      showPassword: false,
    };
    this.input = null;
  }

  handleChange = (e) => {
    let object = e.target.value;
    this.setState({object: object});
    this.props.onChange(this.props.objectKey, object);
  }

  componentDidMount = () => {
    this.getOptions();
  }

  getOptions = (input) => {
    var properties = this.state.properties;
    let displayKey = properties.displayField || "name";
    var request = properties.request;
    // Load select options and autocomplete if edit is enabled 
    if (!request || properties.hostProperty) {
      return;
    }
    if (request.match(/:id/)) {
      if (this.props.parentId) {
        request = request.replace(":id", this.props.parentId);
      } else {
        return;
      }
    }
    var data = {};
    if (properties.minSearch) {
      if (!input || input.length < properties.minSearch) {
        if (this.state.options && this.state.options.length > 0) {
          this.setState({options: []});
        }
        return;
      }
      data[displayKey] = input;
    }
    data.name = this.props.objectKey;
        
    sendRequest({
      method: request,
      type: "GET",
      data: data,
      success: (data) => {
        if (this.input) {
          let input = $(this.input);
          input.typeahead({source:data});
          input.on("change", this.handleChange);
        }
      },
      error: (xhr, status, err) => {
      }
    });
  }

  componentWillReceiveProps = (props) => {
    if (typeof props.object !== "undefined" && props.object !== null) {
      this.setState({object: props.object});
    }
  }

  render = () => {
    var properties = this.state.properties;

    if (properties.type === "text" || properties.type === "password") {
      let isPassword = properties.type === "password";
      return (
        <div style={{position: 'relative', paddingRight: isPassword ? 40 : 0}}>
          <input
            type={this.state.showPassword ? 'text' : properties.type}
            value={this.state.object}
            onChange={this.handleChange}
            readOnly={this.props.disabled}
            className="form-control"
            placeholder={this.props.placeholder || properties.placeholder}
            autoComplete={this.props.objectKey}
            ref={input => this.input = input}
          />
          {isPassword ? 
            <IconButton
              style={{position: 'absolute', right: 0, top: 0, padding: 5}}
              onClick={() => this.setState({showPassword: !this.state.showPassword})}
            >
              {this.state.showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
            </IconButton>
          : null}
        </div>
      )
    }

    if (properties.type === "textarea") {
      return (
        <textarea 
          value={this.state.object}
          onChange={this.handleChange}
          readOnly={this.props.disabled}
          className="form-control"
          placeholder={this.props.placeholder || properties.placeholder}
          ref={input => this.input = input}
        />
      ) 
    }
  }
}
