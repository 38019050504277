import { createStore, combineReducers } from 'redux'

var dataStateReducer = (state = {}, action) => {
  switch (action.type.toLowerCase()) {
    case "data":
      return Object.assign({}, state, {
        [action.key]: action.value
      })
    default:
      return state
  }
}

var configStateReducer = (state = {}, action) => {
  switch (action.type.toLowerCase()) {
    case "config":
      return Object.assign({}, state, {
        [action.key]: action.value
      })
    default:
      return state
  }
}

var setupStateReducer = (state = {}, action) => {
  switch (action.type.toLowerCase()) {
    case "setup":
      return Object.assign({}, state, {
        [action.key]: action.value
      })
    default:
      return state
  }
}

const stateReducer = combineReducers({
  data: dataStateReducer,
  config: configStateReducer,
  setup: setupStateReducer,
})

let storeInstance = null;

export default class Storage {

  static getStore() {
    if(!storeInstance){
      storeInstance = createStore(stateReducer);
    }
    return storeInstance;
  }

  //Data
  static setData(key, value) {
    if (value) {
      localStorage.setItem(`zaiko-admin:data:${key}`, JSON.stringify(value));
    } else {
      localStorage.removeItem(`zaiko-admin:data:${key}`);
    }
    this.getStore().dispatch({type: 'data', key, value});
  }

  static getData(key) {
    let result = this.getStore().getState()['data'][key];
    if (!result) {
      result = JSON.parse(localStorage.getItem(`zaiko-admin:data:${key}`));
    }
    return result;
  }
  
  // Config
  static setConfig(key, value) {
    this.getStore().dispatch({type: "config", key, value});
  }
  static getConfig(key) {
    return this.getStore().getState()["config"][key];
  }
  static getConfigKeys() {
    return Object.keys(this.getStore().getState()["config"]);
  }

  // Setup
  static setSetup(key, value) {
    this.getStore().dispatch({type: "setup", key, value});
  }
  static getSetup(key) {
    return this.getStore().getState()["setup"][key];
  }
  
}
