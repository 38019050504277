import React from 'react';
import ReactQuill, {Quill} from 'react-quill';
import reactHtmlId from 'react-html-id';

var Size = Quill.import('attributors/style/size');
Size.whitelist = ['10px', '11px', '12px', '13px', '14px', '16px', '18px', '20px', '24px'];
Quill.register(Size, true);

const colors = [
  "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff",
  //"#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb",
  //"#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888",
  //"#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444",
  //"#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", "#000000",
  "#0090DA", "#0061A0", "#A4CE4E", "#ffc600", "#db0a5b", 
];

const toolbarOptions = [
  //[{ 'font': [] }],
  [{ 'size': ['10px', '11px', '12px', '13px', '14px', '16px', '18px', '20px', '24px'] }],
  //[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['link', 'image', 'video'],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  //[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  //[{ 'direction': 'rtl' }],                         // text direction
  [{ 'color': colors }, { 'background': colors }],          // dropdown with defaults from theme
  //[{ 'align': [] }],
  //['clean']                                         // remove formatting button
];

export default class RichTextInput extends React.Component {

  constructor(props) {
    super(props);
    reactHtmlId.enableUniqueIds(this);
    this.state = {
      object: this.props.object,
      properties: this.props.properties,
    };
  }

  handleChange = (e) => {
    let object = e;
    this.setState({object: object});
    this.props.onChange(this.props.objectKey, object);
  }

  componentWillReceiveProps = (props) => {
    if (typeof props.object !== "undefined" && props.object !== null) {
      this.setState({object: props.object});
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
  }

  componentDidMount = () => {
    var toolbar = this.quill.getEditor().getModule('toolbar');
    toolbar.addHandler('image', () => {
      var value = prompt('Please enter global image URL:');
      if (value) {
        var range = this.quill.getEditor().getSelection();
        this.quill.getEditor().insertEmbed(range.index, 'image', value, Quill.sources.USER);
      }
    });
  }

  render = () => {
    return (
      <div 
        className={this.props.disabled ? "disabled" : ""}
        id={this.nextUniqueId()}
      >
        <ReactQuill
          ref={quill => this.quill = quill}
          value={this.state.object || "<p></p>"}
          onChange={value => this.props.onChange(this.props.objectKey, value)}
          modules={{toolbar: toolbarOptions}}
          theme='snow'
          bounds={'#'+this.lastUniqueId()}
          readOnly={this.props.disabled}
          placeholder={this.props.placeholder}
        />
      </div>
    )
  }
}
