import React from 'react';
import $ from "jquery";

export default class GraphTableView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  handleLineClick = (e, i) => {
    if (this.props.objectPageKey && this.props.data.ids[i]) {
      if (e.button === 0) {
        $(document).trigger("changePage", [this.props.objectPageKey, {id: this.props.data.ids[i]}]);
      } else if (e.button === 1) {
        $(document).trigger("openPage", [this.props.objectPageKey, {id: this.props.data.ids[i]}]);
      }
    }
  }

  render = () => {
    let columns = null;
    let rows = [];
    let dataset = this.props.data.datasets[0];
    if (dataset) {
      columns = dataset.columns.map((columnName, i) => {
        return (
          <th key={i}>{columnName}</th>
        );
      });
      if (dataset.showIds) {
        columns.unshift( <th key="id">#</th> );
      }
      this.props.data.labels.forEach((label, i) => {
        let id = null;
        if (this.props.objectPageKey && this.props.data.ids[i]) {
          id = this.props.data.ids[i];
          label = <span onMouseDown={e => this.handleLineClick(e,i)}>{label}</span>
        }
        dataset.data[i].forEach((subrow, j) => {
          let cells = [];
          if (dataset.showIds) {
            cells.push(<td key="id">{j === 0 ? id : null}</td>)  
          }
          cells.push(<td key={0}>{j === 0 ? label : null}</td>)
          subrow.forEach((cell, k) => {
            cells.push(<td key={cells.length}>{cell}</td>);
          })
          rows.push(<tr key={rows.length}>{cells}</tr>);
        });
      });
    }
    return (
      <div className="graphTableView">
        <h4>{this.props.options.title.text}</h4>
        <table className="table table-hover">
          <thead>
            <tr>{columns}</tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      </div>
    );
  }
}
