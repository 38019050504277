import React from 'react';
import $ from "jquery";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import { getUrlParameter } from '../helpers/global.js';
import Storage from '../helpers/Storage.js';
import NotificationsController from '../helpers/NotificationsController.js';

import LandingView from './LandingView.js';
import PrivacyView from './PrivacyView.js';
import SupportView from './SupportView.js';
import LoginView from './LoginView.js';
import HeaderView from './HeaderView.js';
import ObjectListView from './ObjectListView.js';
import ObjectEditView from './ObjectEditView.js';
import RequestView from './RequestView.js';
import GraphsView from './GraphsView.js';
import SimpleRequestsView from './SimpleRequestsView.js';
import ChecklistView from './ChecklistView.js';
import OrderView from './OrderView.js';
import SettingsListView from './SettingsListView.js';
import OrdersSchedulerView from './OrdersSchedulerView.js';

var MODES = ["none", "list", "view", "edit", "create", "simple-requests", "request", 
  "graphs", "settings", "object-sections", "checklist", "order", "orders-scheduler"];
var LIST_VIEWS = ["settings", "orders-scheduler"];
var EDIT_VIEWS = ["object-sections", "checklist", "order"];

export default class AdminPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      logged: localStorage.getItem("zaiko-admin:token") != null,
      page: null,
      mode: "none",
      submode: "view",
      menuItems: [],
      showAlert: false,
      alertMessage: '',
      showNotification: false,
      notification: null,
    };
  }
  
  getStateForLocation = (location) => {
    location = location || window.location.search;
    let id = parseInt(getUrlParameter("id", location), 10) || null;
    let isCreate = getUrlParameter("create", location);
    let page = window.location.pathname.split('/')[1];
    if (!Storage.getConfig(page)) {
      page = null;
      id = null;
    }
    let object = id ? {id: id} : {};
    let mode = this.modeForPage(page, object, isCreate ? "create" : null);
    let state = {
      page: page,
      mode: mode,
      target: object,
    };
    return state;
  }

  getMenuItems = () => {
    var items = [];
    var configKeys = Storage.getConfigKeys();
    let user = Storage.getData("user") || {};
    
    for (let i = 0; i < configKeys.length; i++) {
      let configObject = Storage.getConfig(configKeys[i]);
      if (!configObject.roles || configObject.roles.indexOf(user.role) > -1) {
        items.push(configKeys[i]);
      }
    }
    
    return items;
  }

  getDefaultPage = (role) => {
    return "orders";
  }

  onLogin = (data) => {
    localStorage.setItem("zaiko-admin:token", data.access_token);
    Storage.setData('user', data.user);
    this.setState({logged: true}, () => {
      this.getUsersRole();
    });
  }

  onLogout = () => {
    this.setState({logged: false});
    Storage.setData('user', null);
    localStorage.removeItem('zaiko-admin:token');
  }

  onUnauthorized = (data) => {
    if (data && data.type === 'trial') {
      this.setState({
        notification: {
          title: 'Trial expired',
          body: 'Your trial period has been expired. Please contact administrator.',
        },
        showNotification: true,
      });
    } else {
      this.setState({
        notification: {
          title: 'Token expired',
          body: 'Your access token has been expired. Please re-login.',
        },
        showNotification: true,
      });
    }
    this.onLogout();
  }

  modeForPage = (page, object, submode) => {
    if (!page) {
      return "none";
    }
    var mode = Storage.getConfig(page).config.type;
    if ((object && object.id) || submode === "create") {
      if ($.inArray(mode, EDIT_VIEWS) > -1) {
        return mode;
      }
      if (!mode || $.inArray(mode, LIST_VIEWS) > -1) {
        return "view";
      }
    } else {
      if ($.inArray(mode, LIST_VIEWS) > -1) {
        return mode;
      }
      if (!mode || $.inArray(mode, EDIT_VIEWS) > -1) {
        return "list";
      }
    }
    if (mode && $.inArray(mode, MODES) > -1) {
      return Storage.getConfig(page).config.type;
    }
    return "list";
  }

  getUsersRole = () => {
    let menuItems = this.getMenuItems();
    let state = this.getStateForLocation();
    state.menuItems = menuItems;
    if (!state.page || $.inArray(state.page, menuItems) < 0) {
      state.page = this.getDefaultPage();
      state.mode = this.modeForPage(state.page);
      // change url string
      window.history.replaceState({}, "", `/${state.page}`);
    }
    this.setState(state);
  }
  
  componentDidMount = () => {
    if (['/privacy', '/support'].indexOf(window.location.pathname) < 0) {
      if (this.state.logged) {
        this.getUsersRole();
      } else if (window.location.pathname !== '/login') {
        window.history.replaceState({}, "", '/');
      }
    }

    $(document).on("changePage", (e, page, object, submode) => {
      this.changePage(page, object, submode);
    });

    $(document).on("openPage", (e, page, object, submode) => {
      this.openPage(page, object, submode);
    });

    $(document).on("showAlert", (e, message) => {
      if (message) {
        this.setState({showAlert: true, alertMessage: message});
      }
    });

    $(document).on("unauthorized", (e, data) => {
      this.onUnauthorized(data);
    });

    window.onpopstate = (event) => {
      var state = this.getStateForLocation(document.location.search);
      if ($.inArray(state.page, this.state.menuItems) < 0) {
        state.page = this.state.menuItems[0];
        state.mode = this.modeForPage(state.page);
        // change url string
        window.history.replaceState({}, "", `/${state.page}`);
      }
      this.setState(state);
    };

    (()=>{
      var hidden = "hidden";

      function onchange (evt) {
        var v = "visible", h = "hidden",
          evtMap = {
            focus:v, focusin:v, pageshow:v, blur:h, focusout:h, pagehide:h
          };
        evt = evt || window.event;
        if (evt.type in evtMap) {
          //console.log(evtMap[evt.type]);
        } else {
          $(document).trigger(this[hidden] ? "pageHidden" : "pageVisible");
        }
      }

      if (hidden in document) {
        document.addEventListener("visibilitychange", onchange);
      } else if ((hidden = "mozHidden") in document) { // eslint-disable-line
        document.addEventListener("mozvisibilitychange", onchange);
      } else if ((hidden = "webkitHidden") in document) { // eslint-disable-line
        document.addEventListener("webkitvisibilitychange", onchange);
      } else if ((hidden = "msHidden") in document) { // eslint-disable-line
        document.addEventListener("msvisibilitychange", onchange);
      } else if ("onfocusin" in document) {
        document.onfocusin = document.onfocusout = onchange;
      } else {
        window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onchange;
      }
    
      if(document[hidden] !== undefined) {
        onchange({type: document[hidden] ? "blur" : "focus"});
      }
    })();

    Storage.setSetup('is_mobile', window.innerWidth <= 768);
    $(window).resize(() => {
      Storage.setSetup('is_mobile', window.innerWidth <= 768);
    });
    
  }

  changePage = (page, object, submode) => {
    let mode = this.modeForPage(page, object, submode);
    let params = {};
    if (object && object.id) {
      params.id = object.id;
    } else if (submode === "create") {
      params.create = true;
    }
    let url = `/${page}`;
    if (Object.keys(params).length > 0) {
      url += `?${$.param(params)}`;
    }
    window.history.pushState({}, "", url);
    this.setState({page: page, mode: mode, submode: submode, target: object});
  }

  openPage = (page, object, submode) => {
    let params = {};
    if (object && object.id) {
      params.id = object.id;
    } else if (submode === "create") {
      params.create = true;
    }
    let url = `/${page}`;
    if (Object.keys(params).length > 0) {
      url += `?${$.param(params)}`;
    }
    window.open(url, "_blank");
  }
  
  renderContent = () => {
    if (window.location.pathname === '/privacy') {
      return <PrivacyView/>
    } else if (window.location.pathname === '/support') {
      return <SupportView/>
    }
    if (!this.state.logged) {
      if (window.location.pathname === '/login') {
        return <LoginView onLogin={this.onLogin}/>
      } else {
        return <LandingView/>
      }
    } else {
      let pageView = null;
      if (this.state.mode === "graphs") {
        pageView = <GraphsView configKey={this.state.page} key={this.state.page}/>
      } else if (this.state.mode === "request") {
        pageView = <RequestView configKey={this.state.page} key={this.state.page}/>
      } else if (this.state.mode === "simple-requests") {
        pageView = <SimpleRequestsView configKey={this.state.page} key={this.state.page}/>
      } else if (this.state.mode === "settings") {
        pageView = <SettingsListView configKey={this.state.page} key={this.state.page}/>
      } else if (this.state.mode === "orders-scheduler") {
        pageView = <OrdersSchedulerView configKey={this.state.page} key={this.state.page}/>
      } else if (this.state.mode === "checklist") {
        pageView = <ChecklistView 
          configKey={this.state.page} 
          object={this.state.target}
          from={this.state.submode}
        />
      } else if (this.state.mode === "order") {
        pageView = <OrderView 
          configKey={this.state.page} 
          object={this.state.target}
          from={this.state.submode}
        />
      } else if (this.state.mode === "list") {
        pageView = <ObjectListView 
          configKey={this.state.page} 
          key={this.state.page}
          from={this.state.submode}
        />
      } else if (this.state.mode !== "none") {
        pageView = <ObjectEditView 
          configKey={this.state.page}
          edit={this.state.submode === "edit"}
          object={this.state.target}
        />
      }
      return (
        <div>
          <HeaderView
            logged={this.state.logged}
            onLogout={this.onLogout}
            page={this.state.page}
            items={this.state.menuItems}
          />
          <ReactCSSTransitionGroup
            transitionName="content"
            transitionEnterTimeout={400}
            transitionLeaveTimeout={400}
          >
            {pageView}
          </ReactCSSTransitionGroup>

          <NotificationsController/>
        </div>
      )
    }
  }

  render = () => {
    let notification = this.state.notification || {};
    return(
      <div>
        {this.renderContent()}

        <Dialog
          open={this.state.showNotification}
          onClose={() => this.setState({showNotification: false})}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {notification.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {notification.body}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({showNotification: false})} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={this.state.showAlert}
          message={this.state.alertMessage} 
          autoHideDuration={4000}
          onClose={() => {this.setState({showAlert: false})}}
          ContentProps={{
            style: {fontSize: '14px'}
          }}
        />
      </div>
    )
  }
}
