import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Provider } from 'react-redux';

import './config';

import Storage from './helpers/Storage.js';
import AdminPanel from './components/AdminPanel';

import './sass/base.scss';

require('./css/bootstrap.min.css');
require('./css/material-icons.css');
require('./css/font-awesome.min.css');
require('./css/react-select.css');
require('react-quill/dist/quill.snow.css');
require('react-image-crop/dist/ReactCrop.css');
require('video-react/dist/video-react.css');
require('react-datetime/css/react-datetime.css');

//console.log(process.env.NODE_ENV)
//'development' 'production'

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#57d599',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#827ce3',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontSize: 20,
    htmlfontSize: 26,
    h6: {
      fontSize: 20,
      fontWeight: 500,
    },
    title: {
      fontSize: 20,
      fontWeight: 500,
    },
    display1: {
      fontSize: 20,
    },
    body1: {
      fontSize: 20,
    },
  },
});

ReactDOM.render(
  <MuiThemeProvider theme={muiTheme}>
    <Provider store={Storage.getStore()}>
      <AdminPanel/>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('content')
);
