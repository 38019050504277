import React from 'react';
import $ from "jquery";
import moment from 'moment';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import AddIcon from  '@material-ui/icons/Add';
import DeleteIcon from  '@material-ui/icons/Delete';
import Paper from "@material-ui/core/Paper";
import IconButton from '@material-ui/core/IconButton';
import EditIcon from  '@material-ui/icons/Edit';
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  WeekView,
  MonthView,
  Toolbar,
  DateNavigator,
  Appointments,
  TodayButton,
  ViewSwitcher,
  AppointmentTooltip,
} from '@devexpress/dx-react-scheduler-material-ui';

import { sendRequest } from '../helpers/global.js';
import Storage from '../helpers/Storage.js';

import '../sass/OrderSchedulerView.scss';

const styles = {
  navButton: {
    fontSize: '16px',
    marginRight: 16,
  },
  addButton: {
    float: 'right',
    position: 'fixed',
    right: 35,
    bottom: 40
  },
  icon: {
    fontSize: '20px',
  },
  formControl: {
    width: 200,
    textAlign: 'left',
  },
  selectLabel: {
    fontSize: '18px',
  },
  selectInput: {
    fontSize: '20px',
  },
}

export default class OrdersSchedulerView extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getDefaultState(props);
  }
  
  getDefaultState = (props) => {
    let properties = Storage.getConfig(props.configKey).properties;
    let config = Storage.getConfig(props.configKey).config;
    let state = {
      properties: properties,
      config: config,
      type: 'week',
      date: new Date(),
      data: [],
      order: null,
      showDelete: false,
      confirmation: '',
      maxOrders: 0,
    };
    return state;
  }

  componentWillReceiveProps = (props) => {
    if (props.configKey !== this.props.configKey || 
      (props.object && (props.object.id !== this.state.object.id))
    ) {
      let state = this.getDefaultState(props);
      this.setState(state, () => {
        this.requestData();
      });
    }
  }

  componentDidMount = () => {
    this.requestData();
  }

  requestData = () => {
    const date = this.state.date;
    let startDate = null;
    let endDate = null;
    if (this.state.type === 'week') {
      startDate = moment(date).startOf('isoWeek');
      endDate = startDate.clone().add(1, 'week').subtract(1, 'day');
    } else {
      startDate = moment(date).startOf('month').startOf('isoWeek');
      endDate = startDate.clone().add(6, 'week').subtract(1, 'day');
    }

    sendRequest({
      method: this.state.config.method,
      type: "GET",
      data: {
        start_date: startDate.format('YYYY-MM-DD'),
        end_date: endDate.format('YYYY-MM-DD'),
      },
      success: (data) => {
        let dates = {};
        let maxOrders = 0;
        let orders = data.map(item => {
          if (!(item.start_date in dates)) {
            dates[item.start_date] = 0;
          }
          let index = dates[item.start_date]++;
          maxOrders = Math.max(maxOrders, index + 1);
          let time = moment(item.start_date).add(15 * index, 'minute');
          let endTime = time.clone().add(15, 'minute');
          return {
            id: item.id,
            identifier: item.identifier,
            title: item.name,
            startDate: time.valueOf(),
            endDate: endTime.valueOf(),
          }
        });
        this.setState({data: orders, maxOrders});
      },
      error: (xhr, status, err) => {
      }
    });
  }

  onObjectCreate = () => {
    $(document).trigger("changePage", [this.props.configKey, {id: false}, "create"]);
  }

  onObjectEdit = () => {
    $(document).trigger("changePage", [this.props.configKey, this.state.order, "edit"]);
  }

  onObjectDelete = () => {
    sendRequest({
      method: this.state.config.method + this.state.order.id,
      type: "DELETE",
      success: (data) => {
        this.setState({showDelete: false});
        this.requestData();
      },
    });
  }

  onDeleteCancel = () => {
    this.setState({showDelete: false});
  }

  onDateChange = (date) => {
    this.setState({date: date}, () => {
      this.requestData();
    });
  }

  onTypeChange = (type) => {
    this.setState({type: type}, () => {
      this.requestData();
    });
  }

  renderDeleteConfirmation = () => {
    const order = this.state.order || {};
    const confirmation = this.state.confirmation;
    const deleteId = order.identifier || order.serial_number || '';
    return (
      <Dialog open={this.state.showDelete} onClose={this.onDeleteCancel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">DELETE</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure want to delete 
              ${order.name || this.state.config.objectName} ${deleteId}
            ?`}
            <br/>
            Type DELETE to confirm
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Confirm"
            fullWidth
            autoComplete="off"
            value={confirmation}
            onChange={e => this.setState({confirmation: e.target.value})}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button 
            color="primary"
            onClick={this.onObjectDelete}
            disabled={(confirmation || '').toLowerCase() !== 'delete'}
          >Delete</Button>
        </DialogActions>
      </Dialog>
    )
  }

  commandButton = (props) => {
    return (
      <IconButton onClick={() => {
        props.onExecute();
        if (props.id === 'close') {
          this.setState({showDelete: true, confirmation: ''});
        } else {
          this.onObjectEdit();
        }
      }}>
        {props.id === 'close' ? <DeleteIcon/> : <EditIcon/>}
      </IconButton>
    )
  }

  tooltipContent = ({formatDate, ...restProps}) => {
    const data = restProps.appointmentData;
    const format = (date, options) => {
      if (options.year) {
        return ''
      } else {
        if (data.startDate === date) {
          return moment(date).format('D MMM YYYY')
        } else {
          return moment(date).add(1, 'day').format('D MMM YYYY')
        }
      }
    }
    return (
      <AppointmentTooltip.Content
        formatDate={format}
        {...restProps}
      />
    )
  }

  appointmentContent = ({formatDate, ...restProps}) => {
    return (
      <Appointments.AppointmentContent
        formatDate={() => ''}
        {...restProps}
      />
    )
  }

  render = () => {
    const data = this.state.data
    return (
      <div className="adminContent customScrollBar orderSchedulerView">

        <Paper elevation={2} className={`schedulerContainer ${this.state.type}`}>

          <Scheduler data={data} firstDayOfWeek={1}>
            <ViewState
              currentDate={this.state.date}
              onCurrentDateChange={this.onDateChange}
              currentViewName={this.state.type}
              onCurrentViewNameChange={this.onTypeChange}
            />
            <WeekView
              name='week'
              startDayHour={0}
              endDayHour={Math.ceil((this.state.maxOrders + 1) / 4)}
              cellDuration={30}
              timeScaleLayoutComponent={props => <span/>}
            />
            <MonthView/>

            <Toolbar/>
            <DateNavigator/>
            <TodayButton/>
            <ViewSwitcher/>

            <Appointments
              appointmentContentComponent={this.appointmentContent}
            />

            <AppointmentTooltip
              showCloseButton
              showOpenButton
              onAppointmentMetaChange={data => this.setState({order: data.data})}
              commandButtonComponent={this.commandButton}
              contentComponent={this.tooltipContent}
            />

          </Scheduler>

        </Paper>

        <Fab
          color='primary'
          onClick={this.onObjectCreate}
          style={styles.addButton}
        >
          <AddIcon style={styles.icon}/>
        </Fab>

        {this.renderDeleteConfirmation()}

      </div>
    )
  }

}
