import React from 'react';
import ReactCrop from 'react-image-crop';
import {Modal, Button as BTButton} from 'react-bootstrap';
import update from 'immutability-helper';

import { getImageWithCrop, getImageWithMaxWidth } from '../../helpers/global.js';
import Button from '@material-ui/core/Button';

export default class ImageInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      object: this.props.object,
      properties: this.props.properties,
      initTime: Date.now(),
      cropInfo: " ",
      showCropper: false,
      editCrop: {},
    };
  }

  handleChange = (e) => {
    let object = this.state.object;
    var properties = this.state.properties;
    var file = e.target.files[0];
    if (file && file.type.match(/image\/*/)) {
      var reader = new FileReader();
      reader.onload = (e) => {
        getImageWithMaxWidth(e.target.result, 960, (imageBase64) => {
          if (properties.type === "image") {
              object = imageBase64;
          } else {
            object = {
              original_data: imageBase64, 
              data: imageBase64, 
              crop: {x: 0, y: 0, width: 100, height: 100}, 
              type: file.type
            };
          }
          this.setState({object: object, showCropper: properties.type === "image-crop"});
          this.props.onChange(this.props.objectKey, object);
        });
      };
      reader.readAsDataURL(file);
    }
  }

  componentWillReceiveProps = (props) => {
    if (typeof props.object !== "undefined" && props.object !== null) {
      this.setState({object: props.object});
    }
  }

  handleRemoveClick = () => {
    this.fileInput.value = '';
    this.setState({object: ""});
    this.props.onChange(this.props.objectKey, "");
  }

  onModalClose = () => {
    this.setState({showCropper: false, editCrop: {}});
  }

  onCroperSave = () => {
    let object = this.state.object;
    let source = object.original_url || object.original_data || object.data || object.url;
    
    getImageWithCrop(source, this.state.editCrop.pixelCrop || null, (imageData) => {
      let object = update(this.state.object, {
        data: {$set: imageData},
        crop: {$set: this.state.editCrop.crop || {x: 0, y: 0, width: 100, height: 100}},
      })
      this.props.onChange(this.props.objectKey, object);
      this.setState({object, editCrop: {}, showCropper: false});
    });
  }

  renderCropperModal = () => {
    let object = this.state.object;
    let source = object.original_url || object.original_data || object.data || object.url;

    var crop = null;
    if (!this.props.disabled) {
      crop = this.state.editCrop.crop 
        ||this.state.object.crop 
        || {x: 0, y: 0, width: 100, height: 100};
    }
    
    return (
      <Modal show={this.state.showCropper} onHide={this.onModalClose} className="imageModal">
        <Modal.Header closeButton>
          <Modal.Title>{this.state.properties.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactCrop 
            src={source}
            crossorigin={"Anonymous"}
            keepSelection={true}
            disabled={this.props.disabled}
            crop={crop}
            ref={cropper => this.cropper = cropper}
            onChange={(crop, pixelCrop) => {
              let img = this.cropper.componentRef.querySelector("img");
              let k = img.naturalWidth / img.width;
              this.setState({cropInfo: `${Math.round(pixelCrop.width * k)} x ${Math.round(pixelCrop.height * k)}`});
            }}
            onComplete={(crop, pixelCrop) => {
              let img = this.cropper.componentRef.querySelector("img");
              let k = img.naturalWidth / img.width;
              this.setState(update(this.state, {
                editCrop: {
                  crop: {$set: crop},
                  pixelCrop: {$set: {
                    x: Math.round(pixelCrop.x * k),
                    y: Math.round(pixelCrop.y * k),
                    width: Math.round(pixelCrop.width * k),
                    height: Math.round(pixelCrop.height * k),
                  }},
                }
              }));
            }}
          >
          </ReactCrop>
          <h4>{this.state.cropInfo}</h4>
        </Modal.Body>
        <Modal.Footer>
          <BTButton onClick={this.onCroperSave}>Save</BTButton>
        </Modal.Footer>
      </Modal>
    )
  }

  render = () => {
    let object = this.state.object;
    let imageUrl = object.data || object.url || object.original_url || object || null;
    if (this.state.properties.type === "image-crop") {
      if (imageUrl === object.url || imageUrl === object.original_url || imageUrl === object) {
        imageUrl += "?t=" + this.state.initTime;
      }
    }

    var cropper = null;
    var editButton = null;
    var removeButton = null;
    var uploadButton = null;

    if (!this.props.disabled) {
      if (imageUrl && imageUrl.length !== 0) {
        if (this.state.properties.type === "image-crop") {
          cropper = this.renderCropperModal(imageUrl);
          editButton = <i
            className="material-icons editImage"
            onClick={() => {this.setState({showCropper: true})}}
          >edit</i>
        }
        removeButton = <i
          className="material-icons removeImage"
          onClick={this.handleRemoveClick}
        >close</i>
      }
      uploadButton = <div>
        <Button
          variant={imageUrl && imageUrl.length !== 0 ? 'text' : 'contained'}
          onClick={() => {this.fileInput.click()}}
        >Upload New Photo</Button>
      </div>
    }
    
    if (!imageUrl || imageUrl.length === 0) {
      editButton = null;
      removeButton = null;
    }

    return (
      <div className="edit-image">
        {imageUrl ? <img src={imageUrl} alt=""/> : <span>No Image</span>}
        <input
          type="file"
          accept="image/*"
          onChange={this.handleChange}
          disabled={this.props.disabled}
          ref={(input) => {this.fileInput = input;}}
        />
        {editButton}
        {removeButton}
        {uploadButton}
        {cropper}
      </div>
    )

  }
}
