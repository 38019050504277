import Storage from '../helpers/Storage.js';

Storage.setConfig("orders", {
  rootSection: true,
  config: {
    type: "order",
    method: "orders/",
    title: "Work Orders",
    objectName: "Work Order",
    mandatoryFields: ['technician_id'],
    copyAction: {
      type: 'POST',
      request: 'orders/:id/duplicate',
    },
    singleReport: {
      summary: {
        button: "Service Report",
        type: "link",
        linkField: "summary_report_url",
        showCondition: object => !!object.summary_report_url,
      },
    },
    search: {
      name: {
        title: "Name",
        type: "text",
      },
      status: {
        title: "Status",
        type: "select",
        request: "orders/statuses"
      },
      technician_id: {
        title: "Technician",
        type: "select",
        displayField: "username",
        request: "users/technicians"
      },
      customer_id: {
        title: "Customer",
        type: "select",
        request: "customers/all"
      },
      finished_at_min: {
        title: "Service Date Start",
        type: "date"
      },
      finished_at_max: {
        title: "Service Date End",
        type: "date"
      },
      scheduled_at: {
        title: "Scheduled at",
        type: "date",
      },
    }
  },
  properties: {
    id: {
      default: false,
      type: "none",
      title: "#",
      preset: true,
    },
    disable_edit: {
      default: false,
      type: "none",
      preset: true,
    },
    identifier: {
      default: false,
      type: "text",
      title: "Identifier",
      preset: true,
      width: "180px"
    },
    created_at: {
      default: "",
      type: "text",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "text",
      title: "Modified Time",
      preset: true,
    },
    started_at: {
      default: "",
      type: "text",
      title: "Start time",
      preset: true,
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
      placeholder: "Overwrite autogenerated Name (e.g. Pref1234Suff)",
      width: "auto"
    },
    scheduled_at: {
      default: "",
      type: "date",
      title: "Date",
      width: "100px",
      sort: true,
      modifyCondition: object => !object.started_at,
    },
    remarks: {
      default: "",
      type: "textarea",
      title: "Special instructions",
      modifyCondition: object => !object.started_at,
    },
    customer_id: {
      default: null,
      type: "select",
      title: "Customer",
      placeholder: "Customer",
      objectPageKey: "customers",
      request: "customers/all",
      filterCondition: item => !!item.active,
      modifyCondition: object => !object.started_at,
    },
    status: {
      default: "",
      type: "text",
      title: "Status",
      preset: true,
      width: "100px"
    },
    technician_id: {
      default: null,
      type: "select",
      title: "Technician",
      placeholder: "Technician",
      objectPageKey: "users",
      request: "users/technicians",
      displayField: "username",
      width: "120px",
      modifyCondition: object => !object.started_at,
    },
    logs: {
      default: "",
      type: "textarea",
      title: "Logs",
      preset: true,
    },
    parts: {
      default: [],
      modifyCondition: object => !object.started_at,
    },
    repeat_interval: {
      default: null,
      type: "select",
      title: "Repeat interval",
      placeholder: "Repeat interval",
      request: "orders/repeat_intervals",
    },
    summary_report_url: {
      default: false,
      type: "none",
      preset: true,
    },
    
  }
});
