import React from 'react';
import $ from "jquery";
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';

import Storage from '../helpers/Storage.js';

const styles = {
  container: {
    //position: 'fixed',
    overflowY: 'auto',
    height: '100vh',
    width: 260,
    //zIndex: 1,
    background: '#18202c',
  },
  logo: {
    backgroundImage: 'url(../img/logo.png)',
    width: '100%',
    height: 54,
    backgroundPosition: '20px center',
    backgroundSize: '110px',
    backgroundColor: '#232f3e',
    backgroundRepeat: 'no-repeat',
    boxShadow: '0 -1px 0 #404854 inset',
  },
  subheader: {
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: 500,
    paddingTop: 4,
    paddingBottom: 4,
    textAlign: 'left',
  },
  divider: {
    marginTop: 16,
    backgroundColor: '#404854',
  },
  itemText: {
    fontSize: 14,
    fontWeight: 500,
    color: 'inherit',
  },
};

const classStyles = theme => ({
  item: {
    paddingTop: 4,
    paddingBottom: 4,
    color: 'rgba(255, 255, 255, 0.7)',
    height: 30,
    lineHeight: '14px',
  },
  itemActionable: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
});

class SidebarView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openedSections: [],
    };
  }

  handleSectionClick = (e, page) => {
    $(document).trigger("changePage", [page]);
  }

  renderItem = (item) => {
    let classes = this.props.classes;
    
    let selected = this.props.page === item.key;
    return (
      <ListItem 
        key={item.key}
        onClick={(e) => {
          this.props.onItemClick(item);
          this.handleSectionClick(e, item.key);
        }}
        button
        className={classNames(
          classes.item,
          classes.itemActionable,
          selected && classes.itemActiveItem,
        )}
      >
        <ListItemText
          primary={item.config.title}
          primaryTypographyProps={{style: styles.itemText}}
        />
      </ListItem>
    )
  }

  renderHeader = (section) => {
    return (
      <ListSubheader
        key={`h-${section}`}
        style={styles.subheader}
      >{section}</ListSubheader>
    )
  }

  renderDivider = (section) => {
    return (
      <Divider
        key={`d-${section}`}
        style={styles.divider}
      />
    )
  }

  render = ()  => {
    let menuSections = {};
    for (let i = 0; i < this.props.items.length; i++) {
      let key = this.props.items[i];
      let item = Storage.getConfig(key);
      item.key = key;
      if (!item.section) {
        if (item.rootSection) {
          menuSections[key] = item;
        }
        continue;
      }
      if (!menuSections[item.section]) {
        menuSections[item.section] = {items: []};
      }
      menuSections[item.section].items.push(item);
    }

    let menuItems = [];
    Object.keys(menuSections).forEach(section => {
      if (menuSections[section].items) {
        menuItems.push(this.renderDivider(section))
        menuItems.push(this.renderHeader(section));
        menuSections[section].items.forEach((item) => {
          menuItems.push(this.renderItem(item))
        })
      } else {
        menuItems.push(this.renderItem(menuSections[section]));
      }
    });

    return (
      <div style={styles.container} className="customScrollBar">
        <div style={styles.logo}/>
        <List width={260}>
          {menuItems}
        </List>
      </div>       
    )
  }

}

export default withStyles(classStyles)(SidebarView);
