import React from 'react';
import {Modal, Button, Popover, OverlayTrigger} from 'react-bootstrap';

import {sendRequest} from '../helpers/global.js';
import Storage from '../helpers/Storage.js';

import SearchView from './SearchView.js';

export default class SimpleRequestsView extends React.Component {

  constructor(props) {
    super(props);
    var state = {
      options: Storage.getConfig(props.configKey).options,
      config: Storage.getConfig(props.configKey).config,
      responseContent: false,
      search: {},
    };
    // Default search interval
    if (state.config.search) {
      Object.keys(state.config.search).forEach((key) => {
        if (state.config.search[key].default) {
          state.search[key] = state.config.search[key].default;
        }
      });
    }
    this.state = state;
  }

  onRequest = (key) => {
    sendRequest({
      method: this.state.options[key].request,
      type: this.state.options[key].type,
      data: this.state.search,
      success: (data) => {
        this.setState({responseContent: data});
      },
      error: (xhr, status, err) => {
      }
    });
  }

  onModalClose = () => {
    this.setState({responseContent: false});
  }

  onSearchChange = (search) => {
    this.setState({search: search});
  }

  render = () => {
    // Search
    var searchView = null;
    if (typeof this.state.config.search !== "undefined") {
      searchView = 
      <SearchView
        config={this.state.config.search}
        onChange={this.onSearchChange}
        defaultValue={this.state.search}
      />;
    }

    var buttons = Object.keys(this.state.options).map((key) => {
      var option = this.state.options[key];
      const button = <button
        key={key}
        className="btn btn-default button-request" 
        onClick={() => this.onRequest(key)}
      >{option.title}</button>
      if (option.tooltip) {
        const tooltip = <Popover id={key} placement="top">{option.tooltip}</Popover>
        return (
          <OverlayTrigger key={key} placement="top" overlay={tooltip}>
            {button}
          </OverlayTrigger>
        )
      } else {
        return (
          button
        )
      }
    });
    return (
      <div className="adminContent customScrollBar">
        <h1 className="page-header">
          {this.state.config.title}
        </h1>
        {searchView}
        {buttons}

        <Modal show={!!this.state.responseContent} onHide={this.onModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Server response</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span className="errorMessage">{this.state.responseContent.error}</span>
            {this.state.responseContent.message}
            <br/>
            <a href={this.state.responseContent.link} target="_blank" rel="noopener noreferrer">{this.state.responseContent.link ? "Download link" : ""}</a>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onModalClose}>Close</Button>
          </Modal.Footer>
        </Modal>

      </div>
    )
  }
}
