import React from 'react';
import { sendRequest } from '../helpers/global.js';
import Snackbar from '@material-ui/core/Snackbar';
import ReactPlayer from 'react-player'
import Dialog from '@material-ui/core/Dialog';

import '../sass/LandingView.scss';

const VIDEO_URL = 'https://www.dropbox.com/sh/21y0ozbmpbkygdx/AAA6SPjBsuqWS-E5DN9LkLCla/Zaiko%20%28Without%20Archer%20Logo%29.mp4?dl=0';

export default class LandingView extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      alertMessage: '',
      showVideo: false,
    };
  }

  onSubmit = (e) => {
    let container = e.currentTarget.parentElement;
    let name = container.querySelector("input[data-key='name']");
    let email = container.querySelector("input[data-key='email']");
    if (name.value.length === 0) {
      this.setState({alertMessage: 'Please fill your name', showAlert: true});
      return;
    }
    if (email.value.length === 0) {
      this.setState({alertMessage: 'Please fill your email', showAlert: true});
      return;
    }
    sendRequest({
      method: 'users/landing',
      auth: true,
      type: 'POST',
      data: {
        name: name.value, 
        email: email.value,
      },
      success: (data) => {
        name.value = '';
        email.value = '';
        this.setState({alertMessage: 'Your request is processing. We will contact you back soon!', showAlert: true});
      },
      error: (xhr, status, err) => {
        if (xhr.responseJSON && xhr.responseJSON.error) {
          this.setState({alertMessage: xhr.responseJSON.error, showAlert: true});
        }
      }
    });
  }

  onAppStoreClick = () => {
    window.open('https://apps.apple.com/us/app/zaiko-building-maintenance/id1460252426', '_blank');
  }

  onGooglePlayClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.zaiko.givola', '_blank');
  }

  renderRegisterForm = () => {
    return (
      <div className="registerForm">
        <p>Interested? Enter your details and we will contact you shortly.</p>
        <input type="text" placeholder="Full name" data-key="name" autoComplete="name"/>
        <input type="text" placeholder="Email address" data-key="email" autoComplete="email"/>
        <input type="button" value="Submit" onClick={this.onSubmit}/>
      </div>
    )
  }

  renderVideoModal = () => {
    let width = Math.min(window.innerWidth, 1024);
    let height = Math.round(width / 16 * 9);
    return (
      <Dialog
        open={this.state.showVideo}
        onClose={() => this.setState({showVideo: false})}
        maxWidth={false}
        PaperProps={{
          style: {
            fontSize: '0px',
            borderRadius: '0px',
            marginLeft: 0,
            marginRight: 0,
          }
        }}
      >
        <ReactPlayer
          url={VIDEO_URL}
          playing
          controls
          width={width}
          height={height}
        />
      </Dialog>
    )
  }

  renderNavigation = () => {
    return (
      <div className='headerButtons'>
        <div className="headerButton" onClick={() => {
          window.open('https://www.givola.com/', '_blank')
        }}>Team</div>
        <div className="headerButton" onClick={() => {
          window.location.href = '/support';
        }}>Support</div>
        <div className="headerButton" onClick={() => {
          window.location.href = '/privacy';
        }}>Privacy</div>
        <div className="headerButton" onClick={() => {
          window.location.href = '/login';
        }}>Log In</div>
      </div>
    )
  }

  render = () => {
    return (
      <div className="landingView">

        <Snackbar
          open={this.state.showAlert}
          message={this.state.alertMessage} 
          autoHideDuration={4000}
          onClose={() => {this.setState({showAlert: false})}}
          ContentProps={{
            style: {fontSize: '14px'}
          }}
        />

        {this.renderNavigation()}

        <div className="topSection">
          <div className="logo"></div>
          <div className="givolaLogo" onClick={() => window.open('https://www.givola.com/', '_blank')}></div>
          <div className="introBlock">
            <div className="introText">
              <h1>Simplified building maintenance</h1>
              <p>Zaiko helps you track and manage your maintenance operations in a fuss-free way.</p>
            </div>
            <div className="introForm">
              {this.renderRegisterForm()}
              <div className='storeButtons'>
                <div className='appStore' onClick={this.onAppStoreClick}/>
                <div className='googlePlay' onClick={this.onGooglePlayClick}/>
              </div>
            </div>
          </div>
          <div className='videoButtonContainer'>
            <div className='videoButton' onClick={() => this.setState({showVideo: true})}>
              <label>See how Zaiko helps your business</label>
              <div className='playIcon'/>
            </div>
          </div>
        </div>

        <div className="contentRow">
          <div className="tileSection imageSection illustration1"></div>
          <div className="tileSection descriptionSection secondary">
            <div className="descriptionContent">
              <div className="descriptionIcon iconGraph"></div>
              <div className="descriptionHeader">
                Increased Revenue for your maintenance business
              </div>
              <div className="descriptionText">
                Let Zaiko organize your service records. Quick and accurate 
                reporting means that you can invoice clients fast and increase 
                sales from repair orders.
              </div>
            </div>
          </div>
        </div>

        <div className="contentRow">
          <div className="tileSection imageSection illustration2"></div>
          <div className="tileSection descriptionSection primary">
            <div className="descriptionContent">
              <div className="descriptionIcon iconChecklist"></div>
              <div className="descriptionHeader">
                Greater productivity for your technicians 
              </div>
              <div className="descriptionText">
                In-built checklists help your technicians get the job done faster 
                and with fewer errors.
              </div>
            </div>
          </div>
        </div>

        <div className="contentRow">
          <div className="tileSection imageSection illustration3"></div>
          <div className="tileSection descriptionSection">
            <div className="descriptionContent">
              <div className="descriptionIcon iconPhone"></div>
              <div className="descriptionHeader">
                Empower your technicians to document everything
              </div>
              <div className="descriptionText">
                Zaiko is designed to be mobile-first so that your technicians can 
                perform their work from any device. Zaiko works in offline mode 
                as well when there is no internet access.
              </div>
            </div>
          </div>
        </div>

        <div className="bottomSection">
          <div className="logoBlock">
            <div className="logo"></div>
            <div className="tagline">Maintenance made easy.</div>
          </div>
          <div className="introForm">
            {this.renderRegisterForm()}
          </div>
        </div>

        {this.renderVideoModal()}

      </div>
    )
  }

}
  