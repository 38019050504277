import React from 'react';
import $ from "jquery";
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from  '@material-ui/icons/Edit';
import CopyIcon from  '@material-ui/icons/FileCopy';
import DeleteIcon from  '@material-ui/icons/Delete';
import CheckIcon from  '@material-ui/icons/Check';
import CloseIcon from  '@material-ui/icons/Close';
import ActivateIcon from  '@material-ui/icons/Visibility';
import DeactivateIcon from  '@material-ui/icons/VisibilityOff';
import Checkbox from '@material-ui/core/Checkbox';

import Storage from '../helpers/Storage.js';
import UserAvatarView from './UserAvatarView.js';

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class ObjectListElem extends React.Component {

  constructor(props) {
    super(props);
    var properties = Storage.getConfig(props.configKey).properties;
    var config = Storage.getConfig(props.configKey).config;
    var keys = Object.keys(properties);
    var object = {};
    for (let i = 0; i < keys.length; i++) {
      var key = keys[i];
      if ((typeof props[key] !== "undefined") && (props[key] !== null)) {
        object[key] = props[key];
      } else {
        if (Array.isArray(properties[key].default)) {
          object[key] = properties[key].default.slice();
        } else if (typeof properties[key].default === "object") {
          object[key] = Object.assign({}, properties[key].default);
        } else {
          object[key] = properties[key].default;
        }
      }
    }
    var state = {
      isMobile: !!Storage.getSetup('is_mobile'),
      object: object, 
      properties: properties,
      config: config,
      inSelection: props.inSelection
    };
    this.state = state;
  }

  componentWillReceiveProps = (props) => {
    let stateChange = {};
    let mapping = mapStoreToProps({data: {}, setup: {}});
    Object.keys(mapping).forEach((key) => {
      if (typeof props[key] !== 'undefined' && props[key] !== null) {
        stateChange[key] = props[key];
      }
    });
    this.setState(stateChange);

    let object = Object.assign({}, this.state.object);
    Object.keys(this.state.properties).forEach(key => {
      if ((typeof props[key] !== "undefined") && (props[key] !== null)) {
        object[key] = props[key];
      } else {
        object[key] = null;
      }
    });
    stateChange.object = object;
    stateChange.inSelection = props.inSelection;

    this.setState(stateChange);
  }

  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if ($(e.target).is('span')) {
      return;
    }
    if (this.state.config.type === "reports") {
      if (this.state.object[this.state.config.url_field]) {
        window.open(this.state.object[this.state.config.url_field], "_blank");
      }
    } else {
      this.props.onView(this.state.object, e);
    }
  }

  handleCopy = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onCopy(this.state.object, e);
  }

  handleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onEdit(this.state.object, e);
  }

  handleDestroy = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onDelete(this.state.object);
  }

  handleActiveSwitch = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onActiveSwtich(this.state.object);
  }

  onCheckboxClick = (e) => {
    e.stopPropagation();
    this.props.onSelect(this.state.object.id, !this.state.inSelection);
  }

  renderActiveIcon = () => {
    return <CheckIcon style={{margin: '-5px 0px', color: '#2E7D32'}}/>;
  }

  renderInactiveIcon = () => {
    return <CloseIcon style={{margin: '-5px 0px', color: '#cccccc'}}/>;
  }

  renderCellContent = (key) => {
    let properties = this.state.properties[key];
    if (!properties.width) {
      return null;
    }
    if (properties.listCondition && !properties.listCondition(this.state.object)) {
      return null;
    }
    let content = this.state.object[key];
    if (properties.type === "avatar") {
      content = <UserAvatarView user={this.state.object} size={30} style={{margin: '-5px 0px'}}/>
    }
    if (properties.type === "select") {
      if (typeof content === 'boolean') {
        content = content ? this.renderActiveIcon() : this.renderInactiveIcon();
      } else {
        let item = (this.props.options[key] || []).find(option => option.id === this.state.object[key]);
        if (item) {
          let displayKey = properties.displayField || "name";
          content = item[displayKey];
        } else {
          content = "";
        }
      }
    }
    if (properties.type === "address") {
      content =  
      (content["locality"] ? content["locality"] : "") + ' ' +
      (content["street"] ? content["street"] : "") + ' ' +
      (content["other"] ? content["other"] : "");
    }
    if (properties.type === "checkbox") {
      content = content ? this.renderActiveIcon() : this.renderInactiveIcon();
    }
    if (properties.type === "multi") {
      let displayKey = properties.displayField || "name";
      var labels = content.map((elem) => {
        return elem[displayKey];
      });
      content = labels.join(", ");
    }
    return content || ' ';
  }

  render = () => {
    let config = this.state.config;

    let selectInput = null;
    if (this.props.needSelect) {
      selectInput = <Checkbox 
        color='primary'
        checked={this.state.inSelection}
        style={{padding: 0}}
      />
    }

    let editButton = <IconButton
      onClick={this.handleEdit}
      disabled={this.state.object.deleted}
      style={{padding: 0, width: 40, height: 40}}
    >
      <EditIcon/>
    </IconButton>;
    let deleteButton = <IconButton
      onClick={config.deactivationDelete ? this.handleActiveSwitch : this.handleDestroy}
      disabled={this.state.object.deleted}
      style={{padding: 0, width: 40, height: 40}}
    >
      {config.deactivationDelete ? 
        this.state.object.active ? <DeactivateIcon/> : <ActivateIcon/>
      : <DeleteIcon/>}
    </IconButton>;

    if (this.state.object.disable_edit) {
      editButton = <div/>;
      deleteButton = <div/>;
    }
    if (config.deleteRoles && config.deleteRoles.indexOf(Storage.getData('user').role) < 0) {
      deleteButton = null;
    }
    if (config.modifyCondition && !config.modifyCondition(this.state.object)) {
      editButton = null;
      deleteButton = null;
    }
    if (this.props.preset) {
      editButton = null;
      deleteButton = null;
    }

    let copyButton = null;
    if (config.copyAction) {
      copyButton = <IconButton
        onClick={this.handleCopy}
        style={{padding: 0, width: 40, height: 40}}
      >
        <CopyIcon/>
      </IconButton>;
    }

    if (this.state.isMobile) {
      return <table className='table'>
        <tbody>
          {Object.keys(this.state.properties).map(key => {
            let content = this.renderCellContent(key);
            if (content === null || content === undefined) {
              return null;
            } else {
              return <tr key={key}>
                <td width='125px'>{this.state.properties[key].title}</td>
                <td width='auto'>{content}</td>
              </tr>
            }
          })}
          <tr>
            <td width='125px'>Actions</td>
            <td width='auto' style={{padding: 0}}>
              {selectInput ? <div
                style={{padding: 10, display: 'inline-block'}}
                onClick={this.onCheckboxClick}
              >{selectInput}</div> : null}
              {copyButton}
              {editButton}
              {deleteButton}
            </td>
          </tr>
        </tbody>
      </table>
    } else {
      return (
        <tr onClick={this.handleClick}>
          {selectInput ? <td onClick={this.onCheckboxClick}>{selectInput}</td> : null}
          {Object.keys(this.state.properties).map(key => {
            let content = this.renderCellContent(key);
            if (content === null || content === undefined) {
              return null;
            } else {
              if ((this.state.config.mandatoryFields || []).indexOf(key) > -1 && !this.state.object[key]) {
                return <td key={key} className='highlight'>{content}</td>;
              } else {
                return <td key={key}>{content}</td>;
              }
            }
          })}
          {copyButton ? <td style={{padding: 0}}>{copyButton}</td> : null}
          {editButton ? <td style={{padding: 0}}>{editButton}</td> : null}
          {deleteButton ? <td style={{padding: 0}}>{deleteButton}</td> : null}
        </tr>
      );
    }
  }
}

export default connect(mapStoreToProps)(ObjectListElem)
