import React from 'react';
import ActionCable from 'actioncable';

import {WSUrl} from '../helpers/global.js';
import Storage from '../helpers/Storage.js';
import { connect } from 'react-redux';

const mapStoreToProps = (store) => {
  return {
    user: store.data.user,
  }
};

class NotificationsController extends React.Component {

  constructor(props) {
    super(props);
    let user = Storage.getData('user') || {};
    this.state = {
      user,
    };
    this.cable = null;
    if (user.id) {
      this.connectChannel(user);
    }
  }

  componentWillUnmount = () => {
  }

  componentWillReceiveProps = (props) => {
    let stateChange = {};
    let mapping = mapStoreToProps({data: {}, config: {}});
    Object.keys(mapping).forEach((key) => {
      if (typeof props[key] !== 'undefined') {
        stateChange[key] = props[key];
      }
    });

    let userId = (stateChange.user || {}).id;
    let curUserId = (this.state.user || {}).id;
    if (userId !== curUserId) {
      if (userId) {
        this.connectChannel(stateChange.user);
      } else {
        this.disconnectChannel();
      }
    }

    this.setState(stateChange);
  }

  disconnectChannel = () => {
    if (this.cable) {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = null;
      }
      this.cable.disconnect();
      this.cable = null;
    }
  }

  connectChannel = (user) => {
    this.disconnectChannel();
    if (user && user.id) {
      this.cable = ActionCable.createConsumer(WSUrl());
      this.subscription = this.cable.subscriptions.create({
        channel: 'NotificationsChannel',
        token: localStorage.getItem("zaiko-admin:token"),
      }, {
        connected: () => {
          console.log(`Connected to notifications channel as ${user.role} #${user.id}`);
        },
        disconnected: () => {},
        received: (data) => {
          let notifications = Object.assign({}, Storage.getData('notifications'));
          notifications.notifications.unshift(data);
          notifications.unread_count++;
          Storage.setData('notifications', notifications);
        },
      });
    }
  }

  render = () => {
    return null;
  }

}

export default connect(mapStoreToProps)(NotificationsController)
