import React from 'react';
import $ from "jquery";
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {Modal, Button as BTButton} from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import SaveIcon from  '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import { sendRequest, apiUrl } from '../helpers/global.js';
import Storage from '../helpers/Storage.js';

import FlexibleInput from './FlexibleInput.js';

import '../sass/wizard.scss';

const ExpansionPanelSummaryVertical = withStyles({
  content: {
    flexDirection: 'column'
  },
})(props => <ExpansionPanelSummary {...props} />);
ExpansionPanelSummaryVertical.muiName = 'ExpansionPanelSummary';

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class ChecklistView extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getDefaultState(props);
  }
  
  getDefaultState = (props) => {
    var properties = Object.assign({}, Storage.getConfig(props.configKey).properties);
    var config = Storage.getConfig(props.configKey).config;
    var keys = Object.keys(properties);
    var object = {};
    for (let i = 0; i < keys.length; i++) {
      var key = keys[i];
      if (props.object && props.object[key]) {
        object[key] = props.object[key];
      } else {
        if (Array.isArray(properties[key].default)) {
          object[key] = properties[key].default.slice();
        } else if (typeof properties[key].default === "object") {
          object[key] = Object.assign({}, properties[key].default);
        } else {
          object[key] = properties[key].default;
        }
      }
    }
    var state = {
      isMobile: !!Storage.getSetup('is_mobile'),
      object: object, 
      properties: properties, 
      config: config,
      responseContent: false,
      current_report: null,
      errors: {},
      activeQuestion: null,
      lastActiveQuestion: null,
      questionTypes: [],
    }
    return state;
  }

  componentDidMount = () => {
    sendRequest({
      method: "checklists/question_types",
      type: "GET",
      success: (data) => {
        this.setState({questionTypes: data});
      },
      error: (xhr, status, err) => {
      }
    });
    this.requestInitialData();
  }

  componentWillReceiveProps = (props) => {
    if (props.configKey) {
      if (props.configKey !== this.props.configKey || props.object.id !== this.props.object.id) {
        var state = this.getDefaultState(props);
        this.setState(state);
        this.requestInitialData(state);
        return;
      }
    }
    let stateChange = {};
    let mapping = mapStoreToProps({data: {}, setup: {}});
    Object.keys(mapping).forEach((key) => {
      if (typeof props[key] !== 'undefined' && props[key] !== null) {
        stateChange[key] = props[key];
      }
    });
    this.setState(stateChange);
  }

  updateStateWithData = (data) => {
    var keys = Object.keys(this.state.properties);
    var object = this.state.object;
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (typeof data[key] !== "undefined" && data[key] !== null) {
        object[key] = data[key];
      }
    }
    this.setState({object});
  }

  requestInitialData = (state) => {
    state = state || this.state;

    if (state.object.id) {
      sendRequest({
        method: state.config.method + state.object.id,
        type: "GET",
        success: (data) => {
          if (data.id) {
            this.updateStateWithData(data);
          } else if (data.redirect) {
            $(document).trigger("changePage", [data.redirect, {id: state.object.id}]);
          } else if (data.error) {
            this.handleFinishEdit();
          }
        },
        error: (xhr, status, err) => {
        }
      });
    }
  }

  submitData = (e) => {
    e.preventDefault();
    var sendData = this.state.object;
    sendRequest({
      method: this.state.config.method + (this.state.object.id ? this.state.object.id : ""),
      type: this.state.object.id ? "PUT" : "POST",
      data: sendData,
      success: (data) => {
        if (data.id) {
          if (this.state.object.id) {
            this.updateStateWithData(data);
            $(document).trigger("showAlert", [`${this.state.config.objectName} saved`]);
          } else {
            $(document).trigger("showAlert", [`${this.state.config.objectName} created`]);
            this.handleFinishEdit();
          }
        } else if (data.errors) {
          this.setState({errors: data.errors});
        } else {
          this.setState({responseContent: data});
        }
      },
      error: (xhr, status, err) => {
        if (xhr.responseJSON) {
          if (xhr.responseJSON.errors) {
            this.setState({errors: xhr.responseJSON.errors});
          } else {
            this.setState({responseContent: xhr.responseJSON});
          }
        }
      }
    });
  }

  handleFinishEdit = () => {
    $(document).trigger("changePage", [this.props.configKey]);
  }

  onModalClose = () => {
    if (this.state.responseContent.redirectBack) {
      this.handleFinishEdit();
    } else {
      this.setState({responseContent: false});
    }
  }

  onReportCancel = (e) => {
    this.setState({current_report: null});
  }

  onReportConfirm = () => {
    var report = this.state.config.singleReport[this.state.current_report.key];
    var data = {id: this.state.object.id};
    if (report.report_type === "create") {
      data[report.nameParameter] = $('input[data-id="report_create"]').val();
    }
    sendRequest({
      method: report.request,
      type: report.type,
      data: data,
      success: (data) => {
        this.setState({responseContent: data});
      }
    });
  }

  setActiveQuestion = (questionId) => {
    this.setState({
      activeQuestion: questionId,
      lastActiveQuestion: this.state.activeQuestion
    });
    setTimeout(() => {
      this.setState({lastActiveQuestion: null});
    }, 500);
  }

  onReportClick = (key) => {
    var report = this.state.config.singleReport[key];
    if (report.report_type === "link") {
      let link = this.state.object[report.link_field];
      window.open(link, "_blank");
      return;
    }
    if (report.report_type === "share") {
      let link = this.state.object[report.link_field];
      this.setState({responseContent: {copyable: link}});
      return;
    }
    if (report.report_type === "create") {
      this.setState({current_report: {key: key}});
      return;
    }
    if (report.report_type === "upload") {
      this.setState({current_report: {key: key}});
      return;
    }
    sendRequest({
      method: report.request,
      type: report.type,
      data: {id: this.state.object.id},
      success: (data) => {
        this.setState({responseContent: data});
      }
    });
  }

  renderResponse = () => {
    var responseBody = [];
    if (this.state.responseContent) {
      let content = this.state.responseContent;
      if ($.isArray(content.error)) {
        responseBody.push( content.error.map((err, i) => {
          return <span key={"error" + i} className="errorMessage">{err}</span>;
        }) );
      } else if (typeof content.error === "string") {
        responseBody.push( <span key="error" className="errorMessage">{content.error}</span> );
      }
      if (content.message) {
        if (responseBody.length > 0) {
          responseBody.push( <br key={"br" + responseBody.length}/> );
        }
        responseBody.push( content.message );
      }
      if (content.link) {
        if (responseBody.length > 0) {
          responseBody.push( <br key={"br" + responseBody.length}/> );
        }
        if (typeof content.link === "string") {
          responseBody.push( <a key="link" href={content.link} target="_blank" rel="noopener noreferrer">Download link</a> );
        } else {
          responseBody.push( <a key="link" href={content.link.href} target="_blank" rel="noopener noreferrer">{content.link.label}</a> );
        }
      }
      if (content.copyable) {
        if (responseBody.length > 0) {
          responseBody.push( <br key={"br" + responseBody.length}/> );
        }
        let copyToClipboard = (e) => {
          let input = $(e.currentTarget).parent().find("input");
          input.focus();
          input[0].setSelectionRange(0, input.val().length);
          try {
              console.log(document.execCommand("copy"));
          } catch(e) {}
        }
        responseBody.push( <div key="copyable" className="copyable">
          <input value={content.copyable} readOnly={true} className="form-control"/>
          <button className="btn btn-default" onClick={copyToClipboard}>
            <i className="fa fa-clipboard"></i>
          </button> 
        </div> );
      }
    }

    return (
      <Modal show={!!this.state.responseContent} onHide={this.onModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Server response</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {responseBody}
        </Modal.Body>
        <Modal.Footer>
          <BTButton onClick={this.onModalClose}>Close</BTButton>
        </Modal.Footer>
      </Modal>
    )
  }

  renderReport = () => {
    var reportTitle = null;
    var reportButton = null;
    var reportForm = null;
    if (this.state.current_report !== null) {
      var report = this.state.config.singleReport[this.state.current_report.key];
      reportTitle = report.button;
      reportButton = report.confirm;
      if (report.report_type === "create") {
        reportForm = <input
          key="report_create"
          data-id="report_create"
          placeholder={report.placeholder}
          className="form-control"
        />
      }
    }

    return (
      <Modal show={this.state.current_report !== null} onHide={this.onReportCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{reportTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {reportForm}
        </Modal.Body>
        <Modal.Footer>
          <BTButton onClick={this.onReportCancel}>Cancel</BTButton>
          <BTButton onClick={this.onReportConfirm}>{reportButton}</BTButton>
        </Modal.Footer>
      </Modal>
    )
  }

  renderInput = ({key, properties, value, onChange}) => {
    if (properties.type === "none") {
      return null;
    }

    let inputDisabled = properties.preset;
    if (properties.createOnly || this.state.object.disable_edit) {
      inputDisabled = true;
    }
    if (properties.modifyCondition && !properties.modifyCondition(this.state.object)) {
      inputDisabled = true;
    }

    let parentProperties = this.state.properties;
    if (!parentProperties[key]) {
      parentProperties = {[key]: properties};
    }

    let hostProp = properties.hostProperty;
    return (
      <div className="questionInput" key={key}>
        <FlexibleInput
          object={value}
          parentId={this.state.object.id}
          disabled={inputDisabled}
          objectKey={key}
          objectProperties={parentProperties}
          hostPropertyValue={hostProp ? this.state.object[hostProp] : null}
          placeholder={properties.title}
          onChange={onChange}
        />
        {this.state.errors[key] ? 
          <div className='inputError'>{this.state.errors[key]}</div>
        : null}
      </div>
    )
  }

  renderBasicInput = (key) => {
    return this.renderInput({
      key: key,
      properties: this.state.properties[key],
      value: this.state.object[key],
      onChange: (key, val) => {
        this.setState(update(this.state, {
          object: {
            [key]: {$set: val}
          },
          errors: {
            [key]: {$set: null}
          },
        }));
      }
    })
  }

  renderMainSection = () => {
    return (
      <ExpansionPanel key='main'>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          Title
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display: 'block'}}>
          {this.renderBasicInput("name")}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  renderVisibilitySection = () => {
    if ((Storage.getData("user") || {}).role !== 'admin') {
      return null;
    }
    return (
      <ExpansionPanel key='visibility'>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          Visibility
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display: 'block'}}>
          {this.renderBasicInput("organization_ids")}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  renderReferenceSection = () => {
    return (
      <ExpansionPanel key='reference'>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          Reference
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display: 'block'}}>
          {this.renderBasicInput("reference")}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  deleteQuestion = (question, index) => {
    this.setState(update(this.state, {
      object: {
        questions: {$splice: [[index, 1]]}
      }
    }));
  }

  onQuestionDrag = (result) => {
    if (!result.destination) {
      return;
    }
    this.moveQuestion(result.source.index, result.destination.index);
  }

  moveQuestion = (fromIndex, toIndex) => {
    let questions = this.state.object["questions"].slice();
    if (!(fromIndex in questions) || !(toIndex in questions)) {
      return;
    }
    let removed = questions.splice(fromIndex, 1)[0];
    questions.splice(toIndex, 0, removed);
    this.setState(update(this.state, {
      object: {
        questions: {$set: questions}
      }
    }));
  }

  renderAnswer = (answer, index, questionIndex, disabled) => {
    let deleteButton = disabled ? null
    : <div className="deleteAnswer" onClick={() => {
      this.setState(update(this.state, {
        object: {
          questions: { [questionIndex]: {
            answers: {$splice: [[index, 1]]}
          }}
        }
      }));
    }}>
      <i className="material-icons">close</i>
    </div>;
    return (
      <div className="answerRow" key={answer.id}>
        <div className="answerText">
          {this.renderInput({
            key: 'title',
            properties: {type: "text", title: `Answer ${index+1}`, preset: disabled},
            value: answer.title || "",
            onChange: (key, val) => {
              this.setState(update(this.state, {
                object: {
                  questions: { [questionIndex]: {
                    answers: { [index]: {
                      title: {$set: val}
                    }}
                  }}
                }
              }));
            },
          })}
        </div>
        {deleteButton}
      </div>
    )
  }

  renderQuestion = (question, index) => {
    let inputs = [];
    let questionActive = this.state.activeQuestion === question.id;
    let questionWasActive = this.state.lastActiveQuestion === question.id;
    const onChange = (key, val, index) => {
      this.setState(update(this.state, {
        object: {
          questions: { [index]: {
            [key]: {$set: val}
          }}
        }
      }));
    };
    if (questionActive || questionWasActive) {
      inputs = [
        this.renderInput({
          key: "title",
          properties: {type: "text", title: "Title"},
          value: question.title || "",
          onChange: (key, val) => onChange(key, val, index),
        }),
        this.renderInput({
          key: "description",
          properties: {type: "textarea", title: "Description"},
          value: question.description || "",
          onChange: (key, val) => onChange(key, val, index),
        }),
        this.renderInput({
          key: "question_type",
          properties: {type: "select", placeholder: "Question type", options: this.state.questionTypes},
          value: question.question_type || "",
          onChange: (key, val) => onChange(key, val, index),
        }),
        question.question_type === 'selects' ?
          <div className="answersList" key="answers">
            {(question.answers || []).map((answer, i) => this.renderAnswer(answer, i, index))}
            <Button
              variant='outlined'
              color='primary'
              onClick={() => {
                let id = `$${question.id}-${Date.now()}`;
                this.setState(update(this.state, {
                  object: {
                    questions: { [index]: {
                      answers: question.answers ? 
                        {$push: [{id}]} :
                        {$set: [{id}]}
                    }}
                  },
                }));
              }}
            >Add New Answer</Button>
          </div>
        : null,
      ]
    }

    let headerContent = <React.Fragment>
      <Typography style={{
        fontSize: '14px',
        textAlign: 'left',
        margin: '7px 0px',
        flexBasis: 'calc(100% - 125px)',
        color: question.active ? questionActive ? '#827ce3' : '#333333' : '#cccccc',
      }}>
        {question.title || "(Untitled)"}
      </Typography>
      <div>
        {this.state.object.disable_edit ? null : question.id.toString()[0] === '$' ?
          <Button
            variant='text'
            style={{padding: '6px 8px'}}
            onClick={(e) => {
              e.stopPropagation();
              this.deleteQuestion(question, index)
            }}
          >Delete</Button>
          :
          <Button
            variant='text'
            style={{padding: '6px 8px'}}
            onClick={(e) => {
              e.stopPropagation();
              onChange('active', !question.active, index)
            }}
          >{question.active ? 'Disable' : 'Enable'}</Button>
        }
      </div>
    </React.Fragment>
    
    return (
      <ExpansionPanel
        key={question.id}
        expanded={questionActive}
        onChange={() => this.setActiveQuestion(questionActive ? null : question.id)}
      >
        {this.state.isMobile ?
          <ExpansionPanelSummaryVertical expandIcon={<ExpandMoreIcon/>}>
            {headerContent}
          </ExpansionPanelSummaryVertical>
          :
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
            {headerContent}
          </ExpansionPanelSummary>
        }
        <ExpansionPanelDetails style={{display: 'block'}}>
          {inputs}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  renderQuestionsSection = () => {
    return (
      <ExpansionPanel key='questions'>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          Tasks
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display: 'block'}}>

          <DragDropContext onDragEnd={this.onQuestionDrag}>
            <Droppable droppableId='questions'>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {this.state.object["questions"].map((question, index) => 
                    <Draggable
                      index={index}
                      key={question.id}
                      draggableId={question.id}
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              outline: 'none',
                            }}
                          >
                            <div/> {/*added divs to fix material ui styling*/}
                            {this.renderQuestion(question, index)}
                            <div/>
                          </div>
                        )
                      }}
                    </Draggable>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {this.state.object.disable_edit ? null :
            <div style={{textAlign: 'right', marginTop: 22}}>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => {
                  let id = `$${Date.now()}`;
                  let answers = Array.from({length: 2}, (v, i) => {
                    return {id: `${id}-${i}`}
                  });
                  this.setState(update(this.state, {
                    object: {
                      questions: {$push: [{id, answers, active: true}]}
                    },
                  }));
                  this.setActiveQuestion(id);
                }}
              >Add Task</Button>
            </div>
          }

        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  renderExportSection = () => {
    if (!this.state.object.id) {
      return null;
    }
    return (
      <ExpansionPanel key='resources'>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          Exports
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display: 'block'}}>
          {Object.keys(this.state.config.singleReport).map(key => {
            let report = this.state.config.singleReport[key];
            return (
              <Button
                key={key}
                variant='contained'
                style={{margin: '0px 16px 16px 0px'}}
                onClick={(e) => {
                  let method = (report.request || "").replace(":id", this.state.object.id);
                  if (report.type === "get_report") {
                    let params = $.param({
                      access_token: localStorage.getItem("zaiko-admin:token"),
                    });
                    let url = apiUrl(method) + '?' + params;
                    window.open(url, "_blank");
                  } else {
                    sendRequest({
                      method,
                      type: 'GET',
                      data: {id: this.state.object.id},
                      success: (data) => {
                        this.setState({responseContent: data});
                      }
                    });
                  }
                }}
              >{report.button}</Button>
            )
          })}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  render = () => {
    var views = [
      this.renderMainSection(),
      this.renderVisibilitySection(),
      this.renderReferenceSection(),
      this.renderQuestionsSection(),
      this.renderExportSection(),
    ];
    if (this.state.object.id) {
      views.unshift(<label key="id">ID : <b>{this.state.object.id}</b></label>);
    }
    
    let saveButton = <Button
      variant='contained'
      color='primary'
      onClick={this.submitData}
    >
      <SaveIcon style={{marginRight: 8}}/>
      Save Changes
    </Button>

    if (this.state.config.preset || this.state.object.deleted || this.state.object.disable_edit) {
      saveButton = null;
    }
    if (this.state.config.modifyCondition && !this.state.config.modifyCondition(this.state.object)) {
      saveButton = null;
    }

    let viewType = !this.state.object.id ? "Create" : this.state.edit ? "Edit" : "View";
    return (
      <div className="adminContent wizardContent customScrollBar">

        <h1 className="page-header">
          <span className='backLink' onClick={this.handleFinishEdit}>
            <i className="fa fa-angle-left" aria-hidden="true"></i>
          </span>
          {this.state.config.objectName} {viewType}
        </h1>

        <form className="form-horizontal" onSubmit={(e) => e.preventDefault()}>
          {views}
          <div style={{
            textAlign: "right",
            marginTop: 35,
            paddingRight: this.state.isMobile ? 16 : 0,
          }}>
            {saveButton}
          </div>
        </form>

        {this.renderReport()}
        {this.renderResponse()}

      </div>
    );
  }
}

export default connect(mapStoreToProps)(ChecklistView)
