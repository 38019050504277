import React from 'react';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import Storage from '../../helpers/Storage.js';

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class ChangesInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isMobile: !!Storage.getSetup('is_mobile'),
      object: this.props.object,
      properties: this.props.properties,
      message: '',
    };
  }

  handleChange = (e) => {
    let object = e.target.value;
    this.setState({object: object});
    this.props.onChange(this.props.objectKey, object);
  }

  componentDidMount = () => {
  }

  componentWillReceiveProps = (props) => {
    let stateChange = {};
    let mapping = mapStoreToProps({data: {}, setup: {}});
    Object.keys(mapping).forEach((key) => {
      if (typeof props[key] !== 'undefined' && props[key] !== null) {
        stateChange[key] = props[key];
      }
    });
    if (props.object && props.object !== this.state.object) {
      stateChange.object = props.object;
    }
    this.setState(stateChange);
  }

  renderValue = (value) => {
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    return value || (this.state.isMobile ? '' : '-');
  }

  renderDesktop = () => {
    let object = this.state.object || [];
    return (
      <table className="table table-hover">
        <thead>
          <tr>
            <th width="155px">Field</th>
            <th width="200px">Changed from</th>
            <th width="auto">Changed to</th>
          </tr>
        </thead>
        <tbody>
          {object.map((item, index) =>
            <tr key={index}>
              <td>{item.field}</td>
              <td>{this.renderValue(item.from || '-')}</td>
              <td>{this.renderValue(item.to || '-')}</td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  renderMobile = () => {
    let object = this.state.object || [];
    return (
      <List style={{padding: '0px 0px 12px'}}>
        {object.map((item, index) => 
          <ListItem alignItems='flex-start' key={index}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography field={{display: 'inline-block', fontSize: 14,  fontWeight: 500}}>
                    {item.author}
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <Typography style={{fontSize: 16, paddingTop: 4}}>
                  {item.field}: {this.renderValue(item.from)} -> {this.renderValue(item.to)}
                </Typography>
              }
            />
          </ListItem>
        )}
      </List>
    )
  }

  render = () => {
    return (
      <div className='defectsInput'>
        {this.state.isMobile ? this.renderMobile() : this.renderDesktop()}
      </div>
    )
  }
}

export default connect(mapStoreToProps)(ChangesInput)
