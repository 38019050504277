import React from 'react';

export default class FileInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      object: this.props.object,
      file: null,
      properties: this.props.properties,
      infoLabel: null,
      initTime: Date.now(),
    };
  }

  handleChange = (e) => {
    let object = this.state.object;
    var file = e.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.onload = (e) => {
        object = {data: e.target.result, type: file.type};
        this.setState({object, file});
        this.props.onChange(this.props.objectKey, object);
      };
      reader.readAsDataURL(file);
    }
  }

  componentWillReceiveProps = (props) => {
    if (typeof props.object !== "undefined" && props.object !== null) {
      this.setState({object: props.object});
    }
  }

  render = () => {
    let fileLink = null;
    if (!this.state.file && this.state.object) {
      fileLink = <a href={this.state.object} target="_blank" rel="noopener noreferrer">Link</a>
    }
    return (
      <div className="edit-file">
        {fileLink}
        <input
          type="file"
          accept="*"
          onChange={this.handleChange}
          disabled={this.props.disabled}
        />
      </div>
    )
  }
}
