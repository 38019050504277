import $ from "jquery";

/*====  Set global variable here ====*/
var config = {
  production: {
    json_api_url: 'https://api.zaiko.co'
  },
  staging: {
    json_api_url: 'https://zaiko-backend.herokuapp.com'
  },
  local: {
    //json_api_url: 'http://00039ebf.ngrok.io'
    json_api_url: 'https://zaiko-backend.herokuapp.com'
  }
}

var globalVar = {
  json_api_url : config.production.json_api_url,
  environment: 'production'
}
// Local environment
if (window.location.hostname === 'localhost') {
  globalVar.json_api_url = config.local.json_api_url;
  globalVar.environment = 'local';
}
// Staging environment
if (window.location.hostname.match('herokuapp.com')) {
  globalVar.json_api_url = config.staging.json_api_url;
  globalVar.environment = 'staging';
}
// Production environment
if (window.location.hostname === 'zaiko.co') {
  globalVar.json_api_url = config.production.json_api_url;
  globalVar.environment = 'production';
}

$.ajaxSetup({
  statusCode: {
    401: (xhr) => {
      $(document).trigger("unauthorized", xhr.responseJSON);
    },
    500: (e) => {
      if (globalVar.environment !== "local") {
        // send some errors here
      }
    },
  }
});

export const WSUrl = () => {
  return globalVar.json_api_url.replace(/^http/, 'ws') + '/cable'
    + '?token=' + localStorage.getItem("zaiko-admin:token");
}

export const apiUrl = (apiMethod) => {
  return globalVar.json_api_url + "/api/admin/" + apiMethod;
}

export const authUrl = (apiMethod) => {
  return globalVar.json_api_url + "/api/v1/" + apiMethod;
}

var requestCounter = 0;
const addRequest = () => {
  requestCounter++;
  setTimeout(() => {
    if (requestCounter > 0) {
      $(".loaderContainer").fadeIn();
    }
  }, 400);
}
const removeRequest = () => {
  if (--requestCounter < 0) {
    requestCounter = 0;
  }
  if (requestCounter === 0) {
    $(".loaderContainer").fadeOut();
    $('div.Select-control').css("height", "auto");
  }
}
const resetRequests = () => {
  $(".loaderContainer").fadeOut();
}
 
export const getUrlParameter = (sParam, location) => {
  location = location || window.location.search;
  var sPageURL = decodeURIComponent(location.substring(1));
  var sURLVariables = sPageURL.split('&');
  for (let i = 0; i < sURLVariables.length; i++) {
    var sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : sParameterName[1];
    }
  }
  return false;
};

export const sendRequest = (properties) => {
  var token = localStorage.getItem("zaiko-admin:token");
  var url = properties.auth ? authUrl(properties.method) : apiUrl(properties.method);
  if (!properties.noLoad) {
    addRequest();
  }
  $.ajax({
    url: url,
    type: properties.type,
    data: properties.data,
    dataType: 'json',
    beforeSend: (xhr) => {
      xhr.setRequestHeader('Authorization', "Bearer " + token);
    },
    success: (data) => {
      if (!properties.noLoad) {
        removeRequest();
      }
      if (properties.success) {
        properties.success(data);
      }
    },
    error: (xhr, status, err) => {
      if (!properties.noLoad) {
        removeRequest();
      }
      resetRequests();
      console.error(properties.method, status, err.toString());
      if (properties.error) {
        properties.error(xhr, status, err);
      }
    }
  });
}

export const sendDataRequest = (properties) => {
  var token = localStorage.getItem("zaiko-admin:token");
  var url = apiUrl(properties.method);
  if (!properties.noLoad) {
    addRequest();
  }
  $.ajax({
    url: url,
    type: properties.type,
    data: properties.data,
    dataType: 'json',
    beforeSend: (xhr) => {
      xhr.setRequestHeader('Authorization', "Bearer " + token);
    },
    success: (data) => {
      if (!properties.noLoad) {
        removeRequest();
      }
      if (properties.success) {
        properties.success(data);
      }
    },
    error: (xhr, status, err) => {
      if (!properties.noLoad) {
        removeRequest();
      }
      resetRequests();
      console.error(properties.method, status, err.toString());
      if (properties.error) {
        properties.error(xhr, status, err);
      }
    },
    xhr: () => {
      var xhr = new window.XMLHttpRequest();
      xhr.upload.addEventListener("progress", (evt) => {
        if (evt.lengthComputable) {
          var percentComplete = evt.loaded / evt.total;
          if (properties.progress) {
            properties.progress(percentComplete);
          }
        }
      }, false);
      return xhr;
    },
  });
}

// Utils

export const validateEmail = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const objectsEqual = (a, b) => {
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);

  if (aProps.length !== bProps.length) {
      return false;
  }

  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];
    if (a[propName] !== b[propName]) {
      return false;
    }
  }
  
  return true;
}

export const getDateStringBegining = () => {
  return "2016-01-01";
}

export const getDateStringToday = () => {
  var today = new Date(); 
  return today.toISOString().substr(0, 10);
}

export const getDateStringMonthBefore = (n) => {
  if (!n) {
    n = 1;
  }
  var monthBefore = new Date();
  monthBefore.setMonth(monthBefore.getMonth() - n); 
  return monthBefore.toISOString().substr(0, 10);
}

export const getImageWithMaxWidth = (src, maxWidth, handler) => {
  var img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = () => {
    var canvas = document.createElement("CANVAS");
    var ctx = canvas.getContext("2d");
    var k = img.width <= maxWidth ? 1 : maxWidth / img.width;
    canvas.height = img.height * k;
    canvas.width = img.width * k;
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    var dataURL = canvas.toDataURL("image/png");
    if (handler) {
      handler(dataURL);
    }
  };
  img.src = src;
}

export const getImageWithCrop = (src, pixelCrop, handler) => {
  var img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = () => {
    var canvas = document.createElement("CANVAS");
    var ctx = canvas.getContext("2d");
    canvas.height = pixelCrop ? pixelCrop.height : img.height;
    canvas.width = pixelCrop ? pixelCrop.width : img.width;
    ctx.drawImage(img, pixelCrop ? -pixelCrop.x : 0, pixelCrop ? -pixelCrop.y : 0);
    var dataURL = canvas.toDataURL("image/png");
    if (handler) {
      handler(dataURL);
    }
  };
  img.src = src;
}
