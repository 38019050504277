import Storage from '../helpers/Storage.js';

Storage.setConfig("event_logs", {
  roles: ['admin'],
  section: "Settings",
  config: {
    method: "event_logs/",
    title: "Event logs",
    objectName: "Event log",
    preset: true,
    search: {
      created_at_min: {
        title: "Created date Start",
        type: "date"
      },
      created_at_max: {
        title: "Created date End",
        type: "date"
      },
      user_id: {
        title: "User",
        type: "select",
        request: "users/all",
        displayField: "username",
      },
      performed_action: {
        title: "Action",
        type: "select",
        request: "event_logs/performed_actions",
      }
    }
  },
  properties: {
    id: {
      default: false,
      type: "none",
      title: "#",
      preset: true,
      width: "50px",
    },
    created_at_formatted: {
      default: "",
      type: "none",
      title: "Created time",
      width: "auto",
    },
    created_at: {
      default: "",
      type: "text",
      title: "Created time",
    },
    user_id: {
      default: "",
      title: "User",
      type: "select",
      request: "users/all",
      displayField: "username",
      width: "auto",
    },
    auditable: {
      default: null,
      type: "page-link",
      title: "Subject"
    },
    performed_action: {
      default: "",
      type: "text",
      title: "Action",
      width: "auto",
    },
    changes: {
      default: [],
      type: "changes",
      title: "Changes",
    },

  }
});