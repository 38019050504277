import Storage from '../helpers/Storage.js';

Storage.setConfig("checklists", {
  roles: ['admin', 'dispatcher'],
  section: "Settings",
  config: {
    type: "checklist",
    method: "checklists/",
    title: "Checklists",
    objectName: "Checklist",
    deactivationDelete: true,
    singleReport: {
      export: {
        button: "Export Asset CSV",
        type: "get_report",
        request: "checklists/:id/export",
        showCondition: (object) => {return object.id},
      },
    },
    export: {
      import: {
        type: "upload",
        noSelect: true,
        button: "Import Checklist",
        confirm: "Send",
        method: "checklists/import/",
        accept: ".csv",
        fileParameter: "file",
      },
    },
    search: {
      name: {
        title: "Name",
        type: "text",
      },
      asset_class_id: {
        title: "Asset class",
        type: "select",
        request: "assets/classes"
      },
    },
  },
  properties: {
    id: {
      default: false,
      type: "none",
      title: "#",
      preset: true,
    },
    disable_edit: {
      default: false,
      type: "none",
      preset: true,
    },
    created_at: {
      default: "",
      type: "text",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "text",
      title: "Modified Time",
      preset: true,
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
      width: "auto"
    },
    active: {
      default: null,
      type: "select",
      title: "Active",
      options: [{id: true, name: 'Yes'}, {id: false, name: 'No'}],
      width: "60px",
    },
    reference: {
      default: "",
      type: "textarea",
      title: "Reference",
    },
    questions: {
      default: [],
      title: "Questions",
    },
    organization_ids: {
      title: "Organizations",
      type: "multi",
      request: "users/organizations",
      placeholder: "all",
    },
    
  }
});
