import Storage from '../helpers/Storage.js';

Storage.setConfig("prawn_service_reports", {
  config: {
    type: "reports",
    url_field: "prawn_file_url",
    method: "prawn_service_reports/",
    title: "Prawn Service reports",
    preset: true,
    search: {
      identifier: {
        title: "Identifier",
        type: "text",
      },
      name: {
        title: "Name",
        type: "text",
      },
      finished_at_min: {
        title: "Service Date Start",
        type: "date"
      },
      finished_at_max: {
        title: "Service Date End",
        type: "date"
      },
      customer_id: {
        title: "Customer",
        type: "select",
        request: "customers/all"
      },
      technician_id: {
        title: "Technician",
        type: "select",
        displayField: "username",
        request: "users/technicians"
      },
    },
  },
  properties: {
    id: {
      default: false,
      type: "none",
      title: "#",
      preset: true,
    },
    identifier: {
      default: false,
      type: "text",
      title: "Identifier",
      preset: true,
      width: "180px"
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
      width: "auto",
    },
    finished_at: {
      default: "",
      type: "text",
      title: "Date of completion",
      width: "180px",
      sort: true,
    },
    prawn_file_url: {
      default: "",
      type: "none",
      title: "",
    },

  }
});
