import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import {
  red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan, 
  teal, green, lightGreen, lime, yellow, amber, orange, deepOrange
} from '@material-ui/core/colors';

const COLORS = [
  red[500], pink[500], purple[500], deepPurple[500], indigo[500], blue[500], lightBlue[500], cyan[500], 
  teal[500], green[500], lightGreen[500], lime[500], yellow[500], amber[500], orange[500], deepOrange[500]
];

export default class UserAvatarView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  renderLetterAvatar = (user) => {
    let size = this.props.size || 30;
    let name = (user.username || user.name || '');
    let color = COLORS[(user.id + name.length) %  COLORS.length];
    let letter = name.substr(0, 1).toUpperCase();
    let style = Object.assign({
      width: size,
      height: size,
      verticalAlign: 'top',
      border: '1px solid rgba(255,255,255,0.25)',
      color: '#ffffff',
      backgroundColor: color,
      fontSize: '15px',
    }, this.props.style);
    return (
      <Avatar style={style}>{letter}</Avatar>
    )
  }

  renderImageAvatar = (image) => {
    let size = this.props.size || 30;
    let style = Object.assign({
      backgroundImage: `url(${image})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      width: size,
      height: size,
      borderRadius: '50%',
      verticalAlign: 'top',
      border: '1px solid rgba(255,255,255,0.25)',
    }, this.props.style);
    
    return (
      <div style={style}/>
    )
  }

  render = () => {
    let user = this.props.user || {};
    let image = user.avatar_url || user.avatar_image;
    if (image) {
      return this.renderImageAvatar(image);
    } else {
      return this.renderLetterAvatar(user);
    }
  }

}