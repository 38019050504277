import Storage from '../helpers/Storage.js';

Storage.setConfig("asset_classes", {
  roles: ['admin', 'dispatcher'],
  section: "Settings",
  config: {
    method: "asset_classes/",
    title: "Asset classes",
    objectName: "Asset class",
    search: {
      name: {
        title: "Name",
        type: "text",
      },
      category: {
        title: "Category",
        type: "text",
      },
    },
  },
  properties: {
    id: {
      default: false,
      type: "none",
      title: "#",
      preset: true,
    },
    created_at: {
      default: "",
      type: "text",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "text",
      title: "Modified Time",
      preset: true,
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
      width: "auto"
    },
    category: {
      default: "",
      type: "text",
      title: "Category",
      width: "auto"
    },
    checklist_ids: {
      default: [],
      type: "multi",
      title: "Checklists",
      request: "checklists/all",
      objectPageKey: "checklists",
      fastAdd: "Add Checklist",
      reloadOnActive: true,
    },
    inspection_frequency: {
      default: null,
      type: "select",
      title: "Inspection frequency",
      request: "asset_classes/inspection_frequencies",
    },
  }
});
