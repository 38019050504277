import React from 'react';
import Button from '@material-ui/core/Button';

import FlexibleInput from './FlexibleInput.js';

export default class SearchView extends React.Component {

  constructor(props) {
    super(props);
    var state = {
      config: props.config,
      object: {},
    };
    Object.keys(state.config).forEach((key) => {
      var type = state.config[key].type;
      if (props.defaultValue) {
        var defaultValue = props.defaultValue[key];
        if (typeof defaultValue === "string" && type === "select") {
          state.object[key] = {
            value: {
              id: defaultValue,
              name: defaultValue,
              value: defaultValue,
              label: defaultValue,
            }
          };
          return;
        }
        if (typeof defaultValue === "object") {
          state.object[key] = {
            value: defaultValue
          };
          return;
        }
      }
      if (type === "text") {
        state.object[key] = {
          value: ""
        }
      }
      if (type === "checkbox") {
        state.object[key] = {
          value: !!state.config[key].default,
        }
      }
      if (type === "date") {
        state.object[key] = {
          value: ""
        }
      }
      if (type === "address") {
        var addressKeys = Object.keys(state.config[key].placeholder);
        state.object[key] = {value: {}}
        for (let j = 0; j < addressKeys.length; j++) {
          state.object[key].value[addressKeys[j]] = "";
        }
      }
      if (type === "select") {
        state.object[key] = {
          options: [],
          value: null
        }
      }
      if (type === "multi") {
        state.object[key] = {
          options: [],
          value: []
        }
      }
      if (type === "date-interval") {
        var today = new Date();
        var monthBefore = new Date();
        monthBefore.setMonth(monthBefore.getMonth() - 1);
        state.object[key] = {
          value: {
            from: monthBefore.toISOString(),
            to: today.toISOString()
          }
        }
      }
      if (type === "interval") {
        state.object[key] = {
          value: {
            from: "",
            to: ""
          }
        }
      }
    });
    this.state = state;
  }

  componentWillReceiveProps = (props) => {
    if (props.config !== this.state.config) {
      this.setState({config: props.config});
    }
  }

  handleChange = (key, value) => {
    var object = this.state.object;
    object[key].value = value;
    this.setState({object: object});

    if (this.props.onChange) {
      var search = {};
      var keys = Object.keys(this.state.object);
      for (let i = 0; i < keys.length; i++) {
        search[keys[i]] = this.state.object[keys[i]].value;
      }
      this.props.onChange(search);
    }
  }

  handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.handleSearchClick(e);
    }
  }

  handleSearchClick = (e) => {
    var search = {};
    var keys = Object.keys(this.state.object);
    for (let i = 0; i < keys.length; i++) {
      search[keys[i]] = this.state.object[keys[i]].value;
    }
    this.props.onSearch(search);
  }

  render = () => {
    return (
      <div className="search-view card" onKeyUp={this.handleKeyUp}>
        {Object.keys(this.state.object).filter(key =>
          !this.state.config[key].showCondition || this.state.config[key].showCondition()
        ).map(key =>
          <div key={key} className='searchField'>
            <label>{this.state.config[key].title}</label>
            <FlexibleInput
              object={this.state.object[key].value}
              onChange={this.handleChange}
              objectKey={key}
              objectProperties={this.state.config}
            />
          </div>
        )}
        {this.props.onSearch ?
          <div className='searchButton'>
            <Button
              variant='contained'
              onClick={this.handleSearchClick}
              color='primary'
            >Search</Button>
          </div>
        : null}
      </div>
    )
  }
}
