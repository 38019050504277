import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import { sendRequest } from '../../helpers/global.js';
import Storage from '../../helpers/Storage.js';

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class NotesInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isMobile: !!Storage.getSetup('is_mobile'),
      object: this.props.object,
      properties: this.props.properties,
      message: '',
    };
  }

  handleChange = (e) => {
    let object = e.target.value;
    this.setState({object: object});
    this.props.onChange(this.props.objectKey, object);
  }

  componentDidMount = () => {
  }

  componentWillReceiveProps = (props) => {
    let stateChange = {};
    let mapping = mapStoreToProps({data: {}, setup: {}});
    Object.keys(mapping).forEach((key) => {
      if (typeof props[key] !== 'undefined' && props[key] !== null) {
        stateChange[key] = props[key];
      }
    });
    if (props.object && props.object !== this.state.object) {
      stateChange.object = props.object;
    }
    this.setState(stateChange);
  }

  onDelete = (item) => {
    let properties = this.state.properties;
    let request = properties.request.replace(":id", this.props.parentId);
    sendRequest({
      method: request,
      type: "DELETE",
      data: {
        note_id: item.id,
      },
      success: (data) => {
        this.setState({object: data});
        this.props.onChange(this.props.objectKey, data);
      },
      error: (xhr, status, err) => {
      }
    });
  }

  onAdd = () => {
    let properties = this.state.properties;
    let request = properties.request.replace(":id", this.props.parentId);
    sendRequest({
      method: request,
      type: "POST",
      data: {
        content: this.state.message,
      },
      success: (data) => {
        this.setState({object: data, message: ''});
        this.props.onChange(this.props.objectKey, data);
      },
      error: (xhr, status, err) => {
      }
    });
  }

  renderDeleteButton = (item) => {
    return (
      <Button
        variant='text'
        disabled={this.props.disabled}
        onClick={() => this.onDelete(item)}
      >Delete</Button>
    )
  }

  renderDesktop = () => {
    let object = this.state.object || [];
    return (
      <table className="table table-hover">
        <thead>
          <tr>
            <th width="155px">Date</th>
            <th width="200px">Author</th>
            <th width="auto">Content</th>
            <th width="90px"></th>
          </tr>
        </thead>
        <tbody>
          {object.map(item =>
            <tr key={item.id}>
              <td>{item.created_at}</td>
              <td>{item.author}</td>
              <td>{item.content}</td>
              <td>{this.renderDeleteButton(item)}</td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  renderMobile = () => {
    let object = this.state.object || [];
    return (
      <List style={{padding: '0px 0px 12px'}}>
        {object.map(item => 
          <ListItem alignItems='flex-start' key={item.id}>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography style={{display: 'inline-block', fontSize: 14,  fontWeight: 500}}>
                    {item.author}
                  </Typography>
                  <Typography style={{display: 'inline-block', fontSize: 14, marginLeft: 6}}>
                    {`- ${item.created_at}`}
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <Typography style={{fontSize: 16, paddingTop: 4}}>
                  {item.content}
                </Typography>
              }
            />
            {this.renderDeleteButton(item)}
          </ListItem>
        )}
      </List>
    )
  }

  render = () => {
    
    return (
      <div className='defectsInput'>
        {this.state.isMobile ? this.renderMobile() : this.renderDesktop()}
        <div>
          <input
            type='text'
            value={this.state.message}
            onChange={e => this.setState({message: e.target.value})}
            className='form-control'
            placeholder='New note'
            disabled={this.props.disabled}
            style={{
              display: 'inline-block',
              width: 'calc(100% - 95px)',
              marginRight: 15,
            }}
          />
          <Button
            variant='contained'
            onClick={this.onAdd}
            disabled={this.props.disabled}
            style={{
              padding: 4,
              verticalAlign: 'top',
            }}
          >Add</Button>
        </div>
      </div>
    )
  }
}

export default connect(mapStoreToProps)(NotesInput)
