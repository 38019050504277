import React from 'react';
import $ from "jquery";
import Select from 'react-select';

import { sendRequest, objectsEqual } from '../../helpers/global.js';
import Button from '@material-ui/core/Button';

export default class SelectInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      object: this.props.object,
    };
  }

  componentDidMount = () => {
    this.getOptions();
    if (this.props.properties.reloadOnActive) {
      $(document).on("pageVisible", this.getOptions);
    }
  }

  componentWillUnmount = () => {
    $(document).off("pageVisible", this.getOptions);
  }

  handleSelectInputChange = (e) => {
    var properties = this.props.properties;
    if (properties.minSearch) {
      this.getOptions(e);
    }
  }

  getOptions = (input) => {
    var properties = this.props.properties;
    let displayKey = properties.displayField || "name";
    // Load select options and autocomplete if edit is enabled 
    if (!properties.request || properties.hostProperty) {
      return;
    }
    var request = properties.request.slice();
    var data = {};
    if (request.match(/:id/)) {
      if (this.props.parentId) {
        request = request.replace(":id", this.props.parentId);
      } else {
        return;
      }
    }
    if (properties.minSearch) {
      if (!input || input.length < properties.minSearch) {
        if (this.state.options && this.state.options.length > 0) {
          this.setState({options: []});
        }
        return;
      }
      data[displayKey] = input;
    }        
    sendRequest({
      method: request,
      type: "GET",
      data: data,
      success: (data) => {
        if (data && [].constructor === Array) {
          let options = [];
          for (let i = 0; i < data.length; i++) {
            if (typeof data[i] === "string") {
                options.push({value: data[i], label: data[i]});
            } else {
                data[i].value = data[i].id;
                data[i].label = data[i][displayKey];
                options.push(data[i]);
            }
          }
          this.setState({options: options});
        }
      },
      error: (xhr, status, err) => {
      }
    });
  }

  handleChange = (e) => {
    let object = null;
    var properties = this.props.properties;
    if (properties.type === "select") {
      if (e && e.length !== 0) {
        object = e.value;
      } else {
        object = null;
      }
    } else if (properties.type === "multi") {
      object = e.map(item => item.value);
    }
    this.setState({object: object});
    this.props.onChange(this.props.objectKey, object);
  }

  componentWillReceiveProps = (props) => {
    if (props.object === undefined) {
      return;
    }
    this.setState({object: props.object}, () => {
      if (props.hostPropertyValue && (!objectsEqual(props.hostPropertyValue, this.props.hostPropertyValue))) {
        this.updateHostedOptions(props);
      }
    });
  }

  updateHostedOptions = (props) => {
    var properties = this.props.properties;
    if (properties.hostProperty) {
      let request = properties.request.slice();
      let data = {};

      if (request.match(/:id/)) {
        let id = props.hostPropertyValue.id;
        if (id) {
          request = request.replace(":id", id);
        } else {
          if (this.state.object) {
            this.setState({object: null});
          }
          return;
        }
      }
      
      if (properties.hostParameter) {
        let val = props.hostPropertyValue;
        if (Array.isArray(val)) {
          data[properties.hostParameter] = val.map(item => item.id);
        } else if (typeof val === "object") {
          data[properties.hostParameter] = val.id;
        } else {
          data[properties.hostParameter] = val;
        }
      }

      sendRequest({
        method: request,
        data: data,
        type: "GET",
        success: (data) => {
          let objectFound = !this.state.object || !this.state.object.id;
          let options = data.map((datum) => {
            if (this.state.object === datum.id) {
              objectFound = true;
            }
            datum.value = datum.id;
            datum.label = datum[properties.displayField || "name"];
            return datum;
          });
          let stateChange = {options: options};
          if (!objectFound) {
            stateChange.object = null;
          }
          this.setState(stateChange);
        }
      });
    }
  }

  handleItemClick = (val, e) => {
    var objectPageKey = this.props.properties.objectPageKey;
    if (objectPageKey && val.id) {
      $(document).trigger("openPage", [objectPageKey, {id: val.id}]);
    }
  }

  render = () => {
    var properties = this.props.properties;
    var options = this.state.options || [];
    if (properties.options) {
      options = properties.options.map(option => {
        return {
          value: option.id,
          label: option[properties.displayField || "name"],
          ...option
        };
      });
    }
    if (properties.filterCondition) {
      options = options.filter(item => {
        if (properties.filterCondition(item)) {
          return true;
        }
        if (properties.type === "select") {
          return item.id === this.state.object;
        } else if (properties.type === "multi") {
          return (this.state.object || []).indexOf(item.id) > -1;
        }
        return false;
      });
    }
    var value = this.state.object;
    if (properties.type === "select") {
      value = options.find(option => (option.id || option.value) === this.state.object);
    } else if (properties.type === "multi") {
      value = (this.state.object || []).map(id => 
        options.find(option => (option.id || option.value) === id)
      );
    }
    return (
      <div>
        <Select
          name="form-field-name"
          value={value}
          isMulti={properties.type === "multi"}
          isClearable={true}
          options={options}
          onChange={this.handleChange}
          isDisabled={this.props.disabled}
          placeholder={properties.placeholder || (properties.minSearch ? "Enter..." : "Select...")}
          onValueClick={properties.objectPageKey ? this.handleItemClick : null}
          onInputChange={this.handleSelectInputChange}
          className='selectInput'
          classNamePrefix='selectInput'
        />
        {properties.fastAdd ?
          <div style={{marginTop: 8, textAlign: 'right'}}>
            <Button
              variant='text'
              disabled={this.props.disabled}
              onClick={() => $(document).trigger("openPage", [properties.objectPageKey, null, "create"])}
            >{properties.fastAdd}</Button>
          </div>
        : null}
      </div>
    )
  }
}
