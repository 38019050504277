import Storage from '../helpers/Storage.js';

Storage.setConfig("settings", {
  config: {
    type: "settings",
    method: "me/",
    title: "Account Settings",
    objectName: "Account Settings",
  },
  properties: {
    username: {
      default: "",
      type: "text",
      title: "Username",
    },
    email: {
      default: "",
      type: "text",
      title: "Email",
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
    },
    phone: {
      default: "",
      type: "text",
      title: "Phone",
    },
    password: {
      default: "",
      type: "password",
      title: "Password",
    },
    repeat_password: {
      default: "",
      type: "password",
      title: "Repeat password",
    },
    /*sms_auth: {
      default: "",
      type: "select",
      title: "SMS Auth",
      options: [{id: true, name: 'Yes'}, {id: false, name: 'No'}],
    },*/
    order_prefix: {
      default: "",
      type: "text",
      title: "Order prefix",
      placeholder: "date variables are supported, e.g. %d%m%y",
    },
    order_suffix: {
      default: "",
      type: "text",
      title: "Order suffix",
      placeholder: "date variables are supported, e.g. %d%m%y",
    },
    order_starting_number: {
      default: "",
      type: "text",
      title: "Starting number",
    },
    avatar_image: {
      default: "",
      type: "image",
      title: "Avatar",
    },

  }
});