import Storage from '../helpers/Storage.js';

Storage.setConfig("users", {
  section: "Settings",
  config: {
    method: "users/",
    title: "Users",
    objectName: "User",
    createRoles: ['admin', 'agent'],
    deleteRoles: ['admin', 'agent'],
    search: {
      username: {
        title: "Username",
        type: "text",
      },
      email: {
        title: "Email",
        type: "text",
      },
      name: {
        title: "Name",
        type: "text",
      },
      role: {
        title: "Role",
        type: "select",
        request: "users/roles",
      },
      organization_id: {
        title: "Organization",
        type: "select",
        request: "users/organizations",
        showCondition: object => ['admin', 'agent'].indexOf((Storage.getData("user") || {}).role) > -1,
      },
    },
  },
  properties: {
    avatar_url: {
      type: "avatar",
      width: "50px",
    },
    id: {
      default: false,
      type: "none",
      title: "#",
      preset: true,
    },
    created_at: {
      default: "",
      type: "text",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "text",
      title: "Modified Time",
      preset: true,
    },
    username: {
      default: "",
      type: "text",
      title: "Username",
      width: "auto"
    },
    email: {
      default: "",
      type: "text",
      title: "Email",
      width: "auto"
    },
    password: {
      default: "",
      type: "password",
      title: "Password",
      placeholder: "Autogenerated on register",
      modifyCondition: (object) => !!object.id,
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
      width: "auto"
    },
    role: {
      default: "",
      type: "select",
      title: "Role",
      request: "users/roles",
      width: "auto",
    },
    organization_id: {
      default: null,
      type: "select",
      title: "Organization",
      objectPageKey: "organizations",
      request: "users/organizations",
      listCondition: object => ['admin', 'agent'].indexOf((Storage.getData("user") || {}).role) > -1,
      showCondition: object => ['admin', 'agent'].indexOf((Storage.getData("user") || {}).role) > -1,
      width: "auto",
    },   

  }
});
