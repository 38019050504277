import React from 'react';
import $ from "jquery";

export default class GraphListView extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleLineClick = (e, i) => {
    if (this.props.objectPageKey && this.props.data.ids[i]) {
      if (e.button === 0) {
        $(document).trigger("changePage", [this.props.objectPageKey, {id: this.props.data.ids[i]}]);
      } else if (e.button === 1) {
        $(document).trigger("openPage", [this.props.objectPageKey, {id: this.props.data.ids[i]}]);
      }
    }
  }

  render = () => {
    let lines = this.props.data.labels.map((labelData, i) => {
      let valueData = this.props.data.datasets[0] ? this.props.data.datasets[0].data[i] : null;
      let value = null;
      if (Array.isArray(valueData)) {
        value = valueData.reverse().map((valueDatum, i) => {
          return <span key={i}>{valueDatum}</span>
        });
      } else {
        value = <span>{valueData}</span>
      }
      let label = <span>{labelData}</span>;
      if (this.props.objectPageKey && this.props.data.ids[i]) {
        label = 
          <span onMouseDown={(e) => {this.handleLineClick(e,i)}}>
            {label}
          </span>
      }
      return (
        <div key={i} className="graphListItem">
          <div className="valueLabel">
            {label}
          </div>
          <div className="valueLabel">
            {value}
          </div>
        </div>
      );
    });
    return (
      <div className="graphListView">
        <h4>{this.props.options.title.text}</h4>
        {lines}
      </div>
    )
  }
}
