import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import { sendRequest } from '../helpers/global.js';
import Storage from '../helpers/Storage.js';

import FlexibleInput from './FlexibleInput.js';

export default class RequestView extends React.Component {

  constructor(props) {
    super(props);
    var properties = Object.assign({}, Storage.getConfig(props.configKey).properties);
    var config = Storage.getConfig(props.configKey).config;
    var keys = Object.keys(properties);
    var object = {};
    for (let i = 0; i < keys.length; i++) {
      var key = keys[i];
      if (props.object && props.object[key]) {
        object[key] = props.object[key];
      } else {
        if (Array.isArray(properties[key].default)) {
          object[key] = properties[key].default.slice();
        } else if (typeof properties[key].default === "object") {
          object[key] = Object.assign({}, properties[key].default);
        } else {
          object[key] = properties[key].default;
        }
      }
    }
    var state = {
      object: object,
      properties: properties,
      config: config,
      responseContent: false,
    };
    this.state = state;
  }

  onModalClose = () => {
    this.setState({responseContent: false});
  }

  handleSend = (e) => {
    e.preventDefault();
    sendRequest({
      method: this.state.config.method,
      type: this.state.config.methodType,
      data: this.state.object,
      success: (data) => {
        this.setState({responseContent: data});
      },
      error: (xhr, status, err) => {
      }
    });
  }

  handlePropertyChange = (key, value) => {
    var object = this.state.object;
    object[key] = value;
    this.setState({object: object});
  }

  render = () => {
    var inputs = Object.keys(this.state.properties).map((key) => { // eslint-disable-line
      if (this.state.properties[key].type !== "none") {
        var groupHidden = this.state.properties[key].preset && !this.state.object.id;
        var inputDisabled = this.state.properties[key].preset 
          || (!this.state.edit && this.state.object.id > 0)
          || (this.state.edit && this.state.properties[key].createOnly);
        return (
          <div className="form-group" hidden={groupHidden} key={key}>
            <label htmlFor="id" className="col-sm-2 control-label">{this.state.properties[key].title}</label>
            <div className="col-sm-10">
              <FlexibleInput
                object={this.state.object[key]}
                config={this.state.config}
                onChange={this.handlePropertyChange}
                disabled={inputDisabled}
                objectKey={key}
                objectProperties={this.state.properties}
              />
            </div>
          </div>
        );
      }
    });
    return (
      <div className="adminContent customScrollBar">
        <h1 className="page-header">
          {this.state.config.title}
        </h1>

        <form className="form-horizontal">
          {inputs}
          <div className="form-group">
            <div className="col-sm-offset-2 col-sm-10">
              <button 
                onClick={this.handleSend}
                className="btn btn-primary"
              >Send</button>
            </div>
          </div>
        </form>

        <Modal show={!!this.state.responseContent} onHide={this.onModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Server response</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span className="errorMessage">{this.state.responseContent.error}</span>
            {this.state.responseContent.message}
            <br/>
            <a href={this.state.responseContent.link} target="_blank" rel="noopener noreferrer">{this.state.responseContent.link ? "Download link" : ""}</a>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onModalClose}>Close</Button>
          </Modal.Footer>
        </Modal>

      </div>
    )
  }
  
}
