import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import { sendRequest } from '../../helpers/global.js';
import Storage from '../../helpers/Storage.js';

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class DefectsInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isMobile: !!Storage.getSetup('is_mobile'),
      object: this.props.object,
      properties: this.props.properties,
      showDetails: false,
      selectedItem: null,
    };
  }

  handleChange = (e) => {
    let object = e.target.value;
    this.setState({object: object});
    this.props.onChange(this.props.objectKey, object);
  }

  componentDidMount = () => {
  }

  componentWillReceiveProps = (props) => {
    let stateChange = {};
    let mapping = mapStoreToProps({data: {}, setup: {}});
    Object.keys(mapping).forEach((key) => {
      if (typeof props[key] !== 'undefined' && props[key] !== null) {
        stateChange[key] = props[key];
      }
    });
    if (props.object && props.object !== this.state.object) {
      stateChange.object = props.object;
    }
    this.setState(stateChange);
  }

  onSwitch = (item) => {
    let properties = this.state.properties;
    let request = properties.switch_request.replace(":id", this.props.parentId);
    sendRequest({
      method: request,
      type: "POST",
      data: {
        id: item.id,
        active: !item.active,
      },
      success: (data) => {
        this.setState({object: data});
        this.props.onChange(this.props.objectKey, data);
      },
      error: (xhr, status, err) => {
      }
    });
  }

  renderViewButton = (item) => {
    return (
      <Button
        variant='text'
        color='primary'
        onClick={() => this.setState({showDetails: true, selectedItem: item})}
        style={{padding: '0px 8px', margin: '-4px 0px'}}
      >View</Button>
    )
  }

  renderSwitchButton = (item) => {
    return (
      <Button
        variant='text'
        onClick={() => this.onSwitch(item)}
        style={{padding: '0px 8px', margin: '-4px 0px'}}
      >{item.active ? 'Outstanding' : 'Repaired'}</Button>
    )
  }

  renderDesktop = () => {
    let object = this.state.object || [];
    return (
      <table className="table table-hover">
        <thead>
          <tr>
            <th width="80px">#</th>
            <th width="120px">Service Date</th>
            <th width="auto">Defect</th>
            <th width="100px">Description</th>
            <th width="140px"></th>
          </tr>
        </thead>
        <tbody>
          {object.map(item =>
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.date}</td>
              <td>{item.question_title}</td>
              <td>{this.renderViewButton(item)}</td>
              <td>{this.renderSwitchButton(item)}</td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  renderMobile = () => {
    let object = this.state.object || [];
    return (
      <List style={{padding: '0px 0px 12px'}}>
        {object.map(item => 
          <div key={item.id} style={{marginBottom: 10}}>
            <ListItem alignItems='flex-start'>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography style={{display: 'inline-block', fontSize: 14,  fontWeight: 500}}>
                      {`#${item.id}`}
                    </Typography>
                    <Typography style={{display: 'inline-block', fontSize: 14, marginLeft: 6}}>
                      {`- ${item.date}`}
                    </Typography>
                    <Typography style={{fontSize: 16, paddingTop: 2}}>
                      {item.question_title}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            <div style={{paddingLeft: 10, marginTop: -5}}>
              {this.renderViewButton(item)}
              {this.renderSwitchButton(item)}
            </div>
          </div>
        )}
      </List>
    )
  }

  render = () => {
    let item = this.state.selectedItem || {};
    return (
      <div className='defectsInput'>
        {this.state.isMobile ? this.renderMobile() : this.renderDesktop()}

        <Dialog
          open={this.state.showDetails}
          onClose={() => this.setState({showDetails: false})}
          fullWidth={true}
          maxWidth='sm'
          scroll='paper'
        >
          <DialogTitle>{item.question_title || ''}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {item.description || ''}
            </DialogContentText>
            {item.photo_url ?
              <img src={item.photo_url} alt='' style={{maxWidth: '100%', marginTop: 15}}/>
            : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({showDetails: false})} color="primary">
              close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default connect(mapStoreToProps)(DefectsInput)
