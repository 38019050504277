import Storage from '../helpers/Storage.js';

Storage.setConfig("customers", {
  rootSection: true,
  config: {
    method: "customers/",
    title: "Customers",
    objectName: "Customer",
    search: {
      name: {
        title: "Name",
        type: "text",
      },
      email: {
        title: "Email",
        type: "text",
      },
      active: {
        default: null,
        type: "select",
        title: "Active",
        options: [{id: true, name: 'Yes'}, {id: false, name: 'No'}],
      },
    },
  },
  properties: {
    avatar_url: {
      type: "avatar",
      width: "50px",
    },
    id: {
      default: false,
      type: "none",
      title: "#",
      preset: true,
    },
    created_at: {
      default: "",
      type: "text",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "text",
      title: "Modified Time",
      preset: true,
    },
    avatar_image: {
      default: "",
      type: "image",
      title: "Avatar",
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
      width: "auto"
    },
    email: {
      default: "",
      type: "text",
      title: "Email",
    },
    phone: {
      default: "",
      type: "text",
      title: "Phone",
    },
    contact_name: {
      default: "",
      type: "text",
      title: "Contact name",
    },
    designation: {
      default: "",
      type: "text",
      title: "Designation",
    },
    address: {
      default: "",
      type: "text",
      title: "Address",
    },
    signer: {
      default: "",
      type: "text",
      title: "Signer company",
    },
    active: {
      default: null,
      type: "select",
      title: "Active",
      options: [{id: true, name: 'Yes'}, {id: false, name: 'No'}],
    },

  }
});
