import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

export default class CheckboxInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      object: this.props.object,
      properties: this.props.properties,
    };
  }

  handleChange = (e) => {
    let object = !this.state.object;
    this.setState({object: object});
    this.props.onChange(this.props.objectKey, object);
  }

  componentWillReceiveProps = (props) => {
    if (typeof props.object !== "undefined" && props.object !== null) {
      this.setState({object: props.object});
    }
  }

  render = () => {
    return (
      <Checkbox 
        color='primary'
        checked={!!this.state.object}
        style={{padding: 0}}
        disabled={this.props.disabled}
        onChange={this.handleChange}
      />
    )
  }
}
