import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

import { sendRequest, validateEmail } from '../helpers/global.js';

import '../sass/SupportView.scss';

export default class SupportView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      showAlert: false,
    };
    this.inputRefs = {};
  }

  onSend = () => {
    let errors = {};
    ['name', 'email', 'subject', 'message'].forEach(key => {
      if (!this.state.data[key]) {
        errors[key] = true;
      }
      if (key === 'email' && !validateEmail(this.state.data[key])) {
        errors[key] = true;
      }
    });
    if (Object.keys(errors).length > 0) {
      this.setState({errors}, () => {
        Object.keys(errors).forEach(key => {
          let input = this.inputRefs[key];
          if (input) {
            input.classList.add('shake-horizontal');
            setTimeout(() => {
              input.classList.remove('shake-horizontal');
            }, 650);
          }
        })
      });
      return;
    }
    sendRequest({
      auth: true,
      method: 'contacts/support',
      type: 'POST',
      data: this.state.data,
      success: (data) => {
        this.setState({data: {}, showAlert: true});
      },
      error: (xhr, status, err) => {
      }
    });
  }

  renderInput = (key, title, type = 'text') => {
    let props = {
      key,
      type,
      autoComplete: key,
      placeholder: title,
      value: this.state.data[key] || '',
      onChange: e => {
        this.setState({data: {...this.state.data, [key]: e.target.value}})
      },
      ref: input => this.inputRefs[key] = input,
      className: this.state.errors[key] ? 'error' : '',
      onFocus: () => this.setState({errors: {...this.state.errors, [key]: false}}),
    }
    if (type === 'textarea') {
      return <textarea {...props}/>
    }
    return <input {...props}/>
  }
  
  render = () => {
    return (
      <div className="supportView">
        <div className="header">
          <div className="logo" onClick={() => {
            window.location.href = '/';
          }}/>
        </div>
        <div className="supportForm">
          <h1>Support</h1>
          {this.renderInput('name', 'Your name', 'text')}
          {this.renderInput('email', 'Your email', 'email')}
          <br/>
          {this.renderInput('subject', 'Subject', 'text')}
          {this.renderInput('message', 'Message', 'textarea')}
          <button onClick={this.onSend}>Send</button>
        </div>
        <Snackbar
          open={this.state.showAlert}
          message='Your message is being processed. We will contact you back soon' 
          autoHideDuration={4000}
          onClose={() => {this.setState({showAlert: false})}}
          ContentProps={{
            style: {fontSize: '14px'}
          }}
        />
      </div>
    )
  }

}
  