import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

import { sendRequest, getUrlParameter } from '../helpers/global.js';

import VerificationCodeView from './VerificationCodeView.js';

import '../sass/LoginView.scss';

export default class LoginView extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      data: {
        username: '',
        password: '',
      },
      errors: {},
      sms_auth: {
        show: false,
        phone: null,
        token: null,
        sendTime: null,
      },
      mode: getUrlParameter('reset') ? 'reset' : 'login',
      showAlert: false,
      alertMessage: '',
    };
  }

  handleLogin = () => {
    let data = this.state.data;
    let errors = {};
    if (!data.username) {
      errors.username = 'Please enter Username';
    }
    if (!data.password) {
      errors.password = 'Please enter Password';
    }
    if (Object.keys(errors).length > 0) {
      this.setState({errors});
      return;
    }
    sendRequest({
      method: 'auth/login',
      auth: true,
      type: 'POST',
      data: {
        username: data.username,
        password: data.password,
      },
      success: (data) => {
        if (data.user) {
          this.props.onLogin(data);
        } else if (data.sms_token) {
          this.setState({
            sms_auth: {
              show: true,
              phone: data.phone,
              token: data.sms_token,
              sendTime: Date.now(),
            },
          });
        } else {
          this.setState({errors: {...this.state.errors, password: 'Credentials are invalid'}});
        }
      },
      error: (xhr, status, err) => {
        this.setState({errors: {...this.state.errors, password: 'Credentials are invalid'}});
      }
    });
  }

  handleForgot = () => {
    let data = this.state.data;
    let errors = {};
    if (!data.username) {
      errors.username = 'Please enter Username';
    }
    if (Object.keys(errors).length > 0) {
      this.setState({errors});
      return;
    }
    sendRequest({
      method: 'auth/forgot_password',
      auth: true,
      type: 'POST',
      data: {username: data.username},
      success: (data) => {
        this.setState({showAlert: true, alertMessage: 'Reset link has been sent to your email'});
      },
      error: (xhr, status, err) => {
        this.setState({errors: {...this.state.errors, username: 'Credentials are invalid'}});
      }
    });
  }

  handleReset = () => {
    let data = this.state.data;
    let errors = {};
    if (!data.password) {
      errors.password = 'Please enter Password';
    }
    if (Object.keys(errors).length > 0) {
      this.setState({errors});
      return;
    }
    sendRequest({
      method: 'auth/reset_password',
      auth: true,
      type: 'POST',
      data: {
        token: getUrlParameter('token'),
        password: data.password,
      },
      success: (data) => {
        this.setState({mode: 'login', showAlert: true, alertMessage: 'Password updated'});
      },
      error: (xhr, status, err) => {
        this.setState({errors: {...this.state.errors, password: 'Password is invalid'}});
      }
    });
  }

  renderInput = (key, title, type = 'text') => {
    return (
      <div key={key}>
        <label htmlFor={key}>{title}</label>
        <input
          type={type}
          autoComplete={key}
          placeholder={this.state.errors[key] || ''}
          value={this.state.errors[key] ? '' : this.state.data[key]}
          onChange={e => this.setState({data: {...this.state.data, [key]: e.target.value}})}
          onFocus={() => this.setState({errors: {...this.state.errors, [key]: null}})}
        />
      </div>
    )
  }

  renderLoginForm = () => {
    return (
      <form onSubmit={e => {
        e.preventDefault();
        this.handleLogin();
      }}>
        <div className='logo'/>
        {this.renderInput('username', 'Username', 'text')}
        {this.renderInput('password', 'Password', 'password')}
        <span onClick={() => this.setState({mode: 'forgot'})}>Forgot password</span>
        <button type='submit' value='Post'>Enter</button>
      </form>
    )
  }

  renderForgotForm = () => {
    return (
      <form onSubmit={e => {
        e.preventDefault();
        this.handleForgot();
      }}>
        <div className='logo'/>
        {this.renderInput('username', 'Username', 'text')}
        <span onClick={() => this.setState({mode: 'login'})}>Cancel</span>
        <button type='submit' value='Post'>Reset Password</button>
      </form>
    )
  }
  
  renderResetForm = () => {
    return (
      <form onSubmit={e => {
        e.preventDefault();
        this.handleReset();
      }}>
        <div className='logo'/>
        {this.renderInput('password', 'New password', 'password')}
        <span onClick={() => this.setState({mode: 'login'})}>Cancel</span>
        <button type='submit' value='Post'>Change Password</button>
      </form>
    )
  }

  render = () => {
    let sms_auth = this.state.sms_auth;
    let form = null;
    if (this.state.mode === 'forgot') {
      form = this.renderForgotForm();
    } else if (this.state.mode === 'reset') {
      form = this.renderResetForm();
    } else {
      form = this.renderLoginForm();
    }
    return (
      <div className='loginView'>
        {form}

        <Snackbar
          open={this.state.showAlert}
          message={this.state.alertMessage} 
          autoHideDuration={4000}
          onClose={() => {this.setState({showAlert: false})}}
          ContentProps={{
            style: {fontSize: '14px'}
          }}
        />

        <VerificationCodeView
          show={sms_auth.show}
          token={sms_auth.token}
          phone={sms_auth.phone}
          sendTime={sms_auth.sendTime}
          method='auth/sms_login'
          auth={true}
          onSuccess={(data) => {
            if (data.user) {
              this.props.onLogin(data);
            } else {
              this.setState({password: ''});
            }
          }}
          onResend={(data) => {
            this.setState({sms_auth: {...sms_auth, sendTime: Date.now()}})
          }}
          onClose={() => this.setState({sms_auth: {...sms_auth, show: false}})}
        />

      </div>
    );
  }
}
