import React from 'react';

export default class LinkInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      object: this.props.object,
      properties: this.props.properties,
    };
  }

  handleChange = (e) => {
    let object = e.target.value;
    this.setState({object: object});
    this.props.onChange(this.props.objectKey, object);
  }

  componentWillReceiveProps = (props) => {
    if (typeof props.object !== "undefined" && props.object !== null) {
      this.setState({object: props.object});
    }
  }

  render = () => {
    var properties = this.state.properties;

    if (this.props.disabled) {
      return (
        <a className="view-link" href={this.state.object} target="_blank" rel="noopener noreferrer">Link</a>
      )
    } else {
      return (
        <input
          type={properties.type}
          value={this.state.object}
          onChange={this.handleChange}
          className="form-control"
        />
      )
    }
  }
}
