import React from 'react';

import DateInput from '../input/DateInput.js';
import TextInput from '../input/TextInput.js';

export default class IntervalInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      object: this.props.object,
      properties: this.props.properties,
    };
  }

  handleChange = (key, e) => {
    let object = this.state.object;
    object[key] = e;
    this.setState({object: object});
    this.props.onChange(this.props.objectKey, object);
  }

  componentWillReceiveProps = (props) => {
    if (typeof props.object !== "undefined" && props.object !== null) {
      this.setState({object: props.object});
    }
  }

  render = () => {
    var properties = this.state.properties;

    if (properties.type === "date-interval") {
      return (
        <div className="date-interval">
          <DateInput
            properties={{type: "date"}}
            object={this.state.object.from}
            disabled={this.props.disabled}
            objectKey="from"
            onChange={this.handleChange}
          />
          <DateInput
            properties={{type: "date"}}
            object={this.state.object.to}
            disabled={this.props.disabled}
            objectKey="to"
            onChange={this.handleChange}
          />
        </div>
      )
    }

    if (properties.type === "interval") {
      return (
        <div className="date-interval">
          <TextInput
            properties={{type: "text"}}
            object={this.state.object.from}
            disabled={this.props.disabled}
            objectKey="from"
            onChange={this.handleChange}
          />
          <TextInput
            properties={{type: "text"}}
            object={this.state.object.to}
            disabled={this.props.disabled}
            objectKey="to"
            onChange={this.handleChange}
          />
        </div>
      )
    }

  }
}
