import React from 'react';
import update from 'immutability-helper';
import { Modal, Button } from 'react-bootstrap';

import { sendRequest } from '../helpers/global.js';

import TextInput from './input/TextInput.js';

const RESEND_TIME = 30;

export default class VerificationCodeView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      inputErrors: {},
      sms_auth: {
        phone: null,
        token: null,
      },
      resendSeconds: 0,
      sendTime: props.sendTime,
    };
    this.timer = null;
  }

  componentDidMount = () => {
    this.timer = setInterval(() => {
      let seconds = Math.max(RESEND_TIME - Math.round((Date.now() - this.state.sendTime) / 1000), 0);
      if (seconds !== this.state.resendSeconds) {
        this.setState({resendSeconds: seconds});
      }
    }, 150);
  }

  componentWillUnmount = () => {
    clearInterval(this.timer);
  }

  componentWillReceiveProps = (props) => {
    if (props.sendTime && props.sendTime !== this.state.sendTime) {
      this.setState({sendTime: props.sendTime});
    }
  }

  onClose = () => {
    this.props.onClose();
  }

  onSubmit = () => {
    let inputErrors = {};

    if (!this.state.data.code) {
      inputErrors.code = 'Please enter code';
    }

    if (Object.keys(inputErrors).length > 0) {
      this.setState({inputErrors});
    } else {
      sendRequest({
        type: 'POST',
        method: this.props.method,
        auth: !!this.props.auth,
        data: {
          sms_otp: this.state.data.code,
          sms_token: this.props.token,
        },
        success: (data)  => {
          this.props.onSuccess(data);
        },
        error: (error) => {
          this.setState(update(this.state, {
            inputErrors: {
              code: {$set: 'Code is invalid'},
            },
            data: {
              code: {$set: ''},
            },
          }));
        }
      })
    }
  }

  onResend = () => {
    sendRequest({
      type: 'POST',
      method: 'me/resend_otp',
      data: {
        sms_token: this.props.token,
      },
      success: (data)  => {
        this.props.onResend(data);
      },
      error: (error) => {
      }
    })
  }

  renderTextField = (key, title, type) => {
    return (
      <TextInput
        properties={{type: "text", placeholder: title}}
        object={this.state.data[key] || ''}
        objectKey={key}
        onChange={(key, value) => {
          this.setState({data:
              update(this.state.data, {
                [key]: {$set: value}
              })
          })
        }}
      />
    )
  }
  
  render = () => {
    let seconds = this.state.resendSeconds;
    return (
      <Modal show={this.props.show} onHide={this.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>2 Factor Auth</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {`Code was send to ${this.props.phone}`}
          <div style={{textAlign: 'center', padding: '10px 0px 15px'}}>
            <Button
              onClick={this.onResend}
              disabled={seconds > 0}
            >{`Send again${seconds > 0 ? ` (${seconds})` : ''}`}</Button>
          </div>
          {this.renderTextField('code', 'SMS Code')}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.onClose}>Cancel</Button>
          <Button onClick={this.onSubmit}>Send</Button>
        </Modal.Footer>
      </Modal>
    )
  }

}
