import Storage from '../helpers/Storage.js';

Storage.setConfig("assets", {
  rootSection: true,
  config: {
    method: "assets/",
    title: "Assets",
    objectName: "Asset",
    export: {
      /*import: {
        type: "upload",
        noSelect: true,
        button: "Import Asset",
        confirm: "Send",
        method: "assets/import/",
        accept: ".csv",
        fileParameter: "file",
      },
      example: {
        type: "get",
        noSelect: true,
        button: "Import Example",
        method: "assets/import_example/",
      },*/
      service_summary_report: {
        type: "choose",
        button: "Generate Service History Summary",
        idParameter: 'report_format',
        listParameter: "asset_ids",
        method: "assets/service_summary_report/",
        options: [{value: 'pdf', label: 'PDF'}, {value: 'csv', label: 'CSV'}],
        confirm: 'Request',
      },
    },
    search: {
      organization_id: {
        title: "Organization",
        type: "select",
        request: "users/organizations",
        showCondition: object => ['admin', 'agent'].indexOf((Storage.getData("user") || {}).role) > -1,
      },
      customer_id: {
        title: "Customer",
        type: "select",
        request: "customers/all"
      },
      asset_class_id: {
        title: "Asset class",
        type: "multi",
        request: "assets/classes"
      },
      last_service_date_min: {
        title: "Service Date Start",
        type: "date"
      },
      last_service_date_max: {
        title: "Service Date End",
        type: "date"
      },
      defects: {
        default: null,
        type: "select",
        title: "Defects",
        options: [{id: true, name: 'Yes'}, {id: false, name: 'No'}],
      },
      active: {
        default: null,
        type: "select",
        title: "Active",
        options: [{id: true, name: 'Yes'}, {id: false, name: 'No'}],
      },
      serial_number: {
        default: "",
        type: "text",
        title: "Serial number",
      },
    }
  },
  properties: {
    id: {
      default: false,
      type: "none",
      title: "#",
      preset: true,
    },
    created_at: {
      default: "",
      type: "text",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "text",
      title: "Modified Time",
      preset: true,
    },
    photo_url: {
      default: "",
      type: "image",
      title: "Photo",
      preset: true,
    },
    serial_number: {
      default: "",
      type: "text",
      title: "Serial number",
      width: "auto"
    },
    asset_class_id: {
      default: null,
      type: "select",
      title: "Asset class",
      objectPageKey: "asset_classes",
      request: "assets/classes",
      width: "auto",
    },    
    customer_id: {
      default: null,
      type: "select",
      title: "Customer",
      objectPageKey: "customers",
      request: "customers/all",
      width: "auto",
    },
    location: {
      default: "",
      type: "text",
      title: "Location",
    },
    notes: {
      default: [],
      type: "notes",
      title: "Notes",
      showCondition: object => !!object.id,
      request: "assets/:id/note"
    },
    last_service_date: {
      default: "",
      type: "date",
      title: "Last service date",
      preset: true,
      width: "auto",
      sort: true,
    },
    /*service_history: {
      default: null,
      preset: true,
      type: "json",
      title: "Service history",
    },*/
    brand: {
      default: "",
      type: "text",
      title: "Make",
    },
    model: {
      default: "",
      type: "text",
      title: "Model",
    },
    active: {
      default: null,
      type: "select",
      title: "Active",
      options: [{id: true, name: 'Yes'}, {id: false, name: 'No'}],
      width: "60px",
    },
    has_defects: {
      default: null,
      type: "select",
      preset: true,
      title: "Defects",
      options: [{id: true, name: 'Yes'}, {id: false, name: 'No'}],
      width: "60px",
      showCondition: object => false,
    },
    service_history_url: {
      type: "link",
      title: "Service history",
      showCondition: object => !!object.service_history_url,
    },
    defects: {
      default: [],
      preset: true,
      type: "defects",
      title: "Defects",
      switch_request: "assets/:id/switch_defect",
    },

  }
});
